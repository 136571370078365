import './index.scss';

export const Map = () => {
  return (
    <div className='map-wrapper'>
      <div className='map-land-controllers'>
        <button id='map-zoom-in-btn'>
          <svg
            width='21'
            height='21'
            viewBox='0 0 21 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              x='8.06958'
              y='20.3994'
              width='19.5984'
              height='4.61652'
              transform='rotate(-90 8.06958 20.3994)'
              fill='white'
            />
            <rect
              x='8.06958'
              y='20.3994'
              width='19.5984'
              height='4.61652'
              transform='rotate(-90 8.06958 20.3994)'
              fill='white'
            />
            <rect
              x='8.06958'
              y='20.3994'
              width='19.5984'
              height='4.61652'
              transform='rotate(-90 8.06958 20.3994)'
              fill='white'
            />
            <rect
              x='0.578613'
              y='8.29199'
              width='19.5984'
              height='4.61652'
              fill='white'
            />
            <rect
              x='0.578613'
              y='8.29199'
              width='19.5984'
              height='4.61652'
              fill='white'
            />
            <rect
              x='0.578613'
              y='8.29199'
              width='19.5984'
              height='4.61652'
              fill='white'
            />
          </svg>
        </button>
        <button id='map-zoom-reset-btn'>
          <svg
            width='23'
            height='25'
            viewBox='0 0 23 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.5001 17.3333C13.5192 17.3333 15.1251 15.725 15.1251 13.7083C15.1251 11.6915 13.5192 10.0833 11.5001 10.0833C9.481 10.0833 7.87512 11.6915 7.87512 13.7083C7.87512 15.725 9.481 17.3333 11.5001 17.3333Z'
              fill='white'
            />
            <path
              d='M22.154 11.5165C21.8693 10.1249 21.3129 8.80316 20.5167 7.62687C19.7344 6.46963 18.7377 5.47292 17.5805 4.69062C16.404 3.89473 15.0823 3.33837 13.6908 3.05333C12.9599 2.9051 12.2157 2.83222 11.4699 2.83583V0.416748L6.66679 4.04175L11.4699 7.66675V5.2525C12.0547 5.25008 12.6396 5.30567 13.2039 5.42167C14.2854 5.64328 15.3126 6.07563 16.2271 6.69404C17.129 7.30212 17.9052 8.07834 18.5133 8.98021C19.4574 10.3761 19.9608 12.0232 19.9585 13.7084C19.9582 14.8393 19.7323 15.9587 19.2939 17.0011C19.0806 17.5032 18.8201 17.9839 18.5157 18.4366C18.2101 18.8866 17.863 19.3069 17.479 19.6921C16.3093 20.8596 14.8244 21.6604 13.2063 21.9964C12.081 22.2236 10.9217 22.2236 9.79637 21.9964C8.71438 21.7745 7.68667 21.3418 6.77191 20.7228C5.87108 20.1152 5.09571 19.3399 4.48816 18.439C3.54513 17.0417 3.04143 15.3942 3.04179 13.7084H0.625122C0.626408 15.8758 1.27386 17.9936 2.48475 19.7912C3.26756 20.9465 4.26325 21.9422 5.41858 22.725C7.2137 23.9399 9.33251 24.5874 11.5001 24.5834C12.2363 24.5834 12.9707 24.5093 13.692 24.3623C15.0825 24.0752 16.4034 23.519 17.5805 22.725C18.1584 22.3356 18.697 21.8908 19.1887 21.397C19.6812 20.9033 20.1262 20.3644 20.5179 19.7875C21.7318 17.9929 22.3788 15.8751 22.3751 13.7084C22.3751 12.9722 22.301 12.2379 22.154 11.5165Z'
              fill='white'
            />
          </svg>
        </button>
        <button id='map-zoom-out-btn'>
          <svg
            width='21'
            height='5'
            viewBox='0 0 21 5'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              x='20.177'
              y='4.70801'
              width='19.5984'
              height='4.61652'
              transform='rotate(-180 20.177 4.70801)'
              fill='white'
            />
            <rect
              x='20.177'
              y='4.70801'
              width='19.5984'
              height='4.61652'
              transform='rotate(-180 20.177 4.70801)'
              fill='white'
            />
            <rect
              x='20.177'
              y='4.70801'
              width='19.5984'
              height='4.61652'
              transform='rotate(-180 20.177 4.70801)'
              fill='white'
            />
          </svg>
        </button>
      </div>

      <svg id='map' viewBox='0 0 5500 5500'>
        <g id='map-zoom-wrapper'>
          <g id='map-background'>
            <image
              href='./img/level_0/zoom_00.jpg'
              x='0'
              y='0'
              height='5500'
              width='5500'
            />
          </g>
          <g id='map-content'>
            <rect
              id='map-background-darkness'
              opacity='0'
              x='0'
              y='0'
              width='5500'
              height='5500'
            />
          </g>
          <g xmlns='http://www.w3.org/2000/svg' id='Roads'>
            <g>
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2824.961'
                y1='2747.531'
                x2='3222.426'
                y2='2454.983'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3150.241,2541.078c10.581,16.647,16.187,40.951,20.017,60.629'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3162.874,2570.565c0,0,10.121-6.473,19.499-10.894s16.582-10.325,16.582-10.325'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3193.796,2507.946c0,0,4.924,20.381,4.939,36.342s6.572,28.565,6.572,28.565'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3165.312,2495.862c0,0,10.55,13.672,8.118,28.968'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3153.61'
                y1='2547.917'
                x2='3091.676'
                y2='2566.025'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3219.422,2495.039c-28.472,8.034-33.001,23.955-69.181,46.039'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3175.106,2563.375c0,0,4.521,10.822,5.375,27.057c0,0,2.365,15.585,15.692,27.206'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3188.514,2556.406c0,0,4.578,9.999,6.671,23.963'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3130.22,2543.241c0,0,33.53-22.757,43.217-30.374'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3186.729,2606.344c0,0,26.193-20.183,41.244-31.903'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3173.438'
                y1='2524.985'
                x2='3197.583'
                y2='2530.423'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3191.202,2554.942 3165.157,2547.418 3158.03,2535.595'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3195.778,2518.056 3212.468,2515.462 3215.88,2531.527 3232.5,2520.514 3252.913,2521.234'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3215.88'
                y1='2531.527'
                x2='3229.554'
                y2='2555.316'
              />
              <g>
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2594.5'
                  y1='2800.5'
                  x2='2592'
                  y2='2785.5'
                />
              </g>
              <g>
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2706.322'
                  y1='2510.867'
                  x2='2721.529'
                  y2='2510.874'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2705.479'
                  y1='2598.418'
                  x2='2720.686'
                  y2='2598.424'
                />
              </g>
              <g>
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2996.736'
                  y1='2838.071'
                  x2='2996.731'
                  y2='2853.278'
                />
              </g>
              <g>
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2961.415'
                  y1='2648.021'
                  x2='2952.964'
                  y2='2637.366'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2872.173'
                  y1='2713.883'
                  x2='2863.723'
                  y2='2703.228'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3053.884'
                  y1='2579.535'
                  x2='3045.434'
                  y2='2568.88'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3139.655'
                  y1='2516.239'
                  x2='3131.205'
                  y2='2505.584'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3219.559'
                  y1='2457.433'
                  x2='3211.109'
                  y2='2446.778'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3209.314,2427.992l-15.801-26.257L3209.314,2427.992c2.951-1.78,6.41-2.806,10.108-2.806v-30.646     c-17.835,0-33.488,9.3-42.406,23.307l25.862,16.432C3204.508,2431.72,3206.712,2429.562,3209.314,2427.992z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3236.22,2434.682l26.257-15.8L3236.22,2434.682c1.78,2.952,2.806,6.41,2.806,10.108h30.646     c0-17.835-9.3-33.488-23.307-42.406l-16.432,25.862C3232.493,2429.876,3234.651,2432.08,3236.22,2434.682z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3202.878,2434.28l-25.862-16.432c-4.958,7.789-7.843,17.025-7.843,26.942h30.646     C3199.819,2440.921,3200.944,2437.318,3202.878,2434.28z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3229.933,2428.246l16.432-25.862c-7.789-4.959-17.026-7.843-26.942-7.843v30.646     C3223.291,2425.187,3226.895,2426.312,3229.933,2428.246z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3208.912,2461.334c-2.56-1.63-4.718-3.834-6.287-6.436'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3202.625,2454.898c-1.78-2.951-2.806-6.41-2.806-10.108h-30.646c0,17.835,9.3,33.488,23.307,42.406'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3176.367,2470.699'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3239.026,2444.79c0,3.869-1.125,7.473-3.06,10.512l25.862,16.431c4.958-7.788,7.843-17.025,7.843-26.942     H3239.026z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3229.53,2461.588c-2.952,1.78-6.41,2.806-10.108,2.806v30.646c17.834,0,33.488-9.3,42.406-23.307     l-25.862-16.431C3234.336,2457.861,3232.132,2460.02,3229.53,2461.588'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3245.331,2487.845'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3192.48,2487.196c7.789,4.959,17.026,7.843,26.942,7.843v-30.646c-3.869,0-7.473-1.125-10.511-3.06'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2699.312,2340.135c1.63-2.56,3.833-4.718,6.436-6.288'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2705.747,2333.847c2.951-1.78,6.41-2.806,10.108-2.806v-30.646c-17.835,0-33.488,9.3-42.406,23.307'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2689.946,2307.59'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2732.653,2340.537c1.78,2.952,2.806,6.41,2.806,10.108h30.646c0-17.835-9.3-33.488-23.307-42.406     l-16.432,25.862C2728.926,2335.731,2731.084,2337.935,2732.653,2340.537'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2758.91,2324.736'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2673.449,2323.703c-4.958,7.789-7.843,17.025-7.843,26.942h30.646c0-3.869,1.125-7.472,3.06-10.51'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2726.366,2334.101l16.432-25.862c-7.789-4.959-17.026-7.843-26.942-7.843v30.646     C2719.724,2331.042,2723.328,2332.167,2726.366,2334.101z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2705.345,2367.189c-2.56-1.63-4.718-3.834-6.287-6.436l-26.839,14.435l26.839-14.435     c-1.78-2.951-2.806-6.41-2.806-10.108h-30.646c0,17.835,9.3,33.488,23.307,42.406'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2758.261,2377.587c4.958-7.788,7.843-17.025,7.843-26.942h-30.646c0,3.869-1.125,7.473-3.06,10.512'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2725.963,2367.443c-2.952,1.78-6.41,2.806-10.108,2.806'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2741.764,2393.7'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2732.399,2361.157c-1.63,2.56-3.834,4.718-6.436,6.286'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2715.855,2400.894c17.834,0,33.488-9.3,42.406-23.307'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2688.913,2393.051c7.789,4.959,17.026,7.843,26.942,7.843'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2715.855,2370.249c-3.869,0-7.473-1.125-10.511-3.06'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3127.196,2831.484l-13.343-22.174L3127.196,2831.484c2.493-1.503,5.414-2.369,8.537-2.369v-25.88     c-15.062,0-28.28,7.854-35.812,19.683l21.84,13.876C3123.138,2834.632,3124.999,2832.81,3127.196,2831.484z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3149.918,2837.133l22.173-13.343L3149.918,2837.133c1.503,2.493,2.369,5.414,2.369,8.537h25.88     c0-15.062-7.854-28.28-19.682-35.811l-13.876,21.84C3146.771,2833.075,3148.593,2834.936,3149.918,2837.133z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3121.762,2836.794l-21.84-13.876c-4.188,6.577-6.623,14.377-6.623,22.752h25.88     C3119.178,2842.403,3120.128,2839.36,3121.762,2836.794z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3144.609,2831.699l13.876-21.84c-6.577-4.188-14.378-6.624-22.752-6.624v25.88     C3139,2829.115,3142.043,2830.065,3144.609,2831.699z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3121.547,2854.206l-22.174,13.343L3121.547,2854.206c-1.503-2.492-2.369-5.413-2.369-8.536h-25.88     c0,15.062,7.854,28.28,19.682,35.811l13.876-21.84C3124.695,2858.264,3122.872,2856.403,3121.547,2854.206z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3152.288,2845.67c0,3.268-0.95,6.311-2.584,8.877l21.84,13.875c4.188-6.577,6.624-14.377,6.624-22.752     H3152.288z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3144.269,2859.855l13.343,22.174L3144.269,2859.855c-2.493,1.503-5.413,2.369-8.536,2.369v25.88     c15.061,0,28.28-7.854,35.811-19.682l-21.84-13.875C3148.327,2856.708,3146.466,2858.531,3144.269,2859.855z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3126.857,2859.641l-13.876,21.84c6.577,4.188,14.378,6.624,22.752,6.624v-25.88     C3132.466,2862.225,3129.422,2861.274,3126.857,2859.641z'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3147.975,3260.236c2.951-1.781,6.41-2.807,10.108-2.807'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3132.174,3233.979'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3141.54,3266.523c1.63-2.561,3.833-4.719,6.436-6.287'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3158.083,3226.784c-17.835,0-33.488,9.301-42.406,23.308'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3168.594,3260.49c2.56,1.629,4.718,3.833,6.287,6.436'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3174.881,3266.926c1.78,2.951,2.806,6.41,2.806,10.107h30.646c0-17.834-9.3-33.487-23.307-42.405'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3201.138,3251.125'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3138.48,3277.033c0-3.868,1.125-7.472,3.06-10.51'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3115.677,3250.092c-4.958,7.789-7.843,17.025-7.843,26.941'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3185.026,3234.628c-7.789-4.959-17.026-7.844-26.942-7.844'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3158.083,3257.43c3.869,0,7.473,1.126,10.511,3.061'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3107.834,3277.033c0,17.836,9.3,33.488,23.307,42.406'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3200.489,3303.977c4.958-7.789,7.843-17.025,7.843-26.943h-30.646c0,3.869-1.125,7.474-3.06,10.512'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3158.083,3327.283c17.834,0,33.488-9.301,42.406-23.307'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3168.191,3293.832c-2.952,1.779-6.41,2.805-10.108,2.805'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3183.992,3320.089'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3174.627,3287.545c-1.63,2.561-3.834,4.719-6.436,6.287'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3158.083,3296.637c-3.869,0-7.473-1.125-10.511-3.059'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3131.141,3319.439c7.789,4.959,17.026,7.844,26.942,7.844'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3123.649'
                  y1='3240.456'
                  x2='3144.757'
                  y2='3262.417'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3182.417'
                  y1='3232.634'
                  x2='3167.377'
                  y2='3259.777'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3107.752,3281.275l30.728-4.242c0,3.699,1.025,7.157,2.806,10.108l-26.258,15.801l26.258-15.801     c1.569,2.603,3.727,4.806,6.287,6.437'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3153.163'
                  y1='3327.04'
                  x2='3154.368'
                  y2='3296.637'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3191.736'
                  y1='3314.329'
                  x2='3170.678'
                  y2='3292.045'
                />
              </g>
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3102.866,2259.204c2.463,10.472,3.326,17.595-2.155,34.355c-7.536,23.043-6.976,26.439-7.417,40.538'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3386.893,2416.83c-20.576-23.911-41.223-38.885-60.696-49.996c-12.343-7.043-15.444-10.178-28.196-13.85    c-20.031-5.768-42.76-8.568-54.083-5.157c-11.186,3.37-15.177,1.186-32.342-14.174c-29.255-26.178-30.661-30.641-60.807-55.788    c-11.529-9.617-22.012-19.864-29.764-28.618'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3359.641'
                y1='2390.193'
                x2='3502.391'
                y2='2171.577'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3115.841,2651.004c17.392-7.379,26.325-26.972,43.267-40.372c23.747-18.783,28.32-25.265,57.774-45.885    c30.562-21.396,28.705-19.203,35.109-23.479'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3093.3,2549.437c-0.999,9.625-2.641,17.336-2.209,36.009c0.385,16.618-15.304,42.073-5.959,55.61'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3142.115,2355.988c-38.892,70.078-47.518,129.341-47.518,164.59c0,4.965-0.07,9.231-0.189,12.994'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3064.944,2393.714c0,0,24.748-1.052,54.569,8.284'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3076.255,2512.538c0,0,4.83-56.228,10.678-74.583c4.339-13.621,22.36-5.465,22.36-5.465'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2496'
                y1='2814'
                x2='2688.5'
                y2='2787.5'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2496'
                y1='2799'
                x2='2688.5'
                y2='2772.5'
              />
              <rect
                x='2441'
                y='2775'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                fill='none'
                width='55'
                height='64'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2441,2839 2073.5,2839 2073.5,2829 2118.5,2809 2118.5,2775 2441,2775'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2103.5'
                y1='2710.5'
                x2='2293.5'
                y2='2710.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2228.722'
                y1='2725.5'
                x2='2273.5'
                y2='2725.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2323.875'
                y1='2710.5'
                x2='2440.96'
                y2='2710.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2495.598'
                y1='2710.5'
                x2='2542.438'
                y2='2710.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M460.217,2405.993c12.111,1.041,46.32,3.687,46.32,3.687'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2541.978,2685.5v-40c0,0-104.769-26.638-200.521-67.001c-39.737-11.275-98.204-21.507-120.037-38.105'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2219.75'
                y1='2829'
                x2='2073.5'
                y2='2829'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2273.5'
                y1='2794'
                x2='2228.5'
                y2='2794'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2273.5'
                y1='3010'
                x2='2138.5'
                y2='3010'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2181'
                y1='3025'
                x2='2138.5'
                y2='3025'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2273.5'
                y1='3017.5'
                x2='2181'
                y2='3017.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2441,2885h-384.5v58.5c0,0,354.5,0,367,0c17.5,0,17.5-18,17.5-18V2885z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2055.5,3041.029V2980c0,0,288,0,325.5,0c0,0,35.893-0.039,14.353,27.12    c-29.318,36.967-50.601,100.555-74.353,92.591c-35-11.736-107.615-17.559-140-14.083c-87.5,9.389-125.5-4.694-125.5-4.694    V3041.029z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2140.556,3206.766c24.358,32.669,92.798-13.435,136.087-21.801c33.357-6.447,38.411-35.001,29.357-45.618    c-12.5-14.658-100.115-23.161-132.5-19.544c-87.5,9.772-116.228-9.636-128.739,4.697c-12.656,14.499,12.573,28.498,30.073,38.354    C2090.249,3171.535,2116.366,3174.322,2140.556,3206.766z'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2186'
                y1='3152.678'
                x2='2191.562'
                y2='3213.807'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2198.226,2685.5 2103.5,2685.5 2103.5,2735.5 2541.978,2735.5 2541.978,2685.5 2496,2685.5     2496,2680.5 2441,2680.5 2441,2685.5 2228.5,2685.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2441'
                y1='2885'
                x2='2441'
                y2='2839'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2118.5,2809 2385.67,2809 2385.67,2814 2385.67,2839'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2291'
                y1='2775'
                x2='2291'
                y2='2839'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2318.5'
                y1='2809'
                x2='2318.5'
                y2='2839'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2311'
                y1='2775'
                x2='2311'
                y2='2809'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2323.5'
                y1='2775'
                x2='2323.5'
                y2='2809'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2333.5'
                y1='2809'
                x2='2333.5'
                y2='2839'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2381'
                y1='2775'
                x2='2381'
                y2='2809'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2348.5'
                y1='2824'
                x2='2384.42'
                y2='2824'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2371'
                y1='2775'
                x2='2371'
                y2='2809'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2228.5'
                y1='2731.5'
                x2='2228.5'
                y2='2809'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2181'
                y1='2809'
                x2='2181'
                y2='2880'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2168.5'
                y1='2775'
                x2='2168.5'
                y2='2809'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2393.5'
                y1='2648'
                x2='2393.5'
                y2='2735.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2441'
                y1='2685.5'
                x2='2441'
                y2='2775'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2496'
                y1='2660.577'
                x2='2496'
                y2='2777.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2293.5'
                y1='2685.5'
                x2='2293.5'
                y2='2735.5'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2273.5,2740.5 2273.5,2685.5 2273.5,2670.5 2251,2648'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2181'
                y1='2685.5'
                x2='2181'
                y2='2736.125'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2173.5'
                y1='2710.5'
                x2='2173.5'
                y2='2735.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2148.5'
                y1='2725.5'
                x2='2103.5'
                y2='2725.5'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2148.5,2775 2148.5,2685.5 2148.5,2659.991'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2441,2910c0,0,22.5-14,45-71'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2056.5'
                y1='2910'
                x2='2348.5'
                y2='2910'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2402.735'
                y1='2925'
                x2='2441'
                y2='2925'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2181'
                y1='2925'
                x2='2348.5'
                y2='2925'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2348.5,2734.5 2348.5,2871 2348.5,2880 2348.5,2945'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2288.502'
                y1='2885'
                x2='2288.5'
                y2='2910'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2273.5,2734.5 2273.5,2871 2273.5,2880 2273.5,2910'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2238.5'
                y1='2910'
                x2='2238.5'
                y2='2925'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2156'
                y1='2885'
                x2='2156'
                y2='2910'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2136'
                y1='2885'
                x2='2136'
                y2='2910'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2118.5'
                y1='2839'
                x2='2118.5'
                y2='2910'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2123.5'
                y1='2910'
                x2='2123.5'
                y2='2943.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2113.5'
                y1='2910'
                x2='2113.5'
                y2='2943.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2087.39'
                y1='2943.5'
                x2='2087.39'
                y2='2980'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2248.5'
                y1='2910'
                x2='2248.5'
                y2='2925'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2258.5'
                y1='2910'
                x2='2258.5'
                y2='2925'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2268.5'
                y1='2910'
                x2='2268.5'
                y2='2925'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2278.5'
                y1='2910'
                x2='2278.5'
                y2='2925'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2338.5,2943.75 2338.5,2928.75 2293.5,2928.75 2293.5,2943.75'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2403.22'
                y1='2885'
                x2='2403.22'
                y2='2943.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2288.5'
                y1='2910'
                x2='2288.5'
                y2='2925'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2228.5'
                y1='2839'
                x2='2228.5'
                y2='2945'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2181'
                y1='2880'
                x2='2181'
                y2='2979.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2148.5'
                y1='2910'
                x2='2148.5'
                y2='2943.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2291,2943.5v59c0,0-0.549,30.547-7.5,47.333s-13.174,38.773-13.174,38.773'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2273.5,2980 2273.5,3041.779 2286.538,3041.779'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2181'
                y1='2980.5'
                x2='2181'
                y2='3085.627'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2138.5'
                y1='2980.5'
                x2='2138.5'
                y2='3122.5'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2541.978,2645.5 2537.615,2617.889 2504.825,2610.07 2493.5,2596.432 2443.5,2578 2463.5,2655.5    '
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2537.615,2617.889c0,0-8.554-35.162-18.554-47.412s-45.148-33.316-64.561-45.477    c-14.212-8.903-40-21-40-21l-2,65.875l31,8.125'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2541.978,2665.5 2398.5,2648 2251,2628'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2228.5,2740.5 2228.5,2685.5 2228.5,2670.5 2251,2648 2251,2628 2252.333,2610.677'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2321'
                y1='2685.5'
                x2='2321'
                y2='2637.024'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2183.211,2638.719 2251,2648 2321,2653 2353.083,2660.5 2353.083,2685.5 2348.5,2685.5     2348.5,2740.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2148.5'
                y1='2670.5'
                x2='2321'
                y2='2670.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2526'
                y1='2809'
                x2='2523.5'
                y2='2794'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2565'
                y1='2804.5'
                x2='2562.5'
                y2='2789.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2627'
                y1='2796.5'
                x2='2624.5'
                y2='2781.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2661'
                y1='2792.5'
                x2='2658.5'
                y2='2777.5'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2707.524'
                y1='2372.691'
                x2='2705.87'
                y2='2678.871'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2722.319'
                y1='2375.162'
                x2='2720.666'
                y2='2681.342'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2707.524'
                y1='2456.695'
                x2='2721.607'
                y2='2456.701'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2705.9'
                y1='2554.643'
                x2='2721.107'
                y2='2554.649'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2705.469'
                y1='2638.911'
                x2='2720.675'
                y2='2638.917'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3094.054'
                y1='2837.694'
                x2='2860.86'
                y2='2839.041'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3094.054'
                y1='2852.49'
                x2='2858.39'
                y2='2853.836'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3060.305'
                y1='2837.685'
                x2='3060.3'
                y2='2852.892'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3019.616'
                y1='2838.493'
                x2='3019.611'
                y2='2853.7'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2933.856'
                y1='2838.649'
                x2='2933.852'
                y2='2853.856'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2903.442'
                y1='2838.639'
                x2='2903.438'
                y2='2853.846'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2820.25'
                y1='2735.998'
                x2='3215.426'
                y2='2444.983'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2902.445'
                y1='2690.771'
                x2='2893.996'
                y2='2680.116'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2934.396'
                y1='2667.679'
                x2='2925.947'
                y2='2657.024'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3002.44'
                y1='2617.134'
                x2='2993.99'
                y2='2606.479'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3026.893'
                y1='2599.192'
                x2='3018.443'
                y2='2588.537'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3080.548'
                y1='2560.427'
                x2='3072.098'
                y2='2549.771'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3105'
                y1='2542.485'
                x2='3096.551'
                y2='2531.83'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3166.318'
                y1='2497.13'
                x2='3157.868'
                y2='2486.475'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2428,2459.108L2414.5,2504c0,0-28.462-12.32-54-17c-24.713-4.529-46.021-8.254-74.5-7    c-25.471,1.122-31.52,4.272-67.723-17.018c-25.95-15.26-73.983-46.54-97.903-72.052c-13.373-14.264-37.358-64.573-86.867-84.833    c-52.605-21.526-94.549-30.417-94.549-30.417l-15.27,53.634'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2316'
                y1='2923.75'
                x2='2316'
                y2='2928.75'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2428,2191'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2150.365,2555.544 2216.388,2600.667 2328.502,2629.865 2414.218,2634.73 2454.521,2640.554     2459.249,2639.025 2541.978,2653'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2173.022'
                y1='2668.412'
                x2='2200.293'
                y2='2588.936'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2221.419'
                y1='2540.394'
                x2='2097'
                y2='2446.5'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2288.5,2480.281 2300.5,2438.5 2430.99,2323.779'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2428,2459.108c0,0-22.211-19.292-59.833-21.052c-26.774-1.253-62.979-3.677-62.979-3.677l-102.905-115.463    l64.499-11.682l10.836,12.374'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2540.522'
                y1='2416.811'
                x2='2452.678'
                y2='2416.811'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3268.51,3149.685c13.871,8.473,27.995,21.106,37.16,32.257c61.135,74.374,50.402,184.226-23.972,245.359    c-92.97,76.419-230.282,63.001-306.7-29.966c-56.379-68.588-73.641-156.486-54.427-236.646'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3115.028,3302.942 3100.501,3325.004 3058.75,3459.834'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3014.31'
                y1='3016.546'
                x2='3022.116'
                y2='3072.583'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3279.986,2934.328c-16.281,1.774-33.967,1.268-58.053,1.183c-39.299-0.138-80.041,8.394-120.945,25.1'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3017.588,3399.844c-19.838-21.094-32.171-44.26-32.171-44.26c-55.701-97.33-38.744-223.611,61.18-305.746    c64.47-52.993,133.404-70.963,210.196-65.586l21.491,2'
              />
              <g>
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2665.606,2353.928 2522.396,2497.138 2569.762,2544.505 2707.221,2407.047 '
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2905.752'
                  y1='2113.782'
                  x2='2715.997'
                  y2='2303.537'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2766.104,2348.163 2970.575,2143.692 2923.214,2096.319 '
                />
              </g>
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2946.603'
                y1='2153.686'
                x2='2963.586'
                y2='2136.701'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2922.76'
                y1='2129.843'
                x2='2939.744'
                y2='2112.859'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2916.018'
                y1='2123.101'
                x2='2933.001'
                y2='2106.116'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2939.895'
                y1='2146.978'
                x2='2956.879'
                y2='2129.994'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2935.519,2164.769 2918.535,2181.753 2911.612,2174.831 2928.944,2157.498'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2921.656,2150.906 2904.672,2167.891 2911.946,2175.165'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2942.421,2170.975 2918.308,2146.862 2928.741,2136.429 2952.854,2160.542'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2627.786,2486.481c0,0-3.741-12.926-11.119-29.474c-5.178-11.615-6.575-12.49-2.996-14.561    s19.023-9.402,19.023-9.402'
              />
              <g>
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2501.706'
                  y1='2184.666'
                  x2='2665.334'
                  y2='2348.334'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2715.5'
                  y1='2303.5'
                  x2='2553.245'
                  y2='2141.363'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2707.522,2382.973 2675.283,2350.733 2715.633,2310.383 2755.984,2350.733 2722.492,2384.225      '
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2765.192,2360.192 2826.574,2421.574 2787,2462 2725.114,2400.114 '
                />
              </g>
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2348.5,2945c0,0,0,66.823,0,76.656c0,13.623,12.5,22.094,12.5,22.094l84.327,55.016'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2780.233,2333.233'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3093.67,2323.447c-34.767-0.073-37.163,1.39-71.929,1.318c-34.768-0.073-34.766-1.243-69.534-1.316    c-34.768-0.072-42.772-9.981-77.541-10.054'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2909.85,2204.417c0,0-24.502,38.523-32.684,103.415c-5.881,46.642-5.083,74.271-7.512,86.573    c-2.496,12.645-7.611,27.17-43.08,27.17'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2830.383,2304.976 2862.506,2273.08 2851.854,2262.901'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2920.5,2191'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2780.233,2368.5'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2697.733'
                y1='1927.185'
                x2='2697.733'
                y2='1748.667'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2733'
                y1='2077.82'
                x2='2733'
                y2='1748.667'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2130.712,3194.76 2228.5,3120.33 2228.5,2945'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2083.5,3086.535 2083.5,3127.5 2083.5,3167.273'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2200.007'
                y1='3142.016'
                x2='2240.422'
                y2='3196.072'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2323.5'
                y1='2980'
                x2='2323.5'
                y2='3042.5'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2323.5,2995 2308.5,2995 2308.5,3025 2323.5,3025'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2323.5'
                y1='3005'
                x2='2308.5'
                y2='3005'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2323.5'
                y1='3015'
                x2='2308.5'
                y2='3015'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2181,3000 2198.5,3000 2198.5,3010'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2266'
                y1='2710.5'
                x2='2266'
                y2='2725.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2258.5'
                y1='2710.5'
                x2='2258.5'
                y2='2725.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2251'
                y1='2710.5'
                x2='2251'
                y2='2725.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2243.5'
                y1='2710.5'
                x2='2243.5'
                y2='2725.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2236'
                y1='2710.5'
                x2='2236'
                y2='2725.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2393.5'
                y1='2730.5'
                x2='2441'
                y2='2730.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2948.031,2627.532'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2101.935'
                y1='3175.598'
                x2='2172.248'
                y2='3120.014'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2113.702'
                y1='3182.296'
                x2='2191.079'
                y2='3118.718'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2188.578'
                y1='2623.078'
                x2='2251'
                y2='2633.414'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2201.467'
                y1='2641.219'
                x2='2228.5'
                y2='2670.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2200.883'
                y1='2641.139'
                x2='2173.5'
                y2='2670.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2291'
                y1='2650.5'
                x2='2273.5'
                y2='2670.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2291'
                y1='2650.5'
                x2='2311'
                y2='2670.5'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2353.083,2660.5 2373.5,2660.5 2373.5,2685.5 2373.5,2735.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3150.241,2541.078c10.581,16.647,16.187,40.951,20.017,60.629'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3335.355,2509.83c-3.176-7.345-7.781-13.764-18.473-26.455c-18.156-21.555-31.969-38.326-47.211-38.585'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3219.055,2562.744c10.241,10.738,14.914,19.07,10.947,40.285c-5.953,31.839-10.525,64.304-16.824,84.431'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3374.433,2440.604'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2393.5,2665.5 2421,2665.5 2421,2685.5'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2421,2670.5 2466,2670.5 2466,2679.25'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2466'
                y1='2670.5'
                x2='2463.5'
                y2='2656.273'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2448.273,2596.497 2500.942,2615.387 2495.11,2632.344'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2268.936'
                y1='2614.42'
                x2='2268.936'
                y2='2630.354'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2308.52'
                y1='2624.661'
                x2='2308.52'
                y2='2635.799'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2328.502'
                y1='2629.865'
                x2='2361'
                y2='2487.583'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3135.733'
                y1='2888.104'
                x2='3163.936'
                y2='2990.699'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3112.98,2881.481 3088.28,2917.667 3069.507,2929.128'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3380.647,3150.847c0,0,3.227,19.211,12.977,33.961s6.25,27.75,10.376,44.192s15.796,36.034,30.825,43.408    c14.709,7.217,21.963,14.925,27.963,28.592s-6,43.416,0,53.75s15.879,11.916,42.212,5.583s38-11.833,38-11.833'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3543,3348.5c0,0,25.333-12.363,41.003,15.675'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2430.99'
                y1='2394.656'
                x2='2431.109'
                y2='2307.008'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2351.695'
                y1='2320.865'
                x2='2402.64'
                y2='2280.159'
              />
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3401.375,2429.938v-17.13c-9.969,0-18.72,5.198-23.704,13.028'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='3386.893,2416.83 3393.846,2425.138 3393.927,2433.447 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3412.333,2440.896h17.13c0-9.969-5.198-18.719-13.028-23.704'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3425.441,2426.414'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3377.671,2425.836c-2.771,4.354-4.384,9.517-4.384,15.06h17.13'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3416.435,2417.192c-4.354-2.772-9.517-4.384-15.06-4.384v20.121'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3393.846,2440.896h-20.559c0,9.969,5.198,18.719,13.028,23.704'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3377.309,2455.378'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3425.078,2455.956c2.772-4.354,4.385-9.517,4.385-15.06h-20.559'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3401.375,2451.854v17.13c9.969,0,18.719-5.198,23.703-13.027'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='3415.856,2464.962 3408.949,2455.378 3408.823,2447.485 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3386.315,2464.6c4.354,2.772,9.517,4.384,15.06,4.384v-20.558'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3092.662,2640.032c-9.969,0-18.72,5.198-23.704,13.028'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3102.051,2662.469'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3120.75,2668.119c0-9.969-5.198-18.719-13.028-23.704'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3116.728,2653.637'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3068.958,2653.06c-2.771,4.354-4.384,9.517-4.384,15.06'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3107.721,2644.416c-4.354-2.772-9.517-4.384-15.06-4.384'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3064.574,2668.119c0,9.969,5.198,18.719,13.028,23.704'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3068.595,2682.602'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3116.365,2683.179c2.772-4.354,4.385-9.517,4.385-15.06'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3092.662,2696.207c9.969,0,18.719-5.198,23.703-13.027'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3107.143,2692.186'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3077.602,2691.823c4.354,2.772,9.517,4.384,15.06,4.384'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3117.6,2655.196c0,0-11.004,0.304-14.729,8.959'
                />
                <g>
                  <rect
                    x='3084.918'
                    y='2660.375'
                    transform='matrix(0.4033 -0.9151 0.9151 0.4033 -596.0816 4422.1392)'
                    fill='none'
                    stroke='#252526'
                    strokeWidth='2.5'
                    strokeLinejoin='round'
                    strokeMiterlimit='10'
                    width='15.487'
                    height='15.488'
                  />
                </g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3085.132,2641.056c0,0,8.681,5.249,3.566,16.854'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3096.625'
                  y1='2678.328'
                  x2='3114.904'
                  y2='2685.97'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3071.767,2649.364c0,0,12.276,6.126,17.349,8.546'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3082.452'
                  y1='2672.082'
                  x2='3064.804'
                  y2='2664.522'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3120.959,2670.583c0,0-9.363-1.916-18.088-6.427'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3093.039,2333.803c-5.769,0-10.832,3.008-13.717,7.539'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3089.77,2344.623c0.954-0.576,2.073-0.908,3.27-0.908'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3080.902,2339.254'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3087.688,2346.657c0.527-0.829,1.24-1.526,2.082-2.034'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3098.473,2346.786c0.575,0.955,0.907,2.073,0.907,3.27h9.913c0-5.769-3.009-10.832-7.539-13.716     l-5.314,8.365C3097.267,2345.232,3097.964,2345.945,3098.473,2346.786'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3106.966,2341.676'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3079.322,2341.342c-1.604,2.52-2.537,5.507-2.537,8.714h9.913c0-1.251,0.363-2.417,0.989-3.399'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3093.039,2343.715c1.251,0,2.418,0.364,3.4,0.99l5.314-8.365c-2.52-1.604-5.508-2.537-8.715-2.537     V2343.715z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3089.639,2355.408c-0.827-0.527-1.525-1.241-2.033-2.082'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3087.605,2353.326c-0.575-0.955-0.907-2.073-0.907-3.27h-9.913c0,5.769,3.009,10.832,7.539,13.716'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3079.112,2358.437'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3106.756,2358.771c1.604-2.52,2.537-5.507,2.537-8.715h-9.913c0,1.251-0.363,2.417-0.99,3.4'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3093.039,2366.309c5.769,0,10.832-3.007,13.717-7.538'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3096.309,2355.489c-0.955,0.576-2.073,0.908-3.27,0.908'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3101.419,2363.982'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3098.39,2353.457c-0.527,0.828-1.24,1.525-2.081,2.033'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3084.324,2363.772c2.52,1.604,5.508,2.537,8.715,2.537'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3093.039,2356.397c-1.251,0-2.418-0.364-3.4-0.989'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3105.025,2212.826l15.979-15.978c-9.299-9.299-22.31-12.612-34.263-9.958'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='3097.949,2210.316 3103.744,2187.089 3098.293,2209.019 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3105.026,2233.268l15.978,15.979c9.3-9.299,12.611-22.309,9.957-34.263'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3102.326'
                  y1='2223.583'
                  x2='3130.762'
                  y2='2231.987'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3090.833,2210.316l-4.092-23.427c-6.646,1.475-12.966,4.788-18.136,9.958l18.136,17.433'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3102.326,2220.417l28.635-5.433c-1.475-6.646-4.786-12.965-9.957-18.136l-18.678,18.652'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3086.741,2214.28l-18.136-17.433c-9.3,9.299-12.611,22.309-9.957,34.263'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='3086.723,2223.583 3058.848,2214.107 3086.723,2223.583 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3097.949,2237.153l4.917,22.051c6.647-1.475,12.968-4.787,18.138-9.958l-18.678-18.747'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3084.584,2233.268l-15.979,15.978c9.299,9.299,22.309,12.612,34.261,9.958l-4.917-23.221'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3058.648,2231.11c1.475,6.646,4.786,12.965,9.957,18.136l18.136-18.136'
                />
                <rect
                  x='3086.723'
                  y='2210.316'
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  width='15.603'
                  height='25.667'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2499.876,2336.512l5.451-21.929L2499.876,2336.512c2.467,0.61,4.804,1.879,6.732,3.807l15.979-15.978     c-9.299-9.299-22.31-12.612-34.263-9.958l4.917,22.051C2495.427,2335.949,2497.702,2335.973,2499.876,2336.512z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2510.417,2354.028l21.928,5.452L2510.417,2354.028c-0.612,2.466-1.88,4.805-3.808,6.733l15.978,15.979     c9.3-9.299,12.611-22.309,9.957-34.263l-22.051,4.917C2510.98,2349.58,2510.955,2351.853,2510.417,2354.028z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2493.242,2336.434l-4.917-22.051c-6.646,1.475-12.966,4.788-18.136,9.958l15.978,15.979     C2488.184,2338.302,2490.65,2337.01,2493.242,2336.434z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2510.494,2347.395l22.051-4.917c-1.475-6.646-4.786-12.965-9.957-18.136l-15.979,15.978     C2508.626,2342.336,2509.919,2344.802,2510.494,2347.395z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2482.359,2347.051l-21.928-5.451L2482.359,2347.051c0.612-2.466,1.88-4.804,3.808-6.732l-15.978-15.979     c-9.3,9.299-12.611,22.309-9.957,34.263l22.051-4.917C2481.796,2351.5,2481.821,2349.226,2482.359,2347.051z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2506.61,2360.761c-2.018,2.018-4.483,3.31-7.077,3.885l4.917,22.051     c6.647-1.475,12.968-4.787,18.138-9.958L2506.61,2360.761z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2492.9,2364.569l-5.452,21.928L2492.9,2364.569c-2.467-0.611-4.804-1.879-6.732-3.808l-15.979,15.978     c9.299,9.299,22.309,12.612,34.261,9.958l-4.917-22.051C2497.348,2365.131,2495.074,2365.107,2492.9,2364.569z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2482.283,2353.686l-22.051,4.917c1.475,6.646,4.786,12.965,9.957,18.136l15.979-15.978     C2484.15,2358.744,2482.857,2356.278,2482.283,2353.686z'
                />
              </g>
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2493.242'
                y1='2387.764'
                x2='2493.242'
                y2='2416.736'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2478.969'
                y1='2390.146'
                x2='2478.969'
                y2='2416.736'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2483.941'
                y1='2343.19'
                x2='2508.312'
                y2='2343.19'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2483.941'
                y1='2357.875'
                x2='2508.312'
                y2='2357.875'
              />
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2975.007,2563.019c2.16-1.31,4.201-2.891,6.066-4.755c1.726-1.726,3.205-3.604,4.454-5.585'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2958.425,2567.644c5.752,0,11.502-1.546,16.582-4.625'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2926.401,2535.46c-0.028,5.736,1.476,11.477,4.51,16.558'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2930.911,2552.018c1.329,2.227,2.95,4.328,4.868,6.246c1.673,1.673,3.488,3.115,5.402,4.341'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2941.181,2562.604c5.24,3.355,11.242,5.04,17.245,5.04'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2931.464,2518.329c-3.344,5.206-5.035,11.164-5.063,17.131'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2941.979,2508.129c-2.21,1.325-4.297,2.935-6.202,4.839c-1.661,1.661-3.094,3.461-4.314,5.36'
                />

                <ellipse
                  transform='matrix(0.644 -0.765 0.765 0.644 -886.4655 3165.9087)'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  fill='none'
                  cx='2958.531'
                  cy='2535.46'
                  rx='31.919'
                  ry='31.919'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2958.531'
                  y1='2503.541'
                  x2='2958.386'
                  y2='2514.259'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2990.45'
                  y1='2535.46'
                  x2='2979.991'
                  y2='2535.104'
                />
              </g>

              <rect
                x='2943.356'
                y='2519.929'
                transform='matrix(0.7071 -0.7071 0.7071 0.7071 -926.059 2834.467)'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                fill='none'
                width='30.349'
                height='30.349'
              />
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3431.184,2884.653c5.028-7.901,7.957-17.274,7.957-27.338c0-9.617-2.672-18.612-7.294-26.287     c-4.082-6.767-9.693-12.5-16.357-16.738c-7.9-5.033-17.273-7.96-27.336-7.958c-9.618,0.002-18.61,2.667-26.292,7.294     c-6.759,4.081-12.493,9.693-16.733,16.349c-5.03,7.907-7.96,17.277-7.96,27.342c0,9.619,2.672,18.61,7.294,26.291     c4.088,6.764,9.694,12.497,16.357,16.735c7.896,5.035,17.271,7.959,27.335,7.958c9.621,0,18.611-2.667,26.288-7.295     C3421.212,2896.923,3426.943,2891.313,3431.184,2884.653z'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3388.154'
                  y1='2806.332'
                  x2='3388.154'
                  y2='2817.452'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3388.154'
                  y1='2908.302'
                  x2='3388.154'
                  y2='2896.625'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='3428.419,2857.317 3439.14,2857.317 3574.378,2846.557 '
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3347.955'
                  y1='2857.317'
                  x2='3337.17'
                  y2='2857.317'
                />

                <rect
                  x='3359.739'
                  y='2828.617'
                  transform='matrix(0.7071 -0.7071 0.7071 0.7071 -1027.8752 3232.5977)'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  fill='none'
                  width='56.897'
                  height='56.897'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3462.956,3240.501c11.524,2.263,23.809,1.4,35.562-3.199c11.232-4.394,20.516-11.622,27.368-20.529     c6.037-7.859,10.17-17.031,12.072-26.75c2.27-11.525,1.404-23.812-3.196-35.562c-4.398-11.231-11.619-20.515-20.531-27.371     c-7.854-6.03-17.029-10.163-26.741-12.073c-11.533-2.263-23.814-1.401-35.568,3.198c-11.234,4.395-20.513,11.623-27.371,20.531     c-6.032,7.863-10.166,17.031-12.071,26.749c-2.271,11.524-1.402,23.808,3.197,35.561c4.396,11.236,11.618,20.519,20.53,27.368     C3444.068,3234.463,3453.242,3238.593,3462.956,3240.501z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M3466.838,3220.646c7.877,1.544,16.273,0.956,24.306-2.188c7.678-3.005,14.024-7.944,18.709-14.031     c4.125-5.371,6.949-11.642,8.249-18.284c1.552-7.878,0.961-16.275-2.185-24.308c-3.006-7.678-7.941-14.022-14.034-18.708     c-5.365-4.123-11.64-6.948-18.275-8.253c-7.881-1.547-16.278-0.958-24.313,2.185c-7.676,3.004-14.019,7.945-18.706,14.033     c-4.125,5.376-6.948,11.642-8.251,18.284c-1.554,7.875-0.96,16.272,2.187,24.307c3.004,7.679,7.941,14.022,14.031,18.703     C3453.928,3216.517,3460.196,3219.341,3466.838,3220.646z'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3534.763'
                  y1='3154.461'
                  x2='3515.917'
                  y2='3161.835'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3434.521'
                  y1='3193.681'
                  x2='3415.674'
                  y2='3201.055'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3498.518'
                  y1='3237.302'
                  x2='3491.144'
                  y2='3218.458'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='3459.296'
                  y1='3137.059'
                  x2='3451.923'
                  y2='3118.215'
                />
              </g>
              <g>
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2739.255,2132.253c2.776-4.362,4.393-9.536,4.392-15.092c0-5.31-1.474-10.275-4.026-14.513     c-2.253-3.735-5.351-6.901-9.03-9.24c-4.36-2.779-9.536-4.394-15.091-4.394c-5.311,0.001-10.273,1.473-14.513,4.027     c-3.733,2.252-6.898,5.351-9.24,9.025c-2.778,4.365-4.395,9.538-4.395,15.094c0,5.31,1.476,10.274,4.027,14.514     c2.256,3.735,5.352,6.899,9.029,9.239c4.36,2.779,9.536,4.394,15.091,4.394c5.311,0,10.275-1.472,14.512-4.027     C2733.748,2139.027,2736.913,2135.929,2739.255,2132.253z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2739.255,2132.253c-2.342,3.676-5.507,6.774-9.243,9.028'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2737.939,2154.455c2.922-1.773,5.685-3.912,8.208-6.435c2.335-2.335,4.336-4.878,6.027-7.558'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2730.012,2141.281c-4.237,2.555-9.201,4.027-14.512,4.027v15.406c7.784,0,15.565-2.093,22.439-6.259'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2687.352,2117.162l-15.189-0.001c-0.038,7.763,1.997,15.53,6.103,22.406l13.113-7.892     C2688.828,2127.436,2687.353,2122.472,2687.352,2117.162z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2691.379,2131.676l-13.113,7.892c1.798,3.014,3.992,5.857,6.587,8.452     c2.264,2.265,4.72,4.216,7.311,5.875l8.245-12.979C2696.731,2138.575,2693.635,2135.411,2691.379,2131.676z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2752.175,2140.462c4.474-7.094,6.701-15.2,6.661-23.301h-15.19c0.001,5.556-1.616,10.73-4.392,15.092'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2715.5,2145.308c-5.556,0-10.731-1.614-15.091-4.394l-8.245,12.979c7.091,4.54,15.213,6.82,23.336,6.82     V2145.308z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2743.646,2117.161h15.19c-0.037-7.737-2.139-15.461-6.294-22.289l-12.922,7.776     C2742.173,2106.886,2743.646,2111.852,2743.646,2117.161z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2739.62,2102.648l12.922-7.776c-1.763-2.901-3.89-5.641-6.396-8.147c-2.32-2.319-4.842-4.31-7.502-5.994     l-8.055,12.677C2734.269,2095.748,2737.367,2098.914,2739.62,2102.648z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2679.015,2093.978c-4.525,7.044-6.813,15.108-6.852,23.183l15.189,0.001     c0-5.556,1.617-10.729,4.395-15.094'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2700.986,2093.042c4.239-2.555,9.202-4.026,14.513-4.027v-14.985c-7.713,0-15.426,2.052-22.254,6.145'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2691.747,2102.068c2.341-3.674,5.507-6.773,9.24-9.025'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2693.245,2080.175c-2.991,1.793-5.815,3.971-8.392,6.549c-2.248,2.248-4.187,4.684-5.838,7.254'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2730.59,2093.409l8.055-12.677c-7.046-4.458-15.094-6.7-23.146-6.701v14.985     C2721.054,2089.015,2726.23,2090.63,2730.59,2093.409z'
                />
              </g>
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3569.65,2728.225c0,13.352,10.823,24.175,24.176,24.175c13.351,0,24.174-10.823,24.174-24.175    c0-13.351-10.823-24.175-24.174-24.175C3580.474,2704.05,3569.65,2714.874,3569.65,2728.225z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3241.647,2713.548c0,13.352,10.823,24.175,24.176,24.175c13.351,0,24.174-10.823,24.174-24.175    c0-13.351-10.823-24.175-24.174-24.175C3252.471,2689.373,3241.647,2700.197,3241.647,2713.548z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3280.736,2694.035c6.582-12.072,27.326-9.126,48.316-8.896c28.238,0.31,57.559,4.734,68.462,7.323    c8.427,2.001,29.013,10.003,56.827,19.648c19.396,6.726,45.023,9.381,64.584,14.833c23.646,6.591,39.074,9.723,53.262,12.061'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3261.828,2471.732c-9.555,39.31-11.793,59.998-8.557,113.067c2.3,37.729,3.244,70.585,12.941,104.061'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3193.513,2401.735c-27.195-43.298-60.062-51.626-84.538-51.095'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3267.208,2428.438c27.404,0.004,28.25-0.084,55.654-0.08c27.404,0.004,25.937-0.209,53.341-0.205'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3507.459,2581.667c-19.725-15.585-28.113-13.649-65.036-13.649s-36.924,2.451-73.846,2.142    c-28.287-0.237-31.485-1.742-44.296-1.534c0,0-12.248-0.25-20.998,7.322c-4.062,3.515-8.607,16.927-9.055,26.495    c-1.528,32.657,2.893,57.814,12.015,82.218'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3253.755,2592.444c-28.442,21.292-35.063,12.297-67.14,30.215c-34.639,19.349-35.084,31.017-69.015,32.537 '
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3401.375,2468.983c-26.659,17.192-27.208,16.341-53.868,33.533    c-26.659,17.192-32.683,12.123-57.816,37.904'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3507.112,2531.994c-14.713-3.335-20.682-11.722-49.404-3.385c-68.563,19.901-63.862,29.221-80.037,41.508'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3068.605,2249.246c0.341,32.494-0.536,43.112-0.195,75.606'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3569.746,2366.607c14.254,18.164,25.852,53.974,32.23,73.574c8.266,25.397,10.916,36.569,15.107,56.444    c6.069,28.782-21.11,76.751-29.751,88.049c-8.618,11.268-14.292,13.131-21.011,20.944c-6.137,24.235-4.063,42.468-1.988,56.68    c2.754,18.86,12.243,35.336,19.895,43.064'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3615.751,1863.25 3527.667,1932.667 3492.957,1890.138 3516.779,1870.931'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3116.744,2091.776c0,9.495,7.498,17.505,16.994,17.505c9.495,0,17.313-8.01,17.313-17.505    c0-9.496-7.818-17.529-17.313-17.529C3124.242,2074.247,3116.744,2082.28,3116.744,2091.776z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3566.322,2605.273c-35.094,0-54.734-0.48-89.829-0.48s-41.968-3.086-66.452,0.684    c-8.922,1.374-7.285,9.375-7.285,18.403'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3563.42,2655.55c-8.737,5.252-62.157,17.203-85.272,1.211c-24.817-17.169-31.2-21.576-72.023-32.063    c-15.614-4.011-27.927-4.692-55.834-4.692s-46.906-1.404-76.886,4.397'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2984.849,2553.694c8.596,7.896,14.575,13.508,34.597,36.108'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3071.767,2649.364c-9.731-4.513-33.04-37.71-44.874-50.172'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2958.073,2324.539c24.679-36.577,25.831-35.686,50.511-72.263c24.679-36.577,24.415-37.591,48.578-73.795    c0,0,8.742-12.213,18.928-8.314c9.578,3.667,10.652,16.723,10.652,16.723'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3076.785,2350.056c-89.553-1.604-100.783,20.017-107.116,64.403    c-6.333,44.385-11.766,67.607-11.244,89.791'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3114.904,2685.97c14.055,6.216,28.267,8.747,37.003,8.963c23.199,0.576,40.277-4.208,71.139-8.92    c14.825-2.263,23.178-5.341,32.003,5.911'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2987.297,3010.067c0,7.889,6.396,14.284,14.284,14.284c7.889,0,14.285-6.396,14.285-14.284    s-6.396-14.284-14.285-14.284C2993.692,2995.783,2987.297,3002.178,2987.297,3010.067z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3056.836,2561.329c-0.682-52.624,16.152-85.02,5.188-211.319'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2991.753,2433.239c0,0,45.888,2.636,73.942,0.263'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2996.456,2406.821 3011.855,2407.691 3009.954,2433.866'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2987.945,2452.664c0,0,43.124,5.387,77.799,0.48'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2975.528,2508.54c7.821-5.29,30.489-4.956,58.787-4.956s42.214,2.427,60.537,29.089'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2988.157,2523.537c50.822,0,60.59,1.079,70.88,1.653c0,0,1.984,0.406,7.589-3.838    c5.758-4.36,10.375-8.291,10.375-8.291'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2990.45,2535.46c32.394,0,50.118,7.294,66.822,12.807'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2755.262,2135.763 2819.485,2199.986 2797.916,2221.555 2733.137,2156.776'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2776.402'
                y1='2179.344'
                x2='2807.686'
                y2='2210.628'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2696.856,2157.188 2633.039,2221.005 2611.47,2199.436 2675.609,2135.297'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2509.444,2385.343 2571.891,2447.79 2593.46,2426.221 2531.014,2363.774'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2461.823,2337.722 2381.404,2257.304 2414.737,2247.498 2483.392,2316.153'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2461.694,2363.953 2407.399,2418.247 2428.969,2439.816 2483.263,2385.522'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2510.209,2315.438 2571.262,2254.385 2592.831,2275.954 2531.778,2337.007'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3316.969'
                y1='3197.307'
                x2='3182.417'
                y2='3232.634'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3060.357,2573.729c0,0-1.255,8.875,5.029,17.63c10.141,14.127,19.271,23.649,19.271,23.649    s15.465,13.573,34.279,20.283'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3193.796,2507.946c0,0,4.924,20.381,4.939,36.342s6.572,28.565,6.572,28.565'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3125.283,2526.158c0,0,1.538,14.898,10.806,27.005'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3165.312,2495.862c0,0,10.55,13.672,8.118,28.968'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3186.615,2622.659c-3.69,45.586-20.467,72.274-20.467,72.274'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3120.959,2670.583c0,0,24.111,3.616,41.538-6.856c6.468-3.887,19.629-13.765,19.629-13.765'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3107.721,2644.416c0,0,16.131-10.057,16.642-19.722c0.465-8.81,4.726-9.91,10.87-11.99    c5.571-1.886,20.316-6.8,31.995-8.614'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3034.855,2608.38c0,0,22.878-18.284,33.807-26.763c10.249-7.952,13.56-10.722,13.56-10.722'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3153.61'
                y1='2547.917'
                x2='3091.676'
                y2='2566.025'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3219.422,2495.039c-28.472,8.034-33.001,23.955-69.181,46.039'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3072.456,2588.558c0,0,10.341-8.816,18.708-10.615'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3076.367,2604.508c0,0,3.119-4.794,9.799-9.693'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3175.106,2563.375c0,0,4.521,10.822,5.375,27.057c0,0,2.365,15.585,15.692,27.206'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3188.514,2556.406c0,0,4.578,9.999,6.671,23.963'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3200.176,2689.693c0,0,13.606-62.748,13.901-78.509'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3130.22,2543.241c0,0,33.53-22.757,43.217-30.374'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3226.134'
                y1='2625.028'
                x2='3255.746'
                y2='2624.233'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3170.223'
                y1='2504.135'
                x2='3192.811'
                y2='2487.589'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3236.234,2550.803c0,0-6.158,17.625-5.626,30.974'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3195.778,2518.056 3212.468,2515.462 3215.88,2531.527 3232.5,2520.514 3252.913,2521.234'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3215.88'
                y1='2531.527'
                x2='3229.554'
                y2='2555.316'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3226.134'
                y1='2494.593'
                x2='3225.435'
                y2='2524.985'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3255.746,2501.309 3290.665,2542.033 3311.85,2570.906'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3252.273'
                y1='2533.441'
                x2='3267.755'
                y2='2548.374'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3252.273,2566.863c0,0,17.929-24.004,30.382-33.439'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3023.813'
                y1='2493.153'
                x2='3023.813'
                y2='2523.195'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3023.852'
                y1='2456.291'
                x2='3023.852'
                y2='2467.752'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3018.16'
                y1='2434.224'
                x2='3018.16'
                y2='2454.983'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3037.626'
                y1='2434.224'
                x2='3037.626'
                y2='2454.983'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3169.173,2444.79l-41.334,29.61c0,0-10.563,6.499-3.599,37.246'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3100.711,2466.33 3126.298,2475.896 3142.413,2498.503'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3147.176'
                y1='2461.243'
                x2='3163.785'
                y2='2482.837'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2975.528,2508.54c0,0,7.506-29.253,15.049-72.052c7.638-43.341,10.075-54.535,28.455-56.723    c14.758-1.757,45.098-1.44,45.098-1.44'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2972.98,2396.853c0,0,9.928,2.669,9.849,4.908s-2.925,13.422-0.314,14.143s11.369,2.458,11.369,2.458'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2984.621,2372.34c0,0,7.833,5.295,11.867,3.427s11.406-9.636,28.209-11.785s20.808-1.629,20.808-1.629'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2962.415'
                y1='2460.753'
                x2='2985.49'
                y2='2465.014'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2974.891'
                y1='2462.884'
                x2='2980.142'
                y2='2435.622'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2935.625,2428.024 2967.331,2433.804 2990.317,2437.955'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2943.125,2323.484c0,0-0.75,33.674-8.695,66.458c-5.458,22.522,1.195,38.081,1.195,38.081l-17.25,64.916    l13.511,24.058'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2980.272,2377.558c0,0-30.522-22.661-21.785-54.111'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3019.028,2379.765c0,0,1.972,24.161,2.885,28.798c3.922,0.539,23.512,0.271,26.811,0.271    c0.228,6.889,0.605,25.156,0.605,25.156'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3021.913'
                y1='2408.562'
                x2='3011.855'
                y2='2407.691'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3003.192,2340.241v-15.389c0,0-1.232-28.431,6.35-39.25'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3003.604'
                y1='2309.667'
                x2='3036.333'
                y2='2309.667'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3013.225'
                y1='2281.785'
                x2='3036.333'
                y2='2281.785'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2991.064,2365.13c0,0-13.623-9.39-7.593-17.51s12.229-6.876,23.504-6.998s74.723-1.62,74.723-1.62'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2992.43,2274.489 3009.542,2285.603 3023.167,2266.731 3069.143,2267.356'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3005.913,2467.787c-3.655,0-7.22,3.491-8.318,6.811c-0.431,1.302-0.401,2.559-0.401,3.902    c0,1.486,0,2.973,0,4.459c0,3.188,1.642,5.823,4.024,7.841c1.782,1.509,3.885,1.82,6.095,1.82c10.851,0,21.701,0,32.552,0    c1.919,0,3.599-0.05,5.376-1.03c2.789-1.538,5.046-4.952,5.188-8.147c0.112-2.537,0.289-5.032-0.061-7.438    c-0.523-3.605-3.779-7.202-7.332-8.046c-1.278-0.304-2.696-0.171-3.998-0.171c-2.523,0-5.047,0-7.57,0c-6.307,0-12.613,0-18.919,0    C3010.337,2467.787,3008.125,2467.787,3005.913,2467.787z'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3340.68'
                y1='2607.458'
                x2='3340.68'
                y2='2620.028'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3340.717'
                y1='2569.245'
                x2='3340.717'
                y2='2582.058'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3322.778,2582.092c-3.655,0-7.22,3.491-8.317,6.811c-0.432,1.302-0.401,2.559-0.401,3.902    c0,1.486,0,2.973,0,4.459c0,3.188,1.642,5.823,4.024,7.841c1.781,1.509,3.885,1.82,6.095,1.82c10.851,0,21.702,0,32.552,0    c1.92,0,3.6-0.05,5.377-1.03c2.787-1.538,5.045-4.952,5.188-8.147c0.111-2.537,0.289-5.032-0.061-7.438    c-0.523-3.605-3.779-7.202-7.332-8.046c-1.277-0.304-2.695-0.171-3.998-0.171c-2.523,0-5.047,0-7.57,0c-6.307,0-12.613,0-18.92,0    C3327.203,2582.092,3324.99,2582.092,3322.778,2582.092z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2963.857,3448.648c-4.243-2.649-10.23-2.271-13.263-0.251c-1.189,0.793-1.821,1.882-2.532,3.021    c-0.787,1.261-1.573,2.521-2.36,3.782c-1.688,2.704-1.178,6.129,0.519,9.566c1.269,2.573,3.546,4.36,6.112,5.963    c12.596,7.861,25.192,15.725,37.788,23.588c2.229,1.391,4.204,2.565,6.787,3.023c4.05,0.714,8.479-0.545,10.336-3.153    c1.472-2.072,3-4.06,3.868-6.354c1.302-3.438-0.574-8.848-4.25-12.141c-1.322-1.181-3.038-2.098-4.55-3.041    c-2.93-1.828-5.858-3.657-8.788-5.486c-7.322-4.57-14.641-9.141-21.962-13.71    C2968.993,3451.854,2966.425,3450.251,2963.857,3448.648z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3127.752,2906.704c0.062-2.395-2.408-4.795-4.793-5.577c-0.936-0.307-1.847-0.311-2.82-0.334    c-1.076-0.028-2.152-0.056-3.229-0.083c-2.309-0.06-4.246,0.967-5.748,2.49c-1.124,1.139-1.384,2.511-1.421,3.959    c-0.183,7.108-0.364,14.217-0.548,21.325c-0.032,1.258-0.024,2.359,0.656,3.542c1.067,1.854,3.501,3.397,5.814,3.549    c1.835,0.12,3.64,0.284,5.389,0.099c2.62-0.275,5.28-2.341,5.952-4.654c0.241-0.832,0.17-1.763,0.191-2.616    c0.042-1.654,0.084-3.306,0.127-4.96c0.105-4.131,0.212-8.262,0.318-12.395    C3127.678,2909.602,3127.715,2908.152,3127.752,2906.704z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3160.25,2384.125c4.478,2.702,16.5,8.125,9.875,18.312c-5.768,8.87-21.426,33.985-28.889,45.062    c-4.458,6.617-11.97,10.51-19.99,5.593c-6.856-4.203-2.324-17.782,3.92-28.76c6.089-10.705,14.629-23.982,17.657-29.025    C3148,2386.688,3152.149,2379.236,3160.25,2384.125z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3121.864,2453.453c0,0-4.838,7.653-7.851,17.85'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3164.701,2386.75c0,0,2.488-4.474,8.894-10.075'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2403.85,2626.046'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3256.212,2478.281'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2975.528,2508.54'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3057.674,2538.669 3066.062,2540.839 3088.464,2524.144'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3083.803,2518.97c0,0,3.277-4.83,10.862-3.837'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3114.863,2415.336c0,0,8.974,2.431,12.639,4.964'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3163.785,2412.544c0,0,5.999,0.709,13.231,5.304'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2962.424,2458.311l-17.633-2.713c0,0-10.044,33.891-0.783,51.436'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3219.422,2394.541c0,0-1.337-18.547,6.661-28.291c7.853-9.567,18.891-18.687,18.891-18.687'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3152.086,2360.757c0,0,22.109-29.021,42.336-42.692'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3299.042,2581.777c0,0-7.292-4.249-27.231-8.61'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3101.754,2336.34c0,0,15.021-28.78,19.758-28.56s24.981,14.02,28.306,15.752    c2.414-2.844,25.467-24.109,25.467-24.109'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3142.265,2356.227c0,0,9.761-17.035,12.735-23.061c2.262-4.583-3.328-8.814-7.845-11.211'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3103.84,2283.618c0,0,11.901-1.88,20.532,5.833s31.502,28.104,31.502,28.104'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3124.463,2623.438c0,0-9.058-8.225-20.773-13.868c-7.58-3.651-15.304-6.025-15.304-6.025'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3101.392,2563.212c0,0,2.914,38.949,12.865,52.446'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3068.686,2284.687c0,0,16.584,2.077,17.751-1.849s0.458-12.396,0.458-12.396'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3009.777'
                y1='2248.34'
                x2='2972.958'
                y2='2225.351'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2977.655,2295.564c0,0-22.605-12.683-16.801-21.874s34.694-57.399,38.219-63.375    c7.807-13.235,17.751-29.677,26.68-34.315c9.907-5.146,27.629,8.155,27.629,8.155l15.224,12.691'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3280.333,2558.868c0,0-5.346,4.564-8.522,14.298c-2.456,7.526-1.584,12.292-1.569,15.672    s0.027,9.285,0.196,13.974c0.438,12.188-0.75,21.591,10.438,45.003'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3253.948,2346.727c0,0-1.604,8.87-2.042,13.431l-0.454,6.675c0.339,13.75-5.088,35.551-5.088,35.551    h59.561'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3262.477'
                y1='2418.881'
                x2='3330.143'
                y2='2418.881'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3299.167'
                y1='2531.318'
                x2='3321.779'
                y2='2568.664'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3310.303,2549.708c0,0,43.211-17.573,68.621-28.474s83.977-50.056,83.977-50.056'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3478.361,2524.393c-2.462-17.774-4.389-31.449-19.768-62.415c-10.18-20.496-21.002-21.083-29.131-21.083'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3295.815,2647.394c0,0,13.303-4.574,28.119-4.859c14.816-0.285,37.236,3.951,45.37,5.944    s10.79-3.251,12.993-8.114s49.739,10.302,55.388,14.135s0.67,22.159-9.228,48.175'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3311.857,2620.156c0,0-1.025,17.038,1.767,23.216'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3322.869,2642.59c0,0,5.416,26.237,5.104,42.535'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3384.246,2620.837c0,0,2.348,10.143-1.949,19.528'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3367.471'
                y1='2593.895'
                x2='3403.401'
                y2='2593.895'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3404.168'
                y1='2569.245'
                x2='3402.79'
                y2='2612.528'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3385.436'
                y1='2570.062'
                x2='3385.436'
                y2='2620.156'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3309.878,2428.484c0,0-1.794,21.176-13.568,31.566l-39.653,34.989'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3462.102,2604.081 3462.102,2566.732 3448.115,2531.994'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3390.656'
                y1='2515.462'
                x2='3406.742'
                y2='2547.364'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3130.762,2231.987c0,0,34.335,9.332,65.828,35.731s54.656,51.506,82.534,69.945    c27.878,18.439,41.5,26.004,41.5,26.004'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3103.744,2187.089c0,0,7.626-43.464,23.245-79.25'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3150.277,2086.152c0,0,97.621-34.839,141.668-50.102'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3610.355,2317.188c-2.821-31.709-23.345-83.007-52.381-109.954    c-46.611-43.257-87.436-44.814-106.594-49.072c-16.551-3.679-27.771-5.568-40.307-16.408'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3167.332,2127.367 3280.009,2101.234 3180.396,2232.839'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3161.057'
                y1='2312.807'
                x2='3178.79'
                y2='2330.962'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3438.188,2674.729c0,0,53.037,14.938,64.425,11.604'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3505.016,2664.537c0,0-4.791,41.703-2.403,58.961'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3155.875'
                y1='2282.159'
                x2='3178.79'
                y2='2254.391'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3246.365,2347.222c0,0-7.617-8.297,6.664-30.044l56.624-111.471'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3299.932,2353.56l-10.652,24.607c-1.651,3.708-6.226,10.785-1.753,13.452s29.724,14.899,52.933,36.474'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3373.287'
                y1='2440.896'
                x2='3307.353'
                y2='2440.896'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3401.375,2412.809c0,0,5.625-18.142-28.088-40.103s-85.621-53.373-108.599-73.283    s-68.391-54.217-83.679-65.184s-27.09-21.421-50.048-19.255'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3229.913,1881.393c0,0-35.534,34.68-48.913,75.67c-9.229,28.277-4.084,41.304-17.208,70.438    s-26.231,46.907-26.231,46.907'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3208.521,2023.322'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3146.05,2104.544c0,0,56.52,57.675,73.227,76.458c0,0,35.813-5.656,114.95,37.102'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3266.063,2045.84c0,0,26.595,60.894,112.371,105.029'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3208.912'
                y1='2277.839'
                x2='3221.467'
                y2='2264.637'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3346.971'
                y1='2355.837'
                x2='3336.539'
                y2='2372.706'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3317.48'
                y1='2484.105'
                x2='3337.359'
                y2='2468.451'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3647.835'
                y1='2444.015'
                x2='3633.127'
                y2='2448.97'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3605.729,2373.956c0,0,42.576,100.352,41.644,171'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3582.171,2590.759c22.767-21.479,74.181-44.829,79.785-62.218s-5.026-48.542-11.431-74.936    c-4.468-18.412-8.902-31.525-20.416-55.979s-13.517-30.805-17.223-46.062c-4.307-17.731-3.629-28.287-0.946-42.886    s11.022-25.255,10.64-37.355s-18.962-56.533-26.96-66.388s-39.155-32.889-49.465-37.874c-38.426-18.58-47.288-30.72-94.44-30.72    c-14.4,0-20.11,10.525-38.289,6.104c-8.312-2.022-20.764-14.79-35.561-27.047c-18.45-15.283-63.68-48.953-76.322-66.385    c-17.456-24.069-26.337-33.764-39.543-57.514c-18.561-33.38-32.087-58.494-32.087-58.494s-20.392-32.004,13.014-70.005    s114.461-47.99,136.24-47.99c22.308,0,42.161-1,64.484-1c0,0,31.221-0.546,42.672,16.794    c12.126,18.363,15.179,21.127,30.456,40.126'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4682.032,1741.683c38.153,21.936,60.076,55.282,60.076,55.282s61.37,87.286,74.667,156.679'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3404.418,1815.011 3404.418,1858.146 3412.231,1873.144 3424.75,1873.079 3424.75,1814.792'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4113.782,2249.443c-21.441,6.154-41.267,12.828-62.724,18.987'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='4100.104,2193.167 4053.95,2206.203 4070.028,2262.229'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4089.511'
                y1='2256.409'
                x2='4063.434'
                y2='2170.5'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3067.393,2249.246 3035.725,2249.246 3023.167,2266.731'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3061.167'
                y1='2249.604'
                x2='3061.167'
                y2='2266.917'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3053'
                y1='2249.604'
                x2='3053'
                y2='2266.917'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3045.208'
                y1='2249.604'
                x2='3045.208'
                y2='2266.917'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3036.333'
                y1='2249.604'
                x2='3036.333'
                y2='2266.917'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3224.92'
                y1='2367.826'
                x2='3251.243'
                y2='2373.596'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3238.082'
                y1='2370.711'
                x2='3232.48'
                y2='2396.261'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3343.808'
                y1='2089.591'
                x2='3323.214'
                y2='2114.38'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3337.18'
                y1='2083.807'
                x2='3316.586'
                y2='2108.596'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3330.793'
                y1='2078.982'
                x2='3310.781'
                y2='2101.986'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3547.652'
                y1='2198.237'
                x2='3589.77'
                y2='2135.236'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3370.321'
                y1='2620.156'
                x2='3369.304'
                y2='2648.479'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3327.412,2963.726c15.963-24.288,30.047-42.987,36.409-40.513s38.318,27.98,39.168,30.878    c-2.599,6.485-4.291,13.159,4.605,21.999c5.94,5.903,22.473,21.618,30.649,33.976c4.311,6.517,7.89,14.685,11.619,24.265    c-1.439,25.874-5.383,61.415-5.383,61.415l7.441,22.469'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3466.857,3005.238c0,0-16.652,10.262-23.449,13.791'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3438.244,3010.066c0,0-28.447,17.62-42.879,22.692'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3415.882,2940.029c0,0-9.079,5.093-13.126,14.588'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3430.335'
                y1='2959.174'
                x2='3407.595'
                y2='2976.09'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3309.878,2451.888c0,0,17.303,0.86,27.481,16.563c6.748,10.409,18.092,13.212,26.021,9.831    c14.969-6.384,10.9-28.531-13.966-25.533'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3366.268'
                y1='2527.344'
                x2='3385.267'
                y2='2563.432'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3314.927'
                y1='2557.093'
                x2='3369.304'
                y2='2533.939'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3306.871,2543.603c0,0,34.133-15.936,65.631-32.436s53.991-30.732,53.991-30.732'
              />
              <g>
                <rect
                  x='3393.927'
                  y='2433.447'
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  width='14.896'
                  height='14.898'
                />
              </g>
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3415.856'
                y1='2464.962'
                x2='3433.294'
                y2='2490.328'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3023.45,3141.328c-46.45,76.467-21.41,103.787,17.237,126.516c16.733,9.841,28.874,17.553,40.062,24.307'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3064.691,3253.547c0,0,5.437-8.147,9.356-15.421c6.535-14.511,15.686-35.8,20.752-51.864'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3043.804,3240.163 3049.682,3225.861 3073.951,3237.959'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3101.042'
                y1='3232.396'
                x2='3123.649'
                y2='3240.163'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3077.4'
                y1='3230.504'
                x2='3098.029'
                y2='3238.981'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3084.799'
                y1='3212.5'
                x2='3105.428'
                y2='3220.977'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3108.027,3281.421c-4.202-2.199-5.31-3.206-39.017-24.543c-37.186-23.539-46.05-29.9-41.65-47.879    c4.399-17.979,20.639-51.999,20.639-51.999'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3026.173,3258.461c0,0,4.28-5.766,6.902-8.295c2.041,0.925,8.747,6.996,12.778,8.295    c1.659-2.142,7.649-11.531,7.649-11.531'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3000.483,3225.861c0,0,13.352-1.171,13.058-5.605c-0.403-6.075-1.448-10.725,1.373-22.496    c3.948-16.474,13.721-35.051,13.721-35.051'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3080.75'
                y1='3292.15'
                x2='3093.46'
                y2='3272.426'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3087.463'
                y1='3281.421'
                x2='3064.242'
                y2='3266.2'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3011.38,3244.837c0,0-33.319,27.673-57.442,0.54'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3027.358,3209.002c0,0,23.993,10.981,25.982,7.558c1.989-3.422,12.18-23.208,13.843-23.297    c0.546-0.029,21.847,8.323,21.847,8.323'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3053.34'
                y1='3216.56'
                x2='3049.614'
                y2='3226.024'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3000.483,3225.861c0,0-10.812,8.29-14.858,5.097c-10.875-8.583-11.173-24.778-8.875-34.458    c6.992-29.449,10.613-36.722,28.782-65.991'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3070.268,3257.675l-12.898,20.161l8.836,5.414l-23.664,20.959c0,0-9.454-3.425-29.299-15.392    c-19.586-11.811-31.334-30.267-31.624-30.729'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3053.57'
                y1='3087.448'
                x2='3029.777'
                y2='3063.223'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3088.581,3049.241 3046.417,3008.604 3015.865,3008.604'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3113,3052c10.129,31.95,15.812,49.107,20.258,71.707'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3045.625,3137.906c-9.981-5.757-31.542-25.288-45.992-38.339'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3036.064,3100.11c-11.332-13.764-13.948-27.527-13.948-27.527'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3125.874,3092.564c0,0,10.121-6.473,19.499-10.894s18.072-11.795,18.072-11.795'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3158.033,3022.406c0,0,4.134,17.023,3.703,32.979c-0.932,34.466,31.341,47.93,31.341,47.93'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3088.283,3048.158c0,0,4.623,16.759,7.543,27.387'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3104.206,3192.546c0,0,13.795-10.657,29.146-20.367c9.013-5.701,26.462-17.644,26.462-17.644    l40.067-10.054'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3076.258,3174.799c0,0,8.139-1.237,8.734-17.158c0.543-14.509,5.512-16.32,12.68-19.746    c6.499-3.105,21.961-11.2,35.585-14.188c0,0,5.436,16.826,7.686,19.666'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3018.273,3114.916c0,0,11.797-10.543,21.775-17.793c9.869-7.171,12.504-9.305,12.504-9.305'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3117.943'
                y1='3069.272'
                x2='3052.553'
                y2='3087.817'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3080.187,3106.273c0,0,9.153-3.316,15.209-4.907'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3139.24,3084.399c0,0,3.537,13.434,6.26,26.893c0,0,6.724,24.45,24.386,40.716'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3112.14'
                y1='3203.748'
                x2='3152.132'
                y2='3178.351'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3152.311,3078.096c0,0-4.323-9.368-15.54-10.471c-7.875-0.774-18.828,1.647-18.828,1.647'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3087.108,3145.438c0,0-9.526-8.985-21.848-15.149c-7.972-3.989-16.095-6.582-16.095-6.582'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3073.839,3081.885c0,0,8.247,36.058,18.162,58.972'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3152.211,3128.468c0,0,1.039,6.782-16.211,18.532s-35.229,22.812-35.229,22.812l-16.046-8.29'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3141.419'
                y1='3167.244'
                x2='3209.534'
                y2='3167.438'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3209.534,3167.438c0,0,45.994-0.7,51.533,8.164c7.173,11.479,10.958,33.061,10.958,33.061'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3105.365,3134.321c0,0-9.032-27.905-16.479-56.809'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3121.365,3126.508'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3140.186,3166.553c-11.15-14.752-11.15-14.752-11.15-14.752'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3131.25,3150.346c0,0-5.583-11.755-10.562-22.804'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3208.024'
                y1='3012.26'
                x2='3196.431'
                y2='3025.118'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3214.334'
                y1='3017.703'
                x2='3202.741'
                y2='3030.561'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3202.105'
                y1='3007.825'
                x2='3190.511'
                y2='3020.683'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3196.629'
                y1='3002.852'
                x2='3185.036'
                y2='3015.709'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3191.435'
                y1='2997.687'
                x2='3179.841'
                y2='3010.545'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3231.187,3032.758l-41.672-36.767c0,0-13.106,1.446-23.019,4.311'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3220.101'
                y1='3044.061'
                x2='3168.479'
                y2='3000.566'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3142.819'
                y1='3031.866'
                x2='3131.409'
                y2='3019.625'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3131.548,3019.042c0,0,33.111-21.718,36.931-18.476'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2754.244'
                y1='2264.88'
                x2='2673.034'
                y2='2181.871'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2923.565,2096.67c-64.296-57.58-93.655-41.79-120.612-12.285c-26.958,29.506-38.12,37.114-49.686,54.243'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2835.282,2084.821 2844.231,2094.491 2834.781,2115.924'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2801.209,2120.316c0,0,32.341,25.293,58.307,39.701'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2790.78'
                y1='2150.564'
                x2='2812.167'
                y2='2128.944'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2838.191'
                y1='2119.657'
                x2='2825.846'
                y2='2138.231'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2860.42'
                y1='2131.099'
                x2='2846.688'
                y2='2151.812'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2748.401,2172.041c0,0,0.158-0.148,0.467-0.439c3.469-3.263,25.801-24.446,54.958-55.842    c32.167-34.637,40.581-42.766,57.432-35.141c16.852,7.627,47.691,36.078,47.691,36.078'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2775.534'
                y1='2113.052'
                x2='2791.502'
                y2='2128.479'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2868.533,2084.821c0,0-12.727,17.642-10.27,19.827c2.456,2.187,16.883,13.732,16.666,15.133    c-0.072,0.459-7.713,14.072-7.713,14.072'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2766.844'
                y1='2188.788'
                x2='2775.896'
                y2='2179.948'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2612.834,2200.834c-17.772,15.791-23.204,24.945-25.663,33.836c-6.361,23.024,13.114,40.043,9.578,88.734'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2803.625,2260.032c0,0-30.639,13.115-44.318,23.989c-11.823,9.399-16.509,24.917-16.509,24.917'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2459.783,2291.644 2480.776,2284.966 2482.252,2258.086 2528.613,2212.434'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2515.316,2198.316 2483.297,2230.462 2482.233,2258.437'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2505.754'
                y1='2209.287'
                x2='2518.021'
                y2='2221.505'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2500.633'
                y1='2214.431'
                x2='2512.899'
                y2='2226.649'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2495.492'
                y1='2218.989'
                x2='2507.758'
                y2='2231.206'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2490.516'
                y1='2223.697'
                x2='2503.104'
                y2='2236.229'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2485.482'
                y1='2228.73'
                x2='2498.07'
                y2='2241.263'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2483.299'
                y1='2236.489'
                x2='2493.094'
                y2='2246.24'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2584.744,2172.839c-19.666,46.15-11.191,49.603-13.254,81.224'
              />

              <rect
                x='2802.962'
                y='2253.673'
                transform='matrix(0.3241 -0.946 0.946 0.3241 -240.5932 4193.7378)'
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                width='23.132'
                height='23.132'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2807.334'
                y1='2250.549'
                x2='2813.833'
                y2='2230.334'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2920.913,2193.125c-19.837-12.698-46.497,19.662-64.164,36.197'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2538.913,2286.771c-10.881-24.209-23.258-35.044-36.249-48.271'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2497.204'
                y1='2301.394'
                x2='2528.899'
                y2='2269.698'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2549.516,2382.296c9.046-8.921,6.502-58.892,0.662-63.442'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2777.31,2241.69c-8.828-10.464-20.037-21.331-29.6-23.644c-10.591-2.562-25.35,14.494-25.35,14.494'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2634.429,2315.561c0,0,12.508-12.472,21.237-26.703c9.403-15.329,19.924-31.88,27.729-37.122    c14.169-9.517,27.781-22.421,31.964-26.295'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2660.93,2281.787c0,0,15.33,14.013,22.01,21.282'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2671.956,2116.812c0,0-49.106,1.335-95.952,46.48'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2647.845'
                y1='2119.446'
                x2='2662.754'
                y2='2090.418'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2612.888'
                y1='2136.373'
                x2='2646.448'
                y2='2164.149'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2659.187,2195.042c7.726,8.15,10.81,12.742,10.81,12.742l-1.821,26.644l15.742,16.53'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2568.285,2300.367 2551.501,2283.631 2576.004,2259.127'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2792.232,2366.074c0,0,12.525-12.175,18.008-11.337c5.485,0.839,27.73,14.143,31.431,15.924    c3.067-1.806,30.485-13.788,30.485-13.788'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2841.67'
                y1='2370.661'
                x2='2838.655'
                y2='2420.907'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2756.368,2180.007c0,0-6.938,7.525-16.855,14.94c-9.913,7.412-16.942,1.504-34.45,19.012'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2568.496,2451.038c0,0,23.656,29.824,50.564,11.505'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2600.862,2513.405c-23.105-23.094-37.362-40.071-51.646-54.431c-27.319-27.465-36.11-29.474-35.591-42.095 '
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2874.667,2313.396c-45.604,2.165-54.692-19.103-54.692-19.103'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2545.826,2473.708c-3.838,0-10.104-0.027-16.701-0.027c-28.458-11.837-79.929-30.997-79.929-30.997    l-10.156,21.433'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3039.065,2837.694c0,0,10.768-22.941,41.079-48.583c29.703-25.127,65.971-34.354,82.993-38.684    c10.797-2.747,22.613-3.578,29.479-3.77'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3029.275,2969.361 3023.051,2975.5 3012.328,3000.333'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3088.28'
                y1='2917.667'
                x2='3143.859'
                y2='2917.667'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3192.617,2803.555c-0.492,11.882-15.531,17.547-20.525,20.235'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3249.545'
                y1='2731.102'
                x2='3249.513'
                y2='2746.659'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3171.544,2868.422c0,0,18.183,15.578,21.951,42.307'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3181.343,2938.042c0,0-9.069-21.304,6.657-25.871c23.691-6.879,70.955-10.819,84.332-11.905'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3201.919,2803.606c0,0,10.682,30.987,13.506,50.94s7,50.383,7,50.383'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3173.352,2748.605c0,0-0.99,39.414-14.867,61.254'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3135.733'
                y1='2758.84'
                x2='3135.733'
                y2='2803.235'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3221.934'
                y1='2935.511'
                x2='3223.52'
                y2='2983.34'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3117.943,3005.753l13.466,13.872c-9.688,7.617-43.217,30.374-43.217,30.374'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3221.853,2959.908c0,0-10.27,0-31.287,2.404c-20.812,2.381-32.754,5.688-32.754,5.688'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3280.875'
                y1='2732.944'
                x2='3304.941'
                y2='2756.142'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3178.167'
                y1='2845.67'
                x2='3212.987'
                y2='2845.67'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3279.986'
                y1='2934.328'
                x2='3308.16'
                y2='2930.69'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3111.724,3257.194l-17.829-7.378l-0.849-0.44c0,0,11.43-21.771,20.486-50.053l-0.481-0.367    l-125.752-80.707c0,0-33.626-4.92-56.626,12.012c-15.187,11.18-28.657,30.356-28.657,30.356l-123.64-0.795'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3369.75,2753.372c-3,17.372-7.5,22.878-21.46,21.001s-27.04,0.127-28.29,17.853    s-29.332,14.106-29.332,14.106s-27.387-2.497-41.155-2.777'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3291.647'
                y1='2829.128'
                x2='3342.651'
                y2='2833.981'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3388.134'
                y1='2805.746'
                x2='3388.134'
                y2='2742.036'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3362.334'
                y1='2771.824'
                x2='3367.918'
                y2='2810.509'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3318.887'
                y1='2797.439'
                x2='3366.416'
                y2='2800.107'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3346.828,2887.175c0,0-18.898,12.833-46.719,15.411'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2910.543,3364.4c0,18.654,15.122,33.775,33.775,33.775s33.775-15.121,33.775-33.775    c0-18.653-15.122-33.774-33.775-33.774S2910.543,3345.747,2910.543,3364.4z'
              />
              <circle
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                cx='3412.43'
                cy='3409.075'
                r='16.822'
              />
              <g>
                <rect
                  x='3405.24'
                  y='3401.887'
                  transform='matrix(0.3635 -0.9316 0.9316 0.3635 -1003.8367 5348.936)'
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  width='14.381'
                  height='14.377'
                />
              </g>
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2960.472'
                y1='3182.68'
                x2='2915.66'
                y2='3186.657'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2884.667,3280.688v-80.298h29.508c0,0,23.308,2.398,23.891-7.556c0.957-16.315,2.684-22.229,7.434-24.156    s19.473-1.627,19.473-1.627'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2953.938,3221.292c0,0-15.057-1.069-15.873,4.569s-2.816,27.953,1.184,33.921s15.866,2.822,15.866,2.822'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2867.963'
                y1='3335.5'
                x2='2926.834'
                y2='3335.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2966.759,3314.229c0,0-13.176-18.031-40.93-7.118'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2923.129,3298.088c0,0-29.129,1.48-30.218,37.412'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2928.502'
                y1='3394.245'
                x2='2891.383'
                y2='3446.414'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2957.541'
                y1='3502.078'
                x2='2971.353'
                y2='3482.603'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3054.676,3043.399c-1.085,14.208-2.4,25.584-2.4,53.157c0,20.673-5.692,43.092-11.228,56.006'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3135.733'
                y1='2784.75'
                x2='3168.39'
                y2='2784.75'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3266.197,2857.317c0,0,43.743-0.308,70.971,0'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3190.243,2896.542c0,0,16.008-2.417,15.203-5.208c-1.402-4.863-8.864-29.466-8.279-31    c1.333-3.5,18.611-3.288,18.611-3.288'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3401.939'
                y1='2764.892'
                x2='3401.939'
                y2='2789.75'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3388.134,2789.75 3454.54,2789.75 3454.54,2805.746 3428.771,2826.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3317.149,2831.699c0,0-3.417,25.468,1.675,49.053c-7.157,2.539-6.074,9.977-5.01,19.667'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3303.168,2917.725c0,0,30.22-5.352,51.303-22.149'
              />
              <rect
                x='3192.616'
                y='2746.658'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                fill='none'
                width='56.896'
                height='56.897'
              />

              <rect
                x='3041.059'
                y='2940.912'
                transform='matrix(0.7071 -0.7071 0.7071 0.7071 -1200.6176 3040.1533)'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                fill='none'
                width='56.896'
                height='56.896'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3365.085'
                y1='3159.863'
                x2='3339.069'
                y2='3080.847'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3239.941'
                y1='2902.919'
                x2='3233.546'
                y2='2846.062'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3150.741,2885.972c0,0,12.359,42.164,15.134,54.601'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3069.507'
                y1='3009.592'
                x2='3082.952'
                y2='3023.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3109.739,2969.361c0,0,24.797-9.386,44.343-13.261'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3222.732'
                y1='2954.083'
                x2='3279.986'
                y2='2954.083'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3173.284'
                y1='2964.636'
                x2='3178.209'
                y2='2987.675'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3207.609'
                y1='2960.743'
                x2='3210.229'
                y2='3014.254'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3149.397'
                y1='2994.523'
                x2='3142.167'
                y2='2970.667'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3135.945'
                y1='2998.757'
                x2='3127.876'
                y2='2975.17'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3122.829,3003.705l-8.978-22.812c0,0,18.06-8.394,43.232-15.118'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3189.515'
                y1='2995.991'
                x2='3209.191'
                y2='2993.046'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3107.868,3036.49L3113,3052c36.18-22.084,34.758-31.317,71.725-36.535'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3052.875,3079.425 3063.192,3077.076 3064.359,3052.217'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3064.031'
                y1='3063.406'
                x2='3073.585'
                y2='3063.75'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3073.969'
                y1='3056.25'
                x2='3073.058'
                y2='3074.062'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3134.901,3037.693c0,0,3.613,7.252,3.689,30.196'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3044.749'
                y1='3142.818'
                x2='3083.563'
                y2='3166.484'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3128.033'
                y1='3142.329'
                x2='3137.808'
                y2='3137.307'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3124.817'
                y1='3134.384'
                x2='3135.195'
                y2='3130.509'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2997.872'
                y1='3143.022'
                x2='2978.958'
                y2='3133.688'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2990.592'
                y1='3155.373'
                x2='2972.106'
                y2='3148.057'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2960.472'
                y1='3182.958'
                x2='2978.576'
                y2='3189.029'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2983.312'
                y1='3173.969'
                x2='3003.406'
                y2='3181.938'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3304.941'
                y1='2756.142'
                x2='3313.088'
                y2='2764.875'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3302.141,2797.173c0,0-2.037-19.932,11.918-31.666c14.754-12.404,45.473-3.747,45.473-3.747'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3310.417'
                y1='2816.667'
                x2='3352.25'
                y2='2820.754'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3319.082'
                y1='2880.656'
                x2='3340.188'
                y2='2875.375'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3319.496'
                y1='2899.965'
                x2='3323.074'
                y2='2911.389'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3330.531'
                y1='2895.5'
                x2='3335.562'
                y2='2906.363'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3341.084'
                y1='2891.033'
                x2='3348.631'
                y2='2900.059'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3314.207,2925.25 3318.562,2932.601 3323.469,2929.326 3341.223,2943.406'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3346.811,2900.978 3351.438,2909.625 3333.488,2918.812 3350.217,2932.005'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3056.467'
                y1='3162.68'
                x2='3036.064'
                y2='3212.658'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2918.947'
                y1='3280.688'
                x2='2956.554'
                y2='3272.938'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2867.606'
                y1='3280.688'
                x2='2918.947'
                y2='3280.688'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3182.674'
                y1='3167.051'
                x2='3182.674'
                y2='3232.459'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3182.167'
                y1='3178.324'
                x2='3152.629'
                y2='3178.324'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3114.938'
                y1='3237.511'
                x2='3107.447'
                y2='3255.387'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2747.266'
                y1='2422.266'
                x2='2787.25'
                y2='2382.546'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3322.277'
                y1='2340.727'
                x2='3444.56'
                y2='2156.998'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3485.667'
                y1='2604.802'
                x2='3485.667'
                y2='2659.996'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3138.609'
                y1='3186.71'
                x2='3207'
                y2='3186.71'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3223.5'
                y1='3278.667'
                x2='3343.603'
                y2='3317.088'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3191.736,3314.464 3206.841,3331.667 3326.713,3371.106 3408.347,3399.764'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3251.833'
                y1='3287.903'
                x2='3235.072'
                y2='3340.955'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3206.841'
                y1='3331.667'
                x2='3237.776'
                y2='3455.396'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3160.587'
                y1='3372.125'
                x2='3213.002'
                y2='3359.542'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3153.163,3327.04 3152.5,3341.833 3185.167,3472.647'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3113.175'
                y1='3474.774'
                x2='3152.5'
                y2='3341.833'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2996.753'
                y1='3420.238'
                x2='3100.501'
                y2='3325.004'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2986.014'
                y1='3354.408'
                x2='3042.542'
                y2='3304.209'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3237.883'
                y1='3218.497'
                x2='3251.777'
                y2='3271.221'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3077.469'
                y1='3346.146'
                x2='3040.856'
                y2='3305.706'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3091.659'
                y1='3354.388'
                x2='3143.387'
                y2='3370.53'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3416.269,2939.488 3440.083,2920.171 3475.359,2963.662 3450.738,2983.325'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3461.881,2997.635 3486.713,2980.233 3518.395,3026.408 3492.731,3044.09'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3502.554,3057.343 3528.494,3042.386 3556.538,3090.856 3529.747,3106.029'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3537,3123.593 3565.521,3110.818 3587.025,3162.533 3559.017,3174.827'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3564.607,3187.99 3593.567,3178.995 3608.375,3231 3578.568,3239.895'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3580.924,3251.064 3611.562,3245.938 3619.323,3299.851 3588.5,3303.729'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3404.459,3423.579c0,0-61.012,44.087-99.162,73.036c-18.915,14.353-58.297,37.75-100.374,54.75    s-61.673,6-104.422,0s-51.942-17.241-78.571-19.3c-27.596-2.134-62.213-28.398-62.213-28.398    c-24.945-17.247-63.789-47.38-81.289-72.105c-16.093-22.738-14.149-59.478-12.171-80.841c3.611-39.002-0.567-80.005,2.605-109.691 '
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3383.92,3391.188c0,0,3.086-26.033-4.248-49.888c-5.228-17.002-36.069-24.213-36.069-24.213'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3284.584'
                y1='3424.877'
                x2='3328.923'
                y2='3478.954'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3202.339'
                y1='3470.311'
                x2='3227.429'
                y2='3541.584'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3152.5'
                y1='3476.546'
                x2='3152.5'
                y2='3559.645'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3351.438'
                y1='2909.625'
                x2='3365.188'
                y2='2902.834'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3417.672'
                y1='3394.215'
                x2='3413.83'
                y2='3401.903'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3404.459'
                y1='3423.579'
                x2='3409.247'
                y2='3415.552'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3418.612'
                y1='3413.009'
                x2='3427.76'
                y2='3416'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3207.116,3167.442 3207.572,3226.029 3222.309,3278.539 3205.666,3330.328 3152.5,3341.833     3099.529,3325.896 3063.265,3286.472'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3457.086'
                y1='2941.133'
                x2='3627.8'
                y2='2803.481'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3502.545'
                y1='3002.927'
                x2='3695.075'
                y2='2872.287'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3542.516'
                y1='3066.621'
                x2='3754.098'
                y2='2964.602'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3577.224'
                y1='3136.953'
                x2='3806.052'
                y2='3046.903'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3601.167'
                y1='3205.686'
                x2='3700.105'
                y2='3173.524'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3616.842'
                y1='3273.267'
                x2='3698.264'
                y2='3257.67'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3430.393'
                y1='2778.059'
                x2='3430.393'
                y2='2789.562'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3423.688'
                y1='2775.404'
                x2='3423.688'
                y2='2789.562'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3275.206'
                y1='2307.87'
                x2='3392.523'
                y2='2129.44'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3560.574,2209.734 3418.88,2403.249 3600.839,2277.336'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3609.697'
                y1='2339.055'
                x2='3450.072'
                y2='2449.139'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3490.333,2421.374 3507.459,2533.5 3507.459,2604.802'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3542.469,2384.771 3559.333,2531.318 3563,2605.195'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3274.904'
                y1='2108.93'
                x2='3366.83'
                y2='2168.518'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3378.434'
                y1='2150.869'
                x2='3538.609'
                y2='2238.158'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3511.647'
                y1='2339.055'
                x2='3542.469'
                y2='2384.771'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3613.174,1865.281c8.233,10.052,21.409,27.2,33.493,40.168c7.833,9.115,51.064-10.576,78.583-19.199    c82.793-25.943,175.809-92.459,197.547-109.24c9.063-6.997,42.911-24.57,55.37-26.997c17.25-3.36,59.334,2.984,71.834,3.902    s16.602-0.044,54.999-0.88c34.666-0.755,65.812,7.507,81.657,12.324c15.847,4.817,29.734-1.301,48.801-8.176    c28.543-10.292,45.056-23.301,103.23-52.184c57.808-28.7,75.181-75.678,75.181-75.678c10.481-11.052,26.47-36.839,29.896-48.45    c3.428-11.61-0.299-59.627-4.807-71.492c-4.509-11.865-26.883-43.5-35.111-51.463c-30.673-29.682-30.306-36.676-61.93-51.99    c0,0-19.026-7.885-28.599-4.718c-7.21,2.385-12.027,9.273,3.312,18.568c16.783,10.17,55.142,24.312,85.857,79.992    c19.134,34.686,23.055,104.885,11.382,129.553'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4661.276,683.029c43.067-14.827,43.708-42.289,72.469-65.901s37.489-74.637,34.364-94.011    c-11.167-69.248-76.353-61.164-133.235-62.844'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4366.583,646.568c-25.589-11.542-58.878-49.023-69.927-55.267c-11.051-6.244-48.478-16.279-59.894-17.145    c-25.775-1.954-51.985-6.191-71.996-17.261'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3344.262,2940.029 3381.875,2962.851 3359.135,3001.191'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3381.875,2962.851c0,0,10.557,4.399,18.686,0.815'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3224.118,3221.686c0,0-0.69-24.061,3.159-25.686s38.273-11.064,38.273-11.064'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2984.484,3262.354l-12.808,10.412c0,0,8.761,13.554,20.792,25.395s29.461,24.354,29.461,24.354'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2921.303'
                y1='3291.235'
                x2='2958.324'
                y2='3283.145'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3476.458'
                y1='3018.958'
                x2='3420.39'
                y2='3051.367'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3389.667,2980.46 3423.816,3019.189 3435.572,3042.529'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3316.969'
                y1='3197.278'
                x2='3327.402'
                y2='3194.433'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3321.905,3182.105l5.497,12.327c0,0,6.657,12.817,19.473,20.538'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3498.519'
                y1='3237.302'
                x2='3538.765'
                y2='3350.252'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3580.164'
                y1='2383.206'
                x2='3617.791'
                y2='2368.963'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3541.999'
                y1='2605.195'
                x2='3541.999'
                y2='2661.544'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3507.112'
                y1='2531.994'
                x2='3559.333'
                y2='2531.318'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4797.444'
                y1='1857.123'
                x2='4784.033'
                y2='1864.934'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4830.746,1928.201c-5.101-15.467-17.155-37.658-28.737-62.224c-8.08-17.137-15.061-29.091-31.259-50.729'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4767.825'
                y1='1929.127'
                x2='4801.817'
                y2='1907.607'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3932.144'
                y1='1183.521'
                x2='3925.12'
                y2='1197.361'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3977.553,1211.724c-8.356-6.008-13.708-8.898-36.771-23.243c-16.087-10.008-28.439-16.259-53.787-25.641    c-25.348-9.382-32.741-13.09-50.512-20.522'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3833.538'
                y1='1204.106'
                x2='3857.042'
                y2='1151.068'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4700.138'
                y1='2018.99'
                x2='4705.669'
                y2='2036.045'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4611.921,2066.798c0,0,139.25-50.058,183.54-49.157c16.329,0.332,41.672,34.079,81.322,32.298    c36.75-1.65,58.575-7.818,58.575-7.818c12.7-5.278,42.222-13.913,61.255-6.948c19.034,6.964,22.036,16.042,22.862,32.583    s-8.147,74.648-10.416,86.258l-8.651,53.924c-2.995,30.939,6.875,48.928,12.612,55.273c10.146,11.221,23.12,15.506,37.228,18.39    c46.197,9.444,52.448,23.113,86.376,49.013c9.101,6.948,35.014,35.756,40.876,47.014c5.861,11.256,13.146,53.185,13.116,70.443'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3400.739,2403.249 3418.88,2403.249 3517.868,2402.384'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3197.299'
                y1='2207.357'
                x2='3113.876'
                y2='2152.465'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3280.009'
                y1='2101.234'
                x2='3296.036'
                y2='2088.644'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2627.687,2487.5v46.75h19.604v-67.367c0,0.95-2.839-15.073-13.553-32.189    c-12.608-20.142-21.446-27.452-21.446-27.452'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2647.688,2490.566 2654.367,2490.566 2654.367,2515.25 2647.291,2515.25'
              />
              <rect
                x='2569.645'
                y='2323.703'
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                width='54.212'
                height='54.212'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2561.807'
                y1='2306.978'
                x2='2569.645'
                y2='2323.703'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2623.856,2323.703c0,0,5.434-0.198,9.776-7.071'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2623.587,2377.915c0,0,6.212-0.021,10.75,7.282'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2526.625,2402.099c0,0,8.229-10.595,14-6.78c2.93,1.937,5.975,6.776,9.062,7.744    c3.5,1.097,9.207-2.779,12.805-7.81c6.921-9.68,7.152-17.337,7.152-17.337'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2529.125'
                y1='2503.25'
                x2='2563.184'
                y2='2473.648'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2595.887'
                y1='2468.191'
                x2='2594.795'
                y2='2480.562'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2612.661,2480.542c0,0-7.366,0.82-17.866,0.177s-15.396-3.897-15.396-3.897l-8.107-7.988'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2586.85,2433.044 2597.634,2443.33 2595.887,2468.191'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2622.718'
                y1='2418.107'
                x2='2681.057'
                y2='2386.868'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2639.868,2379.666c0,0,6.341,10.096,12.155,6.834c9.307-5.221,20.196-11.312,20.196-11.312'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2679.573'
                y1='2434.694'
                x2='2655.071'
                y2='2400.783'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2770.032'
                y1='2344.235'
                x2='2839.176'
                y2='2412.229'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2788.775,2099.619 2788.775,1748.667 2733,1748.667'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2697.439,1748.667 2642.125,1748.667 2642.125,2010.738 2642.125,2037.875 2657.908,2087.073     2675.75,2099.39'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2805.639,2355.891c0,0,15.021-7.841,36.169-45.354'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2821.054'
                y1='2341.392'
                x2='2814.25'
                y2='2335.049'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2815.821'
                y1='2347.016'
                x2='2809.017'
                y2='2340.673'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2823.761'
                y1='2338.57'
                x2='2861.063'
                y2='2361.757'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2831.243'
                y1='2327.728'
                x2='2873.442'
                y2='2335.059'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2624.679'
                y1='2373.862'
                x2='2657.771'
                y2='2340.771'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2802.179'
                y1='2312.358'
                x2='2824.87'
                y2='2335.049'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2751.728,2292.312c0,0,24.037,9.099,31.239,39.438'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2810.872,2276.755c0,0-3.635,17.193-10.649,15.77s-11.608-3.295-16.535,2.226    c-4.347,4.871-11.608,13.82-11.608,13.82'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2866.375'
                y1='2348.219'
                x2='2865.422'
                y2='2359.831'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2854.808'
                y1='2332.188'
                x2='2851.086'
                y2='2355.556'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2859.286'
                y1='2347.527'
                x2='2858.333'
                y2='2359.139'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2861.453'
                y1='2323.478'
                x2='2862.59'
                y2='2313.361'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2854.133'
                y1='2322.619'
                x2='2855.598'
                y2='2312.996'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2847.32'
                y1='2321.312'
                x2='2848.719'
                y2='2311.938'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2868.323'
                y1='2324.75'
                x2='2869.67'
                y2='2313.729'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2829.101,2303.921 2821.938,2315.938 2834.129,2323.338'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2847.242'
                y1='2288.5'
                x2='2879.703'
                y2='2288.5'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2862.506'
                y1='2273.08'
                x2='2882.876'
                y2='2273.08'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2789.131'
                y1='2290.961'
                x2='2782.967'
                y2='2281.626'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2773.052,2287.698c0,0,15.888-10.845,26.785-15.266'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2829.218,2258.045c0,0,2.384-5.948-1.301-20.649c-2.5-9.973-10.486-34.774-10.486-34.774'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2801.151,2261.117c0,0-1.411-15.364-16.621-26.647'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2623.957'
                y1='2341.801'
                x2='2639.868'
                y2='2357.713'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2653.802,2291.788 2630.875,2278.281 2611.406,2273.25'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2632.667'
                y1='2294.258'
                x2='2586.144'
                y2='2248.832'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2597.893'
                y1='2309.438'
                x2='2612.111'
                y2='2309.438'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2539.796'
                y1='2329.627'
                x2='2539.796'
                y2='2371.522'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2768.88'
                y1='2229.75'
                x2='2787.496'
                y2='2211.135'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2761.324'
                y1='2247.956'
                x2='2772.364'
                y2='2236.916'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2756.022'
                y1='2242.653'
                x2='2768.701'
                y2='2229.974'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2750.935'
                y1='2237.566'
                x2='2761.9'
                y2='2226.601'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2745.886'
                y1='2232.517'
                x2='2755.758'
                y2='2222.645'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2740.792'
                y1='2227.423'
                x2='2748.825'
                y2='2219.389'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2862.506'
                y1='2251.761'
                x2='2841.542'
                y2='2178.229'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2516.274'
                y1='2425.773'
                x2='2541.625'
                y2='2425.773'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2524.479'
                y1='2434.626'
                x2='2546.312'
                y2='2434.626'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2483.712'
                y1='2455.587'
                x2='2525.688'
                y2='2455.587'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2459.312'
                y1='2446.333'
                x2='2514.688'
                y2='2446.333'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2506.625'
                y1='2464.44'
                x2='2526.75'
                y2='2464.44'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2687.058'
                y1='2166.987'
                x2='2721.425'
                y2='2201.354'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2528.613'
                y1='2330.964'
                x2='2563.546'
                y2='2296.032'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2748.401,2172.041c0,0-10.461,10.75-19.776,6.34c-6.806-3.222-15.041-8.506-25.812,3.17'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2890.673'
                y1='2198.334'
                x2='2847.812'
                y2='2198.334'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2694.889'
                y1='2203.826'
                x2='2708.926'
                y2='2189.788'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2679.785'
                y1='2188.535'
                x2='2693.822'
                y2='2174.497'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2690.011'
                y1='2198.949'
                x2='2704.048'
                y2='2184.911'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2602.853,2265.018 2615.969,2251.902 2613.758,2228.271 2626.469,2215.531'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2633.042,2278.812 2640.212,2267.312 2615.969,2251.902'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2847.812,2171.992 2893.076,2171.992 2893.076,2196.308'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3015.667'
                y1='1998.112'
                x2='2939.895'
                y2='2071.359'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2642.125'
                y1='2010.738'
                x2='2733.085'
                y2='2010.738'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2697.946'
                y1='1748.667'
                x2='2733.085'
                y2='1748.667'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2697.946'
                y1='1882.744'
                x2='2733.085'
                y2='1882.744'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2791'
                y1='2192.167'
                x2='2801.333'
                y2='2181.834'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2449.196'
                y1='2420.08'
                x2='2449.196'
                y2='2442.685'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2428.969,2439.816l-0.969,19.292c24.005,11.616,94.396,38.03,94.396,38.03'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2407.399'
                y1='2418.247'
                x2='2323.537'
                y2='2418.247'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2346.397,2398.15 2277.616,2319.608 2337.813,2305.033 2387.655,2361.878'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2418.139,2428.986 2449.064,2398.06 2449.064,2376.582'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2873.346'
                y1='2171.435'
                x2='2918.308'
                y2='2126.458'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2891.25'
                y1='2171.992'
                x2='2904.672'
                y2='2167.891'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2863.679'
                y1='2059.559'
                x2='2874.931'
                y2='2012.86'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2948.889,2121.71l13.004-13.004l7.864-7.864c0,0-33.588-35.342-67.049-62.021l-58.477-54.653h-202.106'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2445.747'
                y1='2321.077'
                x2='2466.691'
                y2='2300.133'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2480.776,2284.966 2497.204,2301.394 2497.204,2313.45'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2509.375,2232.062 2528.188,2251.312 2550.688,2251.312 2559.625,2242.625'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2545.234'
                y1='2229.547'
                x2='2526.206'
                y2='2248.575'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2844.011,2175.523c0,0-13.42-14.447-18.165-17.71c-4.242-2.918-17.471-11.188-38.2,10.335'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2908.132'
                y1='2136.78'
                x2='2920.164'
                y2='2148.812'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2929.888,2137.636 2908.949,2116.696 2926.27,2099.375'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2781.028'
                y1='2140.812'
                x2='2812.021'
                y2='2171.805'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2660.798,2193.503 2676.954,2152.306 2663.688,2147.458'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2519.032,2256.431 2502.493,2272.97 2485.097,2255.574'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2571.12,2230.493c0,0-15.956-17.051-23.12-16.148c-6.771,0.854-11.237,5.418-11.237,5.418'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2431.735'
                y1='2385.892'
                x2='2360.34'
                y2='2385.892'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2847.952'
                y1='2077.986'
                x2='2846.864'
                y2='2057.99'
              />

              <rect
                x='2700.914'
                y='2102.169'
                transform='matrix(0.7071 -0.7071 0.7071 0.7071 -701.47 2540.1877)'
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                width='29.383'
                height='29.383'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2715.605'
                y1='2137.637'
                x2='2715.605'
                y2='2147.468'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2715.605'
                y1='2086.774'
                x2='2715.605'
                y2='2096.605'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2695.09'
                y1='2117.121'
                x2='2685.259'
                y2='2117.121'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2745.952'
                y1='2117.121'
                x2='2736.121'
                y2='2117.121'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2814.348'
                y1='2104.509'
                x2='2878.94'
                y2='2140.594'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2625.109'
                y1='2185.797'
                x2='2646.242'
                y2='2206.93'
              />
              <g>
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2721.587'
                  y1='2806.977'
                  x2='2728.933'
                  y2='3041.375'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2736.814'
                  y1='2799'
                  x2='2743.729'
                  y2='3041.125'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2726.02'
                  y1='2929.475'
                  x2='2741.209'
                  y2='2928.756'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2728.532'
                  y1='3014.764'
                  x2='2742.921'
                  y2='3012.82'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2323.169,3100.241c42.059,20.762,54.202,27.801,63.829,38.192c5.18,5.591,8.725,12.584,10.293,16.594     c1.856,4.75-0.915,8.961-2.967,16.18c-1.156,4.068-4.235,17.731-2.949,23.479c2.28,10.189,21.368,11.165,31.466,13.806'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2305.087,3167.526c25.908,24.575,51.278,32.745,62.895,38.687c5.214,2.666,13.928,6.725,31.354,14.842     c17.195,8.008,20.732,9.439,24.758,10.77c13.94,4.607,25.379,5.234,37.215,5.664c29.073,1.055,55.637,0.746,75.097,0.201'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2913.011,3240.826 2797.982,3240.029 2613.153,3237.688 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2546.352,3395.645c-0.012-17.934,0.02-35.867,0.097-53.799'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2630.287,3259.506c4.013-6.307,6.351-13.787,6.351-21.818c0-7.678-2.132-14.855-5.821-20.982     c-3.259-5.4-7.736-9.977-13.055-13.359c-6.307-4.018-13.787-6.354-21.819-6.352c-7.678,0.002-14.854,2.129-20.983,5.824     c-5.396,3.254-9.973,7.734-13.357,13.047c-4.015,6.311-6.353,13.791-6.353,21.824c0,7.676,2.133,14.852,5.822,20.982     c3.262,5.398,7.736,9.975,13.055,13.357c6.303,4.018,13.786,6.352,21.818,6.35c7.679,0,14.854-2.129,20.979-5.822     C2622.328,3269.299,2626.903,3264.82,2630.287,3259.506z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2771.68,3091.155c4.013-6.307,6.351-13.787,6.351-21.818c0-7.676-2.132-14.855-5.821-20.982     c-3.259-5.4-7.736-9.977-13.055-13.357c-6.307-4.018-13.787-6.355-21.819-6.354c-7.678,0.004-14.854,2.131-20.983,5.824     c-5.396,3.254-9.973,7.736-13.357,13.047c-4.015,6.311-6.353,13.791-6.353,21.824c0,7.676,2.133,14.854,5.822,20.982     c3.262,5.398,7.736,9.975,13.055,13.357c6.303,4.02,13.786,6.352,21.818,6.352c7.679,0,14.854-2.129,20.979-5.822     C2763.721,3100.95,2768.296,3096.469,2771.68,3091.155z'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2595.942'
                  y1='3178.15'
                  x2='2595.942'
                  y2='3196.996'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2555.249'
                  y1='3237.688'
                  x2='2536.406'
                  y2='3237.688'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2700.093,3204.455'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2670.678,3204.455'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2670.677,3175.039'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2700.092,3175.039'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2670.677'
                  y1='3204.455'
                  x2='2662.627'
                  y2='3212.504'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2670.676'
                  y1='3175.039'
                  x2='2662.741'
                  y2='3167.104'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2708.03'
                  y1='3212.393'
                  x2='2700.093'
                  y2='3204.455'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2662.627'
                  y1='3212.504'
                  x2='2670.677'
                  y2='3204.455'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2700.092'
                  y1='3204.455'
                  x2='2708.03'
                  y2='3212.393'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2662.741'
                  y1='3167.104'
                  x2='2670.677'
                  y2='3175.039'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2700.091'
                  y1='3175.039'
                  x2='2707.921'
                  y2='3167.211'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2707.921'
                  y1='3167.211'
                  x2='2700.091'
                  y2='3175.039'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2445.327,3098.766c10.951,7.566,41.652,30.799,58.992,44.879c5.25,4.264,10.083,8.367,15.581,13.625     c4.873,4.66,9.402,9.686,18.261,19.545c11.078,12.326,15.81,18.02,27.584,30.777c1.396,1.512,2.543,2.746,3.282,3.541'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2570.905,3306.938c-0.607-0.031-1.492-0.006-2.508,0.275c-0.863,0.238-1.542,0.582-2.019,0.873     c-0.886,0.521-1.872,1.195-2.876,2.068c0,0-1.093,0.949-2.046,2.072l-42.79,86.17'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2378.914,3402.701c25.099-31.482,124.757-154.781,126.821-158c0.994-1.551,1.618-3.168,1.618-3.168     c0.395-1.201,0.546-2.346,0.572-3.363'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2867.963,3301.588c-152.847-0.047-236.768-1.27-243.037-1.404c-3.056-0.064-6.474-0.645-9.733-2.551     c-2.004-1.172-3.269-2.447-3.969-3.162c-5.347-5.461-7.163-13.445-7.75-16.631'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2358.791,3337.985c-6.853-11.889-55.948-101.737-62.801-113.626'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2409.865,3307.719c0,0-17.041-34.362-33.828-58.858l-68.322-3.022'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2346.175,3315.289c16.535-11.309,33.069-22.615,49.604-33.924'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2596.033,3132.5c1.556-26.256,7.367-55.209,26.393-63.785c6.487-2.924,10.732-1.777,18.901-3.949     c12.913-3.432,29.766-14.475,45.937-45.307'
                />
                <polygon
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2217.272,3276.527 2238.385,3327.984 2289.453,3307.006 2268.142,3255.477 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2508.125,3238.189c-0.073-4.906,0.24-12.387,2.425-21.098c4.151-16.545,13.389-31.629,25.5-43.121'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2510.679,3022.652c22.756,14.988,73.658,54.959,93.432,68.265'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2475.268,3062.711 2526.506,3101.167 2569.632,3066.167 '
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2602.377'
                  y1='3019.75'
                  x2='2602.357'
                  y2='3032.905'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2622.589'
                  y1='3019.348'
                  x2='2622.627'
                  y2='2966'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2586.418,3033.952c-3.654,0.008-7.211,3.508-8.301,6.832c-0.427,1.303-0.394,2.561-0.391,3.902     c0.004,1.488,0.007,2.973,0.011,4.459c0.008,3.188,1.657,5.82,4.044,7.832c1.785,1.504,3.889,1.809,6.099,1.805     c10.852-0.027,21.702-0.053,32.553-0.08c1.919-0.006,3.599-0.059,5.374-1.043c2.783-1.545,5.032-4.965,5.167-8.162     c0.105-2.537,0.276-5.031-0.079-7.438c-0.532-3.604-3.797-7.191-7.352-8.027c-1.278-0.301-2.696-0.166-3.999-0.162     c-2.522,0.006-5.047,0.014-7.569,0.02c-6.308,0.016-12.613,0.029-18.92,0.045     C2590.842,3033.94,2588.63,3033.946,2586.418,3033.952z'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2776.66'
                  y1='2907.452'
                  x2='2740.931'
                  y2='2909.152'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2595.961'
                  y1='3178.918'
                  x2='2596.04'
                  y2='3132.092'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2626.369,3187.127c9.539-19.312,18.995-39.082,28.336-59.304c3.62-7.838,21.718-46.544,25.211-54.313'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2587.105'
                  y1='3326.39'
                  x2='2587.105'
                  y2='3382.083'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2595.996'
                  y1='3326.039'
                  x2='2571.248'
                  y2='3326.039'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2571.587'
                  y1='3356.883'
                  x2='2546.393'
                  y2='3356.883'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2595.943,3278.379 2595.943,3382.018 2570.216,3382.018 '
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2710.292'
                  y1='3226.061'
                  x2='2710.292'
                  y2='3299.473'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2710.292'
                  y1='3099.729'
                  x2='2710.292'
                  y2='3159.314'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2546.738,3237.68c3.417,0.018,21.852,0,21.852,0'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2595.921'
                  y1='3180.932'
                  x2='2595.921'
                  y2='3215.406'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2526.506,3101.167'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2717.892,3459.734c3.388-3.311,4.429-4.883,4.539-5.643c0.019-0.135,0.05-0.553,0.269-1.068     c0.333-0.785,0.85-1.098,1.227-2.49c0.669-2.477-0.4-3.562,0.584-6.018c0.369-0.922,0.529-0.791,0.889-1.615     c1.412-3.24-1.264-5.436-0.631-8.918c0.062-0.344,1.21-2.027,3.506-5.393c2.081-3.055,3.028-4.277,4.661-4.695     c0.876-0.223,1.63-0.137,2.499,0.035c2.678,0.531,4.094,2.631,8.606,3.436c5.19,0.924,6.947-1.373,11.266,0.357     c2.818,1.129,4.596,3.523,5.719,5.625'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2628.848,3490.736c8.724,1.044,10.037-9.953,10.037-9.953c0.621-4.454,2.313-7.033,6.24-7.033     c9.25,0,16.706-2.006,21.8-8.891c8.65-11.691-10.075-39.38-15.319-51.135c-4.352-9.756-5.345-17.094-5.345-20.018l-0.104-7.145     c-0.128-2.725,0.103-5.105,0.054-6.59c-0.125-3.754-0.002-8.275,0.05-11.277c0.02-1.096,0.024-3.41,0.034-8.039     c0.009-4.104-0.01-9.939-0.135-17.258'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2742.124,3363.746c4.095,2.412,6.036,2.236,7.049,1.562c1.699-1.131,0.6-4.057,2.697-6.336     c0.748-0.816,1.259-0.844,4.719-2.059c4.574-1.604,2.881-1.256,9.64-3.654c1.949-0.691,3.664-1.301,5.934-2.041     c0,0,15.031-5.53,18.491-7.256c0,0,5.17-2.075,6.407-8.352s1.292-15.396,2.903-23.275c2.358-11.531,1.848-19.666,3.076-25.023     c1.588-6.926,2.532-11.599,3.916-23.812c1.421-12.54,2.14-19.141,2.098-27.738c-0.054-11.256-1.385-16.845-1.869-27.854     c-0.371-8.428-0.85-17.29-1.233-48.283c0,0-0.049-24.155,0-39.639c0.053-16.537-0.033-28.764-0.033-33.833     c0-9.416-2.292-49.439-40.116-45.885'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2697.734,3350.607c-0.025,3.777,3.438,7.484,6.75,8.643c1.299,0.453,2.557,0.432,3.899,0.441l4.459,0.031     c3.188,0.023,5.835-1.656,7.871-4.102c1.521-1.83,1.848-4.002,1.863-6.283c0.079-11.211,0.158-22.422,0.238-33.633     c0.015-1.982-0.023-3.719-0.991-5.562c-1.518-2.891-4.915-5.248-8.108-5.416c-2.537-0.135-5.03-0.336-7.439,0.01     c-3.608,0.516-7.229,3.854-8.1,7.518c-0.313,1.318-0.191,2.783-0.2,4.129c-0.018,2.607-0.037,5.215-0.055,7.822     c-0.046,6.516-0.093,13.031-0.14,19.547C2697.766,3346.037,2697.75,3348.322,2697.734,3350.607z'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2757.203,3447.951c1.141,1.002,9.649,7.488,9.623,14.014c-0.006,1.953,0.123,5.553,0.135,8.805     c0.002,0.629,1.041,2.959,0.262,4.387c-0.491,0.902-0.797,0.902-1.079,1.568c-0.666,1.568,0.479,7.867,0.907,9.584     c0.424,1.695-0.315,2.078-0.907,5.393c-0.27,1.508-0.332,3.004-0.455,6c-0.095,2.32-0.145,5.336,0,8.898'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2710.071,3359.703c-0.015,6.41-0.008,11.049,0.001,14.201c0.004,1.348,0.014,4.633,0,9.068     c-0.006,1.795-0.015,3.277-0.021,4.277c9.562,8.916,10.604,34.084,9.627,37.193c-0.256,0.814-2.135,2.805-5.832,6.842     c-3.969,4.338-6.82,6.809-8.764,10.787c-1.451,2.971-2.473,4.699-4.516,8.156c-0.301,0.508-1.097,1.855-2.63,3.035     c-0.818,0.629-1.998,1.535-3.438,1.617c-1.764,0.1-2.349-1.129-4.382-2.156c-4.865-2.461-9.273,0.652-12.001-2.598     c-0.116-0.141-0.187-0.238-0.302-0.402c-2.542-3.594-1.62-7.945-1.518-8.395c0.048-0.213,0.099-0.363,0.134-0.484     c0.9-3.008,2.698-4.875,3.371-5.582c2.723-2.859,5.202-5.939,7.889-8.832c2.924-3.148,1.961-2.299,6.943-7.752     c8.688-9.51,10.812-30.969,13.112-31.227c0.622-0.068,3.708-0.324,6.068-0.504c1.423-0.109,2.387-0.178,2.729-0.203     c7.009-0.51,10.514-0.766,11.655-1.537c5.206-3.521,0.243-15.828,5.637-19.801c1.737-1.279,3.859-1.74,3.859-1.74     c1.714,0.068,3.428,0.139,5.142,0.207'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2738.455,3452.51c2.531-5.498,2.572-7.904,1.954-9.021c-0.05-0.092-0.142-0.227-0.158-0.432     c-0.151-1.926,6.815-3.807,7.604-5.145c3.106-5.268,6.58-11.215,6.25-10.582'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2722.604,3345.488c6.107,0.668,9.352-0.635,11.198-2.021c0.834-0.627,2.466-2.086,4.314-1.754     c1.116,0.201,1.922,0.984,2.427,1.617c0.367,0.355,0.927,0.943,1.482,1.754c3.875,5.641-0.547,10.236,0.271,21.707     c0.051,0.711,0.572,7.766-0.951,10.217c-0.1,0.16-0.843,1.354-1.785,3.039c-0.233,0.416-0.486,0.889-0.771,1.457     c-0.808,1.602-2.353,6.557-1.682,9.539c0.672,2.984-0.164,29.232-0.164,29.232c-0.226,1.293-0.45,2.588-0.677,3.883'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2683.287,3451.842c-0.597,2.764-1.711,4.49-2.653,5.572c-1.63,1.871-3.021,2.135-3.729,4.141     c-0.632,1.785-0.33,3.85-0.315,3.949c0.213,1.377,0.67,1.76,0.548,2.752c-0.195,1.596-1.375,2.641-1.84,3.088     c-1.388,1.338-2.428,3.758-4.169,8.723c-1.292,3.684-0.64,2.787-2.831,10.719c-1.071,3.875-1.452,4.904-0.809,5.865     c1.399,2.092,5.361,0.625,8.695,1.414c3.547,0.842,10.722,7.139,11.495,20.506'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2712.108,3460.52 2706.255,3459.413 2695.322,3454.736 '
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2675.576'
                  y1='3443.551'
                  x2='2667.415'
                  y2='3446.117'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2435.888,2938.752c30.254,26.916,67.602,53.793,97.857,80.708h5.994h176.149'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2674.982'
                  y1='3380.293'
                  x2='2709.161'
                  y2='3380.293'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2674.982'
                  y1='3357.917'
                  x2='2674.982'
                  y2='3380.295'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2725.341,3239.381 2725.341,3277.6 2725.341,3284.328 '
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2771.194,3240.029 2771.194,3277.6 2771.194,3284.328 '
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2725.423,3284.416 2732.973,3284.416 2771.194,3284.416 2803.709,3284.416 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2466.017,3220.092c1.646-4.441,1.917-6.309,1.69-7.043c-0.04-0.129-0.19-0.521-0.214-1.08     c-0.035-0.854,0.299-1.355,0.044-2.775c-0.454-2.525-1.884-3.051-2.043-5.689c-0.061-0.992,0.141-0.941,0.112-1.842     c-0.109-3.531-3.467-4.373-4.382-7.791c-0.09-0.338,0.228-2.35,0.862-6.375c0.577-3.65,0.909-5.16,2.207-6.236     c0.697-0.578,1.416-0.82,2.275-1.037c2.648-0.664,4.825,0.631,9.249-0.572c5.086-1.385,5.694-4.211,10.336-4.492     c3.032-0.186,5.662,1.221,7.576,2.641'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2406.467,3224.359c-1.719,16.416-1.718,49.541,19.891,76.818c6.543,8.26,13.664,14.324,20.004,18.736'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2436.493,3175.994c12.454,3.973,15.607,8.199,16.053,11.428c0.116,0.848-0.731,3.449-2.347,8.68     c-1.735,5.617-3.256,9.07-3.312,13.498c-0.042,3.307-0.226,5.305-0.596,9.305c-0.055,0.586-0.198,2.145-1.08,3.867     c-0.471,0.918-1.149,2.242-2.417,2.932c-1.552,0.844-2.605-0.016-4.884-0.078c-5.45-0.145-8.104,4.555-11.959,2.783     c-0.165-0.076-0.271-0.137-0.445-0.234c-3.834-2.162-4.859-6.488-4.96-6.939c-0.047-0.213-0.065-0.371-0.086-0.496     c-0.471-3.104,0.356-5.561,0.662-6.488c1.238-3.748,2.163-7.592,3.355-11.354c1.298-4.098,0.79-2.918,2.964-9.979     c3.789-12.311,5.086-14.539,7.055-15.754c0.532-0.328,3.214-1.877,5.269-3.051c1.24-0.707,2.082-1.18,2.383-1.348     c6.117-3.459,9.178-5.188,9.88-6.373c3.2-5.41-6.547-14.412-3.369-20.308c1.023-1.9,2.744-3.225,2.744-3.225     c1.58-0.67,2.094-0.887,3.672-1.559'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2481.517,3204.77c-0.19-6.01-1.187-8.209-2.196-8.975c-0.081-0.061-0.219-0.146-0.32-0.326     c-0.955-1.684,4.201-6.211,4.302-7.742c0.4-6.035,0.822-12.834,0.812-12.127'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2434.196,3115.8c0.482,0.164,12.329,7.912,13.178,8.408c5.914,3.443,3.881,9.486,9.524,19.508     c0.349,0.621,3.836,6.777,3.507,9.642c-0.021,0.188-0.184,1.586-0.315,3.51c-0.033,0.479-0.059,1.014-0.075,1.648     c-0.044,1.793,0.678,6.932,2.56,9.342c1.883,2.412,3.798,8.416,3.798,8.416c0.349,1.268,0.699,2.535,1.048,3.801'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2431.358,3227.748c0.644,2.754,0.373,4.791-0.016,6.172c-0.673,2.389-1.819,3.223-1.603,5.338     c0.168,1.641,0.915,2,1.403,3.705c0.687,2.395-0.111,4.273-0.78,6.66c-1.222,4.363-1.574,8.836-1.348,11.934     c0.307,4.17,1.767,7.221,2.764,9.303c2.187,4.562,4.685,7.37,6.843,9.343'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2456.623'
                  y1='3224.865'
                  x2='2443.476'
                  y2='3225.221'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2724.419'
                  y1='2891.735'
                  x2='2739.609'
                  y2='2891.016'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='10'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2727.158'
                  y1='2967.024'
                  x2='2742.347'
                  y2='2966.307'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2595.717'
                  y1='3343.637'
                  x2='2697.518'
                  y2='3343.637'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2568.801,3211.027 2568.801,3264.672 2622.669,3264.672 2622.669,3210.492 2568.801,3210.492      '
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2675.918,3300.482 2675.918,3314.362 2595.735,3314.362 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2742.276,2947.062c0,0,30.942-5.058,48.725-11.521c15.184-5.518,25.594-17.629,25.594-17.629'
                />
                <rect
                  x='2709.61'
                  y='3041.125'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  width='54.017'
                  height='54.017'
                />
                <rect
                  x='2670.663'
                  y='3175.248'
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  width='29.003'
                  height='29.002'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2632.732,3172.951 2612.844,3163.191 2612.844,3180.361 '
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2641.453,3155.854 2621.02,3145.828 2640.034,3104.359 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2551.29,3081.612c0,0,17.631,17.573,9.525,31.881c-22.812,40.341-25.467,38.713-36.319,47.308'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2559.271'
                  y1='3093.727'
                  x2='2582.266'
                  y2='3075.103'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2631.1,3046.094c0,0,15.958-1.316,25.929,11.128'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2577.827,3043.368c0,0-18.327-4.304-25.946,7.923'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2661.61,3113.618c0,0-13.039-4.99-20.158-8.971s-11.233-20.538-8.176-38.426'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2575.353,3526.13l-4.858-130.957l0.161-9.492c0-66.68,0.271-86.306,0.271-86.306     c0.015-0.326,0.027-4.766-1.721-7.482c-1.21-1.881-2.723-2.854-3.664-3.479c-1.542-1.021-11.596-7.68-18.141-16.418     c-1.307-1.746-2.322-3.346-2.479-3.607c-5.397-8.971-8.518-19.469-8.518-30.699c0-11.754,3.419-22.695,9.294-31.928     c4.952-7.773,11.648-14.328,19.541-19.094c8.97-5.402,19.471-8.514,30.701-8.518c11.751-0.002,22.696,3.416,31.922,9.295     c7.938,5.059,12.371,10.781,19.101,19.543c1.728,2.25,3.104,4.945,6.198,8.76c2.423,2.986,4.777,5.904,8.37,8.795     c6.187,4.98,12.308,6.898,13.956,7.385c1.58,0.463,8.727,2.457,18,1.01c9.056-1.412,15.257-5.342,17.494-6.877     c2.383-1.633,6.106-4.188,9.337-8.797c8.562-12.215,5.751-27.547,4.779-32.738c-0.185-0.988-0.354-1.754-0.464-2.252     c-4.045-18.154-16.784-21.383-28.922-41.662c-3.396-5.674-5.483-10.547-6.944-13.955c0,0-5.439-12.697-7.82-24.27     c-2.903-14.115-4.805-50.271,19.687-72.809c4.626-4.258,13.484-11.059,27.897-14.814'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2630.134,3520.824 2624.109,3393.171 2515.5,3398.396 2521.142,3535.822 '
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2510.766,3410.987 2515.5,3398.396 2421.111,3349.894 '
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2563.174'
                  y1='3106.312'
                  x2='2600.289'
                  y2='3088.289'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2526.506'
                  y1='3101.167'
                  x2='2548.544'
                  y2='3133.371'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2478.883,3124.006c0,0,11.227-14.792,22.172-9.149s16.927,12.411,33.876-1.38'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2596,3149.334c0,0-19.374-1.711-19.436,6.898c-0.103,14.232-0.188,25.213-0.188,25.213'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2598.336'
                  y1='3113.707'
                  x2='2631.762'
                  y2='3122'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2621.02'
                  y1='3119.296'
                  x2='2632.194'
                  y2='3083.162'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2557.727'
                  y1='3119.776'
                  x2='2596.794'
                  y2='3120.257'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2539.836'
                  y1='3120.646'
                  x2='2549.917'
                  y2='3102.33'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2622.962,3004.833 2667.296,3004.833 2667.296,3019.348 '
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2622.962'
                  y1='2989.917'
                  x2='2649.25'
                  y2='2989.917'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2660.828,3004.833c0,0-1.197-3.353-12.775-16.891c-11.375-13.3-16.944-18.692-25.426-22.775     c-10.127-0.5-54.218,0.076-54.218,0.076l-0.212,54.105'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2520.841,3008.11c0,0,28.903-41.708,47.568-42.867'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2710.292'
                  y1='3119.965'
                  x2='2790.25'
                  y2='3119.965'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2763.627,3095.142 2790.25,3102.688 2790.25,3134.25 2764.938,3134.25 2764.938,3159.312      2778.377,3159.75 '
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2721.507,3215.426 2768.191,3215.426 2768.191,3239.381 '
                />
                <circle
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  cx='2685.808'
                  cy='3189.749'
                  r='31.217'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2768.191,3215.426 2787.5,3215.426 2787.5,3193.396 2787.583,3159.75 2794.333,3159.75 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2739.875,3215.441c0,0-1.688-25.612,10.688-35.183c8.772-6.784,15.104-6.759,36.983-5.236'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2577.969,3152.791c0,0-15.969-6.953-18.969-4.732s-5.309,10.578-9.783,10.162s-13.167-7.379-13.167-7.379  '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2550.074,3158.236c0,0-2.562,6.014,3.592,13.514s12.086,14.614,12.086,14.614'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2787.5,3193.396c0,0-20.562-7.544-26.549,3.167c-2.322,4.154-10.383,10-14.816,8     s-5.013-4.938-5.013-4.938'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2758.12,3200.737c0,0,7.008-0.675,5.552,14.138'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2763.043,3174.712c0,0-8.355,9.101,0.905,17.632'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2725.341'
                  y1='3254.167'
                  x2='2771.217'
                  y2='3254.167'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2636.235,3098.446 2645.511,3085.573 2658.417,3083.5 '
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2519.966'
                  y1='3193.396'
                  x2='2540.219'
                  y2='3215.668'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2631.702,3257.104c0,0,1.097,6.396,9.198,6.396s69.392,0,69.392,0'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2647.77,3300.295c0,0,0.329-15.879,3.613-15.879s41.83,0.679,41.83-1.868s0-19.048,0-19.048'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2622.669,3264.672 2632.468,3283.25 2651.383,3284.416 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2632.468,3283.25c0,0-10.083,7.764-8.734,16.882'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2435.815,3357.449 2513.234,3259.721 2502.691,3249.258 '
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2564.821,3308.572 2545.528,3299.783 2517.561,3354.155 2535.701,3363.49 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2396.896,3164.57c0,0,22.853-1.881,30.846,0.291c2.608,0.709,6.579,10.552,4.801,14.338'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2646.298'
                  y1='3357.917'
                  x2='2701.995'
                  y2='3357.917'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2743.641,3317.133c3.842-4.11,31.359-1.625,32.734,0.871s3.125,12.832,0,15.227     s-23.507,7.973-25.253,8.434s-8.875-3.254-10.311-6.896S2738.96,3322.141,2743.641,3317.133z'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2738.665'
                  y1='3341.871'
                  x2='2743.296'
                  y2='3337.91'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2513.234,3259.721c0,0,3.718,14.812,11.555,24.483s20.74,15.579,20.74,15.579'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2452.811,3336.365c0,0,23.6-1.636,41.978-8.334s37.349-36.153,37.349-36.153'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2507.457'
                  y1='3320.611'
                  x2='2491.604'
                  y2='3287.025'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2474.627'
                  y1='3308.455'
                  x2='2484.458'
                  y2='3331.192'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2492.029,3328.838c0,0,4.093,14.14,25.532,25.317'
                />
                <polyline
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2646.617,3368.177 2625.219,3368.177 2625.219,3372.707 2595.717,3372.707 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2634.072,3343.398c0,0-3.197-15.508,6.303-16.266s18.1-2.658,17.55-12.771'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2764.938,3159.312c0,0-17.125,0.438-27.893,12.062'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2675.918'
                  y1='3314.362'
                  x2='2675.918'
                  y2='3328.881'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2468.306'
                  y1='3291.399'
                  x2='2456.479'
                  y2='3281.538'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2443.058'
                  y1='3298.5'
                  x2='2477.375'
                  y2='3254.884'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2677.737,3468.418c0,0,8.718,2.166,9.782,5.166s-1.713,15.833,3.634,16.25s19.568,0.417,19.568,0.417'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2376.038,3248.86c0,0,2.876-14.285-2.038-14.765c-5.345-0.521-31.25-1.187-31.25-1.187'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2450.456,3364.766c0,0,6.754-21.43,28.086-12.66s25.783,16.414,24.933,21.904s-5.163,15.555-5.163,15.555  '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2356.671,3248.254c0,0-7.048,31.586,7.724,54.5'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2381.841'
                  y1='3290.896'
                  x2='2402.078'
                  y2='3312.651'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2388.724,3321.29c0,0,8.368,13.428,21.572,6.652s21.531-0.913,25.073,4.072'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2624.109,3393.171c0,0,15.153-1.043,22.188-8.606'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2675.576,3379.938c0,0-6.532,5.887-5.616,11.725s10.854,42.517,10.854,42.517'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2291.793'
                  y1='3310.833'
                  x2='2332.134'
                  y2='3290.107'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2432.492'
                  y1='3432.823'
                  x2='2441.625'
                  y2='3437.375'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2373.409'
                  y1='3399.437'
                  x2='2383.658'
                  y2='3405.965'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2325.636'
                  y1='3359.501'
                  x2='2331.623'
                  y2='3365.146'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2289.453'
                  y1='3307.006'
                  x2='2294.164'
                  y2='3314.71'
                />
                <polygon
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2511.665,3408.45 2476.062,3508.125 2423.207,3489.627 2459.188,3389.601 '
                />
                <polygon
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2432.492,3432.61 2392.868,3502.653 2343.877,3475.529 2383.658,3405.965 '
                />
                <polygon
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  points='2373.705,3399.834 2322.913,3462.94 2279.659,3427.375 2331.623,3365.146 '
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2325.733,3359.838l-31.569-45.128L2227,3360.172l31.933,46l91.247-63.188l18.811-10.919     c54.576-26.928,75.679-59.439,75.707-59.492c4.062-6.092,2.154-10.824,3.937-21.062c0.636-3.652,0.98-5.52,2.292-7.549     c1.357-2.102,2.517-2.564,2.967-4.584c0.572-2.57-0.894-3.654,0-5.529c0.87-1.828,2.625-1.566,3.511-3.27     c0.497-0.955,0.777-2.639-0.78-5.713c2.354-0.807,5.784-2.227,9.395-4.773c4.912-3.467,5.16-8.564,9.191-9.287     c2.619-0.469,4.539,1.049,5.732-0.156c1.125-1.137-0.33-2.74,0.182-4.855c1.381-5.711,15.476-8.441,15.728-11.02     c0.047-0.484-0.004-1.008-0.004-1.008c-0.173-1.223-1.02-1.988-1.982-7.449c-0.763-4.33-1.257-7.135-0.919-10.791     c0.592-6.377,1.59-6.25,1.208-9.143c-1.122-8.477-13.104-8.617-16.261-19.046c-0.371-1.229-2.495-5.59-1.542-10.01     c0.548-2.539,2.391-5.685,6.485-9.568'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2210.923,4364.222c45.211-28.343,66.943-42.738,84.661-53.638'
                />
                <path
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  d='M2084.167,3465.802l-25.871-9.777l-16.796-20.246l-0.856-20.777l32.794-32.376l-5-7.511l29.994-28.113     c0,0,23.341-25.252,36.697-48.358c10.872-18.809,26.062-21.864,26.062-21.864s123.774-33.313,204.6-95.825     c53.238-41.175,79.536-82.187,79.536-82.187c8.28-9.617,12.89-15.318,15.61-18.818c0.674-0.865,4.097-5.281,8.19-10.213     c1.457-1.754,2.872-3.422,3.187-3.791c1.815-2.141,3.284-3.871,5.105-5.916c1.354-1.518,3.573-3.896,6.122-6.633     c3.112-3.342,3.294-3.448,4.886-5.331c0.998-1.178,2.112-2.554,2.646-3.241c0.312-0.402,0.548-0.711,0.614-0.797     c0.008-0.012,0.017-0.021,0.018-0.023c0.204-0.27,9.113-11.068,29.151-35.332'
                />
                <line
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2261.782'
                  y1='3240.408'
                  x2='2268.142'
                  y2='3255.477'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2522.99'
                  y1='3031.174'
                  x2='2459.566'
                  y2='3108.669'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2391.426'
                  y1='3184.263'
                  x2='2365.791'
                  y2='3180.953'
                />
                <line
                  fill='none'
                  stroke='#252526'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeMiterlimit='10'
                  x1='2781.417'
                  y1='3256.168'
                  x2='2781.417'
                  y2='3284.302'
                />
              </g>
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2724.587,2903.495h-25.253c0,0-4.167-14.355-20.333-25.206c-12.396-8.32-13.75-7.164-28.25-6.289'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2289.453'
                y1='3307.006'
                x2='2294.164'
                y2='3314.71'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2721.492,2577.75c0,0,38.823,11.18,54.073,6.762c15.25-4.418,31-1.168,41.5,7.082'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2787.564,2582.573c0,0,5.723-10.134-1.742-20.564c-8.882-12.409-9.771-19.12-10.757-24.039'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2875,2853.856c0,0-18.25,3.644-28.546,16.144c-10.058,12.211-18.069,29.16-16.902,45.743'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2901.959,2675.187c0,0-22.795-31.868-40.462-49.868'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2914.527,2681.565c0,0,27.612,27.345,32.477,34.717c2.798,4.24,3.64,16.319,4.524,24.319'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2667.509,2774.06c0,0-2.269-14.449,2.422-23.115c4.691-8.667,5.615-20-2.422-40'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2655.19,2793.777c0,0,5.27,31.673-1.5,44.25c-6.721,12.487-13.593,15.337-13.593,15.337'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2831.781'
                y1='2892.917'
                x2='2786'
                y2='2892.917'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2885.508'
                y1='2653.439'
                x2='2871.269'
                y2='2664.577'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2283.042'
                y1='3050.955'
                x2='2353.534'
                y2='3035.648'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2251.969,3018.75c0,0-1.469,21.25-23.469,29.75s-47.5,11.25-47.5,11.25'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2138.5,3010c0,0-24.989-3.751-34.124,29.625c-8.407,30.717-48.568,20.125-48.568,20.125'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2089.333,2443.212 1909.042,2383.939 1930.764,2304.462 2090.634,2350.936'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2202.282,2318.916 2120.374,2390.93 2089.333,2443.212'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2323.875'
                y1='2686'
                x2='2323.875'
                y2='2735.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2308.79'
                y1='2686'
                x2='2308.79'
                y2='2735.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2253.481,3123.568c0,0-2.229,19.09,3.622,33.099c6.374,15.259,15.899,29.039,15.899,29.039'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2258.226,3158.604c0,0,25.46-7.876,26.034-8.573'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2270.9,3139.604c0,0,14.534,1.757,17.733,22.826'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2210.79,3156.438c0,0,20.46-9.863,42.966-12.981'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2305.087,3046.168c0,0,0.999,16.086,10.037,24.832c10.083,9.757,4.917,13.48,0.021,26.74'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2228.5,3068.75c0,0,29.62,1.398,45.748,6.949'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2273.5,3041.779c0,0-14.188-0.716-16.397,9.74c-2.476,11.714-3.603,19.481-3.603,19.481'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2154.864,3025c0,0-1.17,26.525,2.816,38.929s7.261,22.791,7.261,22.791'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2138.206,3035c0,0-16.857-2.106-17.094,5.197s0.145,29.803,0.704,30.803s16.684,1.5,16.684,1.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2218.5'
                y1='2809'
                x2='2218.5'
                y2='2839'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2765.847,3796.728c0,0-11.113-2.719-19.053-4.586c-7.941-1.867-15.131-3.934-22.376-7.298    c-12.452-5.783-22.487-13.44-22.487-13.44l-9.023,10.501'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2756.845,3794.543l4.057-7.599c0,0-7.44-8.397-8.891-15.096c-1.449-6.699,1.608-14.99,6.096-20.422    c0,0,4.34-4.262,2.802-11.58c-1.423-6.768-2.344-10.369-2.344-10.369l-3.599-13.251l12.431-17.906l-9.921-31.688    c0,0-38.034,9.933-64.987,11.01c-26.954,1.078-32.39,1.696-32.39,1.696l-20.802,23.155l69.592,61.352l-6.957,7.56'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2759.498'
                y1='3733.4'
                x2='2721.137'
                y2='3783.249'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2007.353,4696.654c0,0-2.015-0.821-9.62-3.773c-7.604-2.95-14.438-5.997-21.146-10.335    c-11.529-7.456-20.403-16.43-20.403-16.43L1938,4682.343'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2205.733,3973.911l-8.762-3.252c0,0-11.363,25.703-20.318,33.917c-8.954,8.214-34.169-0.509-44.937-4.498    c-10.768-3.99-23.487-13.567-23.487-13.567l-5.147-27.383l45.458-26.377c0,0,4.309-2.555,10.305-6.004l43.951-25.661l7.578,4.233    c0,0,0.798,11.413,1.438,19.544c0.642,8.131,0.864,15.61-0.132,23.537C2209.966,3962.022,2205.733,3973.911,2205.733,3973.911    l12.812,4.906'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3388.154,2908.302c142.437,156.219,183.759,287.847,196.062,362.148    c25.232,152.373-85.145,300.929-107.443,360.531s-24.91,83.47-67.299,140.438s-105.354,81.587-137.093,98.795    c-36.197,19.625-27.67,17.56-61.881,31.075c-16.246,6.418-26.265,8.681-47.164,18.739c-20.899,10.059-29.917,18.174-29.917,18.174    l8.568,12.641l-24.735,17.466c0,0-4.379-5.979-8.502-12.113c-9.466,5.437-30.72,23.43-30.72,23.43    s-106.379,73.521-137.046,151.521S2880,4230.5,2912,4293.25c17.283,33.892,9.555,92.321,12.25,111.912    c2.527,18.373,16.13,41.199,16.75,55.863c0.512,12.118,9.176,25.621,9.176,25.621'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2544.88,3997.199c0,0-14.698,38.487-17.668,46.689c-2.97,8.203-10.277,11.746-41.507,9.289    c0,0-2.07,16.5,6.262,33.357'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2951.506,3364.91l-172.594-0.797c-2.363-0.084-6.654,0.137-10.863,2.678    c-1.009,0.609-2.802,1.715-4.382,3.775c-2.751,3.588-2.846,7.631-3.034,8.9c-1.605,10.777,9.167,36.027,6.558,44.17    c-0.892,2.777-1.739,2.236-5,7.748c-1.869,3.162-2.621,5.908-3.782,10.148c-1.464,5.35-1.026,6.402-1.393,7.582    c0,0-0.178,0.494-0.428,0.912c-1.33,2.225-15.239-1.334-18.929,3.238c-1.366,1.693-0.737,3.766-2.24,4.312    c-1.594,0.58-2.68-1.613-5.249-2.311c-3.953-1.068-6.354,3.434-12.279,4.467c-4.351,0.76-3.242,0.785-5.053,0.785    c-1.3,0-1.371,2.562-1.612,5.014c-0.308,3.124-0.027,3.721-0.851,5.799c-1.527,3.865-3.289,4.504-3.017,6.258    c0.606,3.93,3.697,4.164,3.56,7.529c-0.057,1.379,0.139,3.768-0.375,6.154c-0.183,0.846-0.399,1.717-0.53,3.045    c-0.107,1.084-0.103,1.824-0.102,3.035c0.001,1.129-0.004,2.779-0.033,4.854c0.141,1.018,0.229,2.617-0.27,4.449    c-0.939,3.449-3.307,5.459-4.314,6.203c-4.912,3.619-17.14,5.868-18.375,5.808c-21.478-1.049-68.083,3.155-94.084,4.798    c-12.888,0.814-6.146,1.707-53.65,7.338c-11.326,1.342-49.612,14.065-67.435,45.053c-13.441,23.368-20.999,25.917-28.25,56.782    c-26.391,75.394-8.792,171.611,13.464,259.681c0,0,3,12.866,15.637,35.521c8.066,14.46,18.805,31.716,18.805,31.716    s7.174,11.83,23.224,32.548c11.679,15.076,24.497,18.878,24.497,18.878'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2941,4487.5l41.278-3.409c0,0,3.914,37.167,6.523,40.24c6.224,7.331,37.28,8.451,37.28,8.451'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2779.167,4846.251c0,0-13.667-22.941-21.407-54.72c-7.628-31.319-36.26-57.031-43.534-87.212    s-31.858-84.547-32.058-111.628c-0.054-7.376,6.321-22.59,10.017-31.679c4-9.838,22.358-12.411,32.243-17.691    c29.171-15.585,35.9-31.071,70.222-52.862c25.408-16.132,30.606-24.525,32.998-30.959c2.479-6.667,5.507-19.59,5.06-28.171    c-0.194-3.721-0.988-9.419-6.052-15.308c-5.064-5.891-13.258-12.585-17.811-20.622c0,0-6.921-12.366-7.051-28.883    s-9.301-16.046-20.1-34.091c-10.248-17.125-23.957-29.075-57.875-27.887c-28.156,0.986-66.555-18.349-77.658-21.573    c-9.752-2.832-31.275-9.402-31.275-9.402'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2103.5,2735.5c0,0-66.973,0-83.28,0s-64.351-0.304-72.72-40.119c-8.998-42.806-9.54-49.535-21.318-79.603    c-4.581-11.694-22.638-50.591-57.674-62.361c-13.773-4.626-39.796-11.778-53.543-21.583c-11.911-8.496-13.312-25.664-1.465-36.333    c25.52-22.985,83.926-70.94,95.542-111.561'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2173.022,2668.412 2064,2629.375 2102.167,2520.414 2073.5,2497.667 2089.333,2443.212     2097,2446.5'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4463.847,3323.152c0,0,30.534-3.106,40.014-24.921c5.186-11.932,3.075-40.968,5.756-47.623    c3.224-8.001,6.268-11.361,6.268-11.361'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3163.46,3631.426c4.767-3.169,9.706,9.84,9.948,15.829c0.241,5.987-17.461,28.161-23.238,36.219    c-5.777,8.059-18.235,23.271-24.634,29.063c-6.399,5.794-11.376,3.383-19.579-0.144c-7.027-3.021-21.396-11.858-27.579-16.935    c-15.006-12.322-13.308-20.181-10.618-23.782c0,0,6.984-11.716,17.668-8.153c10.684,3.563,22.321,19.767,38.699,9.254    S3154.322,3637.501,3163.46,3631.426z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4840.861,4625.997c0,0,18.896-49.546,20.09-61.932c1.379-14.294-4.605-26.433-23.771-34.271    c0,0-25.372-9.018-34.354-23.336'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='4845.305,4613.771 4825.625,4606.596 4841.755,4531.871'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4860.783'
                y1='4565.366'
                x2='4835.027'
                y2='4565.968'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3529.328,4947.461 3545.014,4933.577 3610.915,4990.204'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3564.511'
                y1='4984.133'
                x2='3576.253'
                y2='4961.202'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5096.739,3311.382c4.903-16.764,7.669-19.461,6.787-42.638c-0.883-23.178-4.725-34.685-4.725-34.685    l-15.01,2.811l-6.144-29.651c0,0,7.229-1.628,14.5-2.961c-1.206-10.849-9.223-37.519-9.223-37.519'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5071.957,2801.392l-23.115,10.011c0,0-11.813-27.772-14.917-29.855c-8.59-5.769-43.338,5.461-43.338,5.461 '
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4756.639,5132.851c11.571-46.498-3.206-118.13-13.052-154.165c-4.533-16.592-7.88-42.888-5.974-57.44    c1.573-12.026,3.059-25.192,3.059-25.192'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4754.605,4896.66l-23.857-2.269c0,0,2.521-37.285,0.477-40.762c-4.874-8.289-31.113-23.924-31.113-23.924'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3504.885,4920.29c0,0,50.368,57.66,60.689,64.611c11.91,8.021,24.515,8.769,41.904,5.917    c17.39-2.853,25.173-7.43,44.901-21.537c19.727-14.108,35.454-19.984,50.038-21.64c12.051-1.369,25.188-3.099,25.188-3.099'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3730.371,4958.211l-3.545-23.701c0,0,44.142-6.307,77.615-23.388'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4319.525,3864.431l-11.501,21.026c0,0-21.886-11.289-37.264-16.525'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3252.179,4900.466c0,0-20.59,2.791-35.827-20.711c-11.648-17.967-16.253-37.574-16.299-44.747    c-0.055-8.628,1.228-12.02,1.228-12.02'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4100.576,4912.162c-38.725-23.494-107.968-27.281-107.968-27.281s-23.473-3.582-48.046-25.748'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3109.448,4752.674c13.176,14.574,30.523,28.753,30.523,28.753c11.337,10.471,37.67,28.104,53.11,36.666    c15.44,8.562,24.235,11.369,40.449,6.35c19.611-6.068,34.237-33.785,34.237-33.785'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4782.105,4324.881c8.669-15.291,15.747-25.281,24.925-28.594c9.177-3.314,17.961-5.404,23.803-10.399    c5.842-4.993,14.326-21.168,13.316-29.401c-1.176-9.582-3.994-23.519-5.342-29.217c-2.965-12.543-3.931-16.63-3.931-16.63    l-36.05,5.439l-9.859,64.365l9.411,21.368'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4659.411,3567.027c0,0,23.959,12.834,33.78,20.52c4.611,3.61,13.932,11.406,19.662,17.406    c5.73,5.995,6.423,11.104,1.666,17.139c-4.757,6.037-22.173,12.956-30.529,17.992'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4573.382,3908.844c41.07,20.652,75.902,23.222,75.902,23.222s33.466,4.644,74.898-17.196    c8.518-4.49,13.623-10.454,21.317-7.354c7.125,2.87,19.375,7.985,26.922,11.86c6.837,3.511,7.245,6.024,11.072,15    c9.506,22.292,19.043,21.608,30.417,30.617c7.199,5.702,19.672,27.258,19.672,27.258'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4313.468,3876.994c0,0,11.509,6.562,21.939,12.754c12.62,7.493,35.747,13.407,52.943,13    c23.091-0.547,38.7-17.198,77.771-3.858'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4854.038,3470.397c-0.737,4.432-3.729,16.206,3.129,20.326c6.86,4.117,26.134,4.748,33.662,3.382    c7.526-1.366,14.433-0.773,17.461-8.859c3.025-8.081,7.113-27.312,8.548-37.125c1.433-9.811,6.987-37.635,4.024-42.844    c-2.963-5.21-11.182-12.422-16.102-9.5c-4.921,2.924-12.938,12.957-17.994,19.863c-5.057,6.906-7.175,6.961-13.125,21.522    c-5.95,14.559-9.529,17.091-12.099,20.51C4858.975,3461.09,4855.187,3463.492,4854.038,3470.397z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4655.89,5147.991c-4.997,0-16.881,1.11-20.75,8.111c-7.375,13.344-3.321,19.8,14.75,27.57    c7.425,3.192,25.87,5.193,35.558,4.635c12.246-0.706,29.994-7.301,40.192-11.455c10.197-4.152,39.828-17.071,42.982-22.398    c3.156-5.326,5.03-16.15-0.732-18.68c-5.764-2.529-18.5-3.648-28.398-3.648c-9.645,0-10.833,10.741-27.664,15.865    c-10.078,3.068-16.854,4.902-28.904,3.36C4678.146,5150.74,4666.015,5147.991,4655.89,5147.991z'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2103.5'
                y1='2685.5'
                x2='2050'
                y2='2673.416'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2117.962,2647.927 2152.768,2547.534 2098.888,2529.774'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1944.13,2679.195c0,0,75.601-31.822,111.485-25.675'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1915.713,2593.788c0,0,78.764-20.409,91.4,6.448s23.651,53.285,23.651,53.285'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1995.323,2661.619c0,0,1.642,30.318,13.232,43.756c10.53,12.208,30.196,15.543,30.196,15.543'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2064,2676.578c0,0-6.75,34.422-6.75,58.922'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2020.468,2629.618c0,0,22.562-7.752,43.532-0.243c0,0-18.002,45.117-27.999,105.821'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='702.08,2834.183 727.34,2805.432 732.75,2781.812'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='694.836,2870.158 674.863,2851.572 695.252,2829.572 716.066,2847.861'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='662.062'
                y1='2882.636'
                x2='682.676'
                y2='2859.791'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='686.076'
                y1='2838.677'
                x2='627.156'
                y2='2784.071'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='472.059'
                y1='2406.348'
                x2='479.168'
                y2='2355.573'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2112.792,3726.282 2099.744,3705.028 2146.986,3678.065 2158.999,3697.945 2079.356,3746.523    '
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2079.369'
                y1='3746.543'
                x2='2091.604'
                y2='3766.753'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2453.839,3735.722c0,0,5.277,32.331,14.823,44.195s21.916,18.679,21.916,18.679    c19.067-24.137,22.606-34.992,18.696-42.79c-3.91-7.799-23.195-47.08-23.195-47.08l-23.638-12.541'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2430.663,3735.988c0,0,7.167-1.653,35.484-0.398c19.052,0.844,30.553-4.793,30.553-4.793'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2078.732,4292.395c0,0,26.655,4.266,36.619,6.216c9.966,1.951,31.463,8.756,31.463,8.756'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2075.587,4304.086 2082.964,4276.656 2055.016,4267.943 2046.426,4293.795'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2046.426,4293.795c0,0-10.953-2.6-17.931-3.054c-7.37-0.479-22.068,0.924-34.745,16.134    c-10.178,12.212-35.561,42.429-28.254,70.575c0,0,6.035,31.175,5.082,35.175s-21.828,26-21.828,26'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1587.372,4037.209c0,0-15.307,22.231-20.914,30.694c-26.077,39.361-53.974,42.163-53.974,42.163'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='1578.055,4029.477 1599.914,4047.616 1620.027,4026.351 1599.565,4007.259'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2017.748,4644.896c2.447,3.059,8.314,15.299,8.819,22.134c0.507,6.836-14.142,22.663-14.142,22.663    l-5.073,6.962'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1510.357'
                y1='4102.031'
                x2='1517.74'
                y2='4149.797'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1539.675'
                y1='4137.047'
                x2='1516.327'
                y2='4140.656'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1510.334'
                y1='4102.035'
                x2='1486.986'
                y2='4105.644'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3903.189,2020.47c-0.022-2.435-4.844-17.051-8.15-19.226c-3.306-2.175-28.128-3.793-31.391-3.699    c-3.262,0.094-10.827,1.005-15.406,6.783c-3.664,4.622-5.889,24.303-4.988,33s5.824,19.854,13.391,22.074    c7.566,2.219,12.981,2.012,17.721-1.872c4.738-3.884,13.272-10.963,15.845-14.767    C3892.783,2038.958,3903.222,2024.009,3903.189,2020.47z'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4376.957'
                y1='2199.97'
                x2='4407.314'
                y2='2243.713'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4352.131'
                y1='2219.899'
                x2='4368.639'
                y2='2241.67'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5033.848,1863.693c0.764,10.739-3.033,52.808-5.935,68.509'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='5060.652'
                y1='1910.535'
                x2='4989.301'
                y2='1904.678'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='5060.901'
                y1='1878.7'
                x2='5033.597'
                y2='1877.731'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4267.586,2096.872c-27.669,0.511-77.188-6.957-92.757-10.503'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5071.718,784.501c-16,19.909-50.494,24.284-65.726,30.789c-15.231,6.504-22.708,12.465-22.647,32.732    c0.059,20.268,3.092,43.146-2.496,74.146s-12.841,40.117-8.142,83.13l5.782,52.93'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4994.504'
                y1='459.038'
                x2='4994.05'
                y2='520.653'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5270.902,634.418c-13.558-8.67-16.583-38.839-12.644-54.926c3.938-16.087,3.656-25.645-12.592-37.761    c-16.248-12.115-47.005-12.282-60.685-10.856c0,0-31.524,1.079-47.311-4.68c-31.641-11.542-34.384-31.131-58.318-52.157    c-13.726-12.058-47.753-14.394-74.891-15.444c-14.567-0.564-31.669-0.119-58.056,5.736c-24.377,5.409-37.042,16.12-43.959,21.462'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4948.862,1300.194c33.433,37.468,79.162,27.924,79.418,46.233c0.308,21.843-15.014,11.596-31.431,15.474    c-23.421,5.531-37.787,18.311-46.459,31.706'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4195.128'
                y1='2119.973'
                x2='4201.438'
                y2='2067.104'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4226.926'
                y1='2121.533'
                x2='4229.019'
                y2='2094.292'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5019.553,1023.209c-6.55,1.724-43.921,5.94-79.634,8.083'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='5009.818'
                y1='1052.573'
                x2='5005.711'
                y2='1025.563'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3538.523,1520.542c0,0,5.838,2.077,25.442,1.793c19.605-0.283,37.238,27.014,37.238,27.014l30.692,46.864'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3653.847,1574.985l-27.619-41.219c0,0-14.033-22.343-18.347-29.164    c-4.312-6.821-18.408-27.665-37.628-13.109c-19.219,14.556-17.369,30.731-17.369,30.731'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3613.81'
                y1='1567.319'
                x2='3638.012'
                y2='1551.409'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3605.008'
                y1='1554.89'
                x2='3629.209'
                y2='1538.981'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1521.259,1630.292l8.623-33.083c0,0,8.367-30.864-4.337-45.798    c-25.962-30.522-62.602-13.537-62.602-13.537'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3315.327,720.208l-18.754-11.407c0,0-19.523-11.906-37.133-21.541    c-19.713-10.786-36.796-20.529-36.796-20.529'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3331.119,695.929l-17.87-11.438c0,0-21.816-14.84-28.501-19.36c-6.686-4.519-28.261-18.235-29.769-34.119'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3315.327'
                y1='720.208'
                x2='3331.119'
                y2='695.929'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3302.28'
                y1='712.352'
                x2='3318.072'
                y2='688.073'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3293.466,671.044c0,0,25.662-32.59,9.64-63.749'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3296.573,708.801c0,0-47.595,70.066-64.18,92.054'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3026.991,1216.196c-24.202,8.702-37.784,24.178-43.659,35.919'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3266.939,1219.129c8.894,6.507,26.065,15.374,26.065,15.374s23.157,10.704,24.082,19.042    c1.125,10.142-9.582,17.625-25.957,21.637c-16.375,4.012-20.066,3.588-34.233,3.414'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3054.754,1056.569c0,0,14.25-18.594,33.828-20.75c17.992-1.981,25.311,0.531,31.112,6.147    c3.499,3.388,38.105,38.116,64.376,60.874'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2361.816,663.302c0,0-11.362-20.487-5.525-39.299c5.365-17.287,10.589-22.995,18.054-26.072    c8.237-3.396,68.512-27.939,68.512-27.939'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2655.096,714.81c0,0,28.532,12.5,34.456,35.474c4.519,17.527,3.261,25.105-1.424,31.682    c-2.314,3.248-8.56,11.909-14.755,20.488c4.746,3.731,22.473,16.941,22.473,16.941'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1753.444,1731.688c0,0-20.008,46.469-33.389,60.924c-12.296,13.282-19.472,16.178-27.533,15.735    c-8.897-0.489-58.555-3.426-58.555-3.426'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3246.763,1699.659c-22.746-5.346-50.467-11.605-60.293-22.929c-10.344-11.921-21.391-42.905-21.391-42.905    l-20.639-60.487'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3113.376,1761.638c-16.043-15.824-48.998-17.948-59.262-23.037s-27.499-16.297-50-11.922    c0,0-15.779,3.189-28.781,2.914c-9.078-0.192-11.72-1.589-16.394-4.713c-7.09-4.739-33.156-29.816-48.298-46.21    c-5.479-5.932-13.12-8.128-31.112-6.147c-19.578,2.156-33.828,20.75-33.828,20.75s-15.251,21.453-31.084,26.62    c-11.112,3.626-25.842,4.483-25.842,4.483'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1504.018,949.791l7.439,44.65c0,0,19.48,1.961,30.53-12.143c9.097-11.611,12.031-20.585,12.031-20.585    S1526.519,945.416,1504.018,949.791z'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3273.397,380.527l-12.819-15.85c-7.101-7.906-10.792-12.644-20.038-16.959    c-7.603-3.548-28.791,10.658-28.791,10.658l-24.664,17.08'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1920.199,896.288c6.506-21.75,7.628-37.12,8.333-47.3c0.58-8.37-20.292-23.038-20.292-23.038    s-9.027-6.066-35.065-9.965c-22.126-3.314-31.506-31.527-37.917-66.873c0,0-5.624-47.504-6.29-53.129    c-0.808-6.824-53.565-42.415-53.565-42.415l-40.331-16.988'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1781.487,450.825c-40.417-9.5-41.91-36.078-42.139-39.416c-0.783-11.416,0.287-23.77,3.805-34    c3.563-10.358-23.215-33.199-23.215-33.199s-10.951-12.885-33.201-1.218'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='691.345'
                y1='2240.556'
                x2='666.492'
                y2='2299.941'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='674.269'
                y1='2282.33'
                x2='700.905'
                y2='2293.478'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1227.385,2382.173c0,0,9.145,5.977,8.421,7.849c-0.724,1.873-18.031,37.473-22.161,42.964    c-4.131,5.492-9.553,8.027-17.249,5.548c-7.695-2.479-2.728-6.167,0.507-12.377c3.235-6.21,22.168-35.812,24.14-39.579    C1223.015,2382.813,1224.481,2380.84,1227.385,2382.173z'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='828.902,2334.288 891.208,2334.288 920.735,2325.945'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M784.862,2010.86c0,0,46.804-17.567,50.431-17.757c3.628-0.19,6.677,2.061,9.5,6.797    c1.597,2.679,4.107,10.113,7.934,22.514c2.517,8.157,18.21,11.763,24.994,16.714c4.25,3.102,7.119,16.899,7.119,16.899'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M812.86,2000.547c0,0,10.891,30.138,11.906,33.09c1.016,2.952,3.257,6.232,12.438,2.946    c9.18-3.286,20.322-8.207,20.322-8.207'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='855.652'
                y1='2334.288'
                x2='855.652'
                y2='2301.372'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M306.795,3381.383c0,0,8.123-14.508,22.714-43.101'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M962.17,1835.464c15.456,3.739,17.206,15.266,25.289,25c8.256,9.942,23.013,15.02,29.549,15.002    c6.537-0.017,75.667,0.137,75.667,0.137'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M660.863,1563.087c46.711,13.8,99.386,71.503,101.276,107.774'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='1134.978,2101.381 1118.031,2091.841 1143.779,2041.595 1125.428,2012.429'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='1239.916,1412.222 1240.755,1396.946 1214.442,1346.993 1183.07,1335.357'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1226.586'
                y1='1371.208'
                x2='1143.267'
                y2='1415.179'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='320.81'
                y1='3356.792'
                x2='382.573'
                y2='3391.855'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1319.006'
                y1='3985.738'
                x2='1372.075'
                y2='3931.737'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M385.539,2901.107c7.527-6.327,36.066-29.878,39.301-31.729c3.236-1.848,23.055-3.837,30.76-2.777    c6.014,0.828,13.989,12.945,21.184,22.828'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1263.915,3984.045c26.422-23.424,69.264,10.895,69.264,10.895'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1090.216,4189.974c6.037-3.939,21.415-6.975,28.175-8.526c10.223-2.348,26.573-7.895,29.302-10.433    c2.726-2.54,25.127-31.991,31.009-39.871l9.355-13.034'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='310.621,1967.968 269.959,1963.802 266.04,2004.48'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='690.308,2534.739 635.081,2563.584 612.771,2584.65'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M777.425,3020.258c29.345,2.105,59.144,4.172,66.528,2.761c5.415-1.035,8.742-3.366,10.299-6.648    c2.803-5.908,11.608-41.618,11.608-41.618s3.592-12.743,9.983-14.75c10.451-3.281,17.349-5.125,22.75-9.371    c5.402-4.248,26.792-28.247,30.197-34.208c3.43-6.005,1.072-15.005,0.189-24.255s-0.106-14.458-5.371-16.417    c-2.643-0.983-13.513-4.287-21.439,11.042'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M860.617,2995.193c0,0-40.503,0.773-43.625,0.72c-3.122-0.054-7.409,0.329-7.456,10.079    c-0.048,9.751-0.304,16.049-0.304,16.049'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M834.505,4350.267c0,0,20.565-25.636,22.576-28.023c2.012-2.387,4.032-6.723-3.503-12.913    c-7.534-6.191-13.975-11.779-13.975-11.779'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='666.597'
                y1='2547.123'
                x2='682.784'
                y2='2578.92'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='271.636,2184.825 255.184,2244.92 256.445,2286.623'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='264.572'
                y1='2210.626'
                x2='280.688'
                y2='2215.075'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='791.579,1233.946 811.855,1175.031 813.574,1144.396'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='800.285'
                y1='1208.652'
                x2='775.227'
                y2='1200.029'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2158.686,2179.977 2123.906,2231.672 2114.346,2260.829'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2143.753'
                y1='2202.172'
                x2='2165.741'
                y2='2216.963'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M404.112,2644.334c-14.294,36.53-67.652,88.229-67.652,88.229'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M862.747,3539.334c0,0,24.123-64.785,26.374-70.922c2.252-6.136,10.891-14.2,23.614-11.93    c10.04,1.792,9.56,1.224,30.519-5.601l21.936-23.891'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M683.796,3943.817c18.226-30.614,46.704-89.818,88.813-104.323'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='886.163,2519.886 883.635,2534.974 904.245,2587.536 934.133,2602.581'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='894.862'
                y1='2562.125'
                x2='957.431'
                y2='2537.832'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='356.367'
                y1='2712.442'
                x2='406.937'
                y2='2762.309'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M934.517,628.905c0,0,3.852-3.689,12.09-9.058c8.238-5.369,39.419-25.29,42.854-26.735    c3.437-1.442,23.555-1.674,30.87,0.969c29.604,10.695,35.368,14.643,52.439,40.623'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='686.278'
                y1='1797.035'
                x2='643.325'
                y2='1749.087'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='655.888,1763.674 625.442,1790.948 312.956,1948.858 309.275,1984.795'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M298.209,1314.169c-4.947-35.283-12.632-118.571-11.135-156.571c1.497-38,17.27-55.465,28.331-75.333    c11.061-19.868,15.843-29.697,16.125-38.5c0.282-8.803-2.018-17.7-1.729-19.686c1.188-8.147,9.953-27.343,13.6-33.188    c3.647-5.843,8.915-8.203,17.443-6.922c9.394,1.411,35.728,15.046,63.728-6.29'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='668.602,579.747 627.191,626.298 613.799,653.905'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M434.708,1458.406c0,0-30.425,17.229-48.5,19.001c-3.615,0.354-14.934,0.524-20.342-0.541    c-7.377-1.452-39.426-17.238-39.426-17.238'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M404.535,1472.565c0,0-49.952-24.479-55.452-27.621c-3.613-2.064-13.626-5.125-18.351,4.532    c-6.312,12.9-11.396,24.384-11.396,24.384'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='650.824'
                y1='599.733'
                x2='670.622'
                y2='617.345'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1995.104,2412.309c0,0-22.242,69.925-11.825,134.44'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1897.514,2570.546c0,0,66.931-32.87,130.02-22.879c0,0,22.696,4.117,28.082-4.41    c7.385-11.694,17.885-45.59,17.885-45.59l-201.277-57.411'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2007.378,2545.995c0,0,4.623,20.144-7.182,46.649'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2007.434,2569.638c0,0,21.933,3.303,26.375,5.333s7.443,21.569,7.442,51.174'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2629.552,1361.85c0,0,22.838,42.032,25.543,47.009c3.281,6.038,65.474,19.528,65.474,19.528'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2679.557,1418.341c0,0,5.061-16.893,3.917-22.247c-1.144-5.354-17.494-24.779-18.866-24.604    c-1.372,0.177-24.633,9.544-24.633,9.544'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2384.407,1366.952c0,0-44.662-17.133-49.951-19.162c-6.416-2.461-62.472,27.668-62.472,27.668'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2309.696,1356.466c0,0,7.122,16.133,11.469,19.462c4.346,3.328,29.394,7.484,30.323,6.458    c0.928-1.025,12.535-23.253,12.535-23.253'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1809.766,678.107c0,0-10.965,13.813-11.888,19.209c-0.922,5.396,7.059,29.499,8.398,29.844    c1.339,0.345,26.416,0.271,26.416,0.271'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2569.714,2002.257c0,0,0.759,36.939-44.763,34.406c-34.9-1.942-66.559-4.318-68.942-4.604    c-6.823-0.819-56.06,45.399-56.06,45.399l-96.211,26.191'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2434.449,2047.065c0,0,10.517,13.36,15.545,15.525c5.028,2.166,54.903,1.256,55.553,0.035    c0.651-1.221,6.938-26.333,6.938-26.333'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3015.667,1998.112c0,0,76.57-76.779,90.821-94.647c25.983-32.579,42.887-66.371,42.887-66.371'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3032.49,1728.689c0,0,2.392,19.921,6.838,23.114c4.447,3.192,42.312,17.751,43.209,16.697    c0.896-1.052,11.425-18.28,11.425-18.28'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2705.245,394.736c0,0-47.367,6.677-52.977,7.468c-6.805,0.96-41.215,54.495-41.215,54.495'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2634.822,421.799c0,0,14.053,10.655,19.468,11.458c5.416,0.803,29.336-7.712,29.651-9.059    c0.315-1.347-0.315-26.415-0.315-26.415'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2008.599,865.392c0,0,14.306-62.011,20.386-65.213c5.013-2.64,47.341-24.923,47.341-24.923    s18.241-8.294,20.495-18.607c2.255-10.313,7.79-41.926,23.721-37.13c15.931,4.797,30.41,13.429,30.41,13.429'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2019.184,824.516c0,0,16.825,5.282,22.193,4.208c5.369-1.074,25.007-17.165,24.849-18.54    c-0.158-1.374-9.219-24.757-9.219-24.757'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1280.309,555.569c0,0,29.535-37.628,33.032-42.085c4.244-5.405-8.025-67.851-8.025-67.851'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1312.349,487.268c0,0-17.516,2.051-21.977,5.223c-4.462,3.172-15.815,25.883-15.109,27.072    c0.706,1.189,18.526,18.833,18.526,18.833'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2431.633,1110.477c0,0-0.329-16.795,2.081-17.27c2.409-0.476,33.497-5.709,35.607-4.511    c2.112,1.198,8.733,9.775,8.733,9.775s-16.1,11.869-18.63,12.38C2456.892,1111.363,2438.4,1111.821,2431.633,1110.477    c-6.767-1.345-37.18-12.745-37.18-12.745'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2030.758,1108.125c0,0-4.091,27.46-4.268,34.418c3.018,0.692,5.939,0.304,5.955,0.949    c0.075,3.061,1.742,22.453,3.734,29.059c1.991,6.606,16.271,35.777,16.271,35.777'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2036.179,1172.551c0,0,16.57-3.449,22.31-4.681'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2935.455,1249.538c0,0,3.003,16.527,5.459,16.516c2.456-0.011,33.964-1.035,35.796-2.628    c1.832-1.592,6.623-11.311,6.623-11.311s-18.13-8.446-20.711-8.446C2960.039,1243.669,2941.822,1246.88,2935.455,1249.538    c-6.367,2.658-33.921,19.853-33.921,19.853'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3140.639,1789.95c0,0-15.36,6.799-14.773,9.184c0.587,2.384,8.98,32.771,10.959,34.178    c1.978,1.407,12.55,3.783,12.55,3.783s3.953-19.606,3.348-22.116C3152.116,1812.469,3144.717,1795.515,3140.639,1789.95    c-4.079-5.566-27.262-28.312-27.262-28.312'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1565.754,1939.489l-49.686,45.803l-31.196-1.1l-76.47-19.312c0,0-66.337-19.453-72.619-21.262'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='1552.447,3809.445 1590.128,3790.706 1612.79,3724.352'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2642.125,2037.875c0,0-11.879,48.191-43.112,70.606c-37.483,26.9-34.356,24.656-56.577,40.603    l-40.729,35.582l-73.923,75.878'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3374.734,3333.121c0,0,4.048-23.72-2.113-63.817c-2.81-18.282-12.014-31.713-13.754-49.29'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2031.506'
                y1='1143.108'
                x2='2061.092'
                y2='1145.962'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1573.791'
                y1='4124.23'
                x2='1640.391'
                y2='4043.227'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M911.729,4399.679l-40.156-42.036l-26.734,1.595c0,0-26.832-20.715-28.857-23.73    c-2.026-3.015-1.663-6.787,0.953-11.641c3.565-6.619,30.745-35.443,30.745-35.443'
              />
              <polygon
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='1201.002,3499.018 1174.321,3546.504 1222.737,3573.085 1248.95,3525.709'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1528.627'
                y1='1604.179'
                x2='1574.194'
                y2='1616.43'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1513.155'
                y1='1540.574'
                x2='1497.492'
                y2='1562.355'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1531.454'
                y1='1562.714'
                x2='1580.959'
                y2='1541.539'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1483.903'
                y1='1533.223'
                x2='1482.25'
                y2='1497.562'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2303.182,1359.623c-8.548-18.161-30.212-23.033-30.212-23.033l-118.493-28.791'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5075.92,1574.102c0,0,2.799,26.722,9.549,35.825'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='5078.22'
                y1='1588.883'
                x2='5031.594'
                y2='1595.657'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5053.743,1592.439c0,0,2.81,23.467-1.394,43.344c-3.592,16.988-6.364,34.248-6.364,34.248'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4969.03,1317.229c0,0-8.931,34.069-1.732,58.162'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4236.762,574.157c0,0-2.318,21.108,0.053,26.065c5.498,11.492,64.352,32.82,74.037,31.139    c9.686-1.681,19.315-10.636,19.315-10.636'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4501.991'
                y1='1024.706'
                x2='4501.916'
                y2='1139.05'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4560.325'
                y1='1049.589'
                x2='4501.937'
                y2='1049.73'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4615.874'
                y1='1081.867'
                x2='4501.976'
                y2='1081.053'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4501.8'
                y1='1056.131'
                x2='4456.334'
                y2='1056.128'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3919.753'
                y1='1779.334'
                x2='3896.496'
                y2='1743.351'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3907.937'
                y1='1251.025'
                x2='3933.658'
                y2='1203.495'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3897.289,1166.617c0,0,7.456-23.811-0.134-34.468c-7.589-10.656-28.182-25.709-28.182-25.709'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='5156.529'
                y1='2350.538'
                x2='5124.032'
                y2='2378.866'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3674.976,369.058c0,0,51.642-25.743,60.332-25.968c8.689-0.224,27.87,5.597,27.87,5.597    s8.604,27.457,17.082,40.139'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3771.147,370.597 3710.135,375.171 3695.224,359.805'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1511.457,994.441c0,0,8.888,53.143,16.827,90.309l6.4,33.272l34.68,42.839l49.636,2.388    c0,0,78.086-23.061,118.5-22.173c45.5,1,66.929,4.007,80.054,9.839c17.404,7.734,22.414,25.565,24.576,32.118    c2.163,6.552,3.969,24.961,3.771,27.536s-10.008,20.003-10.008,20.003s-9.323-5.522-10.771-7.47    c-1.448-1.948-0.055-33.442,0.122-35.892s16.885-4.177,16.885-4.177'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4616.144'
                y1='1002.809'
                x2='4615.617'
                y2='1091.903'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4560.486'
                y1='1001.185'
                x2='4559.961'
                y2='1159.531'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4307.29,1405.453l-140.33-46.979l-135.932-91.016l-76.9-51.116    c-47.703-31.715-101.113-53.312-101.113-53.312'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4705.338'
                y1='4925.769'
                x2='4707.596'
                y2='4867.784'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4730.748'
                y1='4894.392'
                x2='4706.631'
                y2='4892.56'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2660.099,3679.337c0,0,23.7,23.729,51.896,30.792c25.889,6.487,42.971,6.096,42.971,6.096'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2680.271'
                y1='3738.614'
                x2='2707.41'
                y2='3708.826'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2134.5'
                y1='4266.707'
                x2='2119.752'
                y2='4299.119'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2196.97'
                y1='3970.659'
                x2='2127.081'
                y2='3945.202'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1974.574,4681.21c28.752-32.953,52.067-42.509,83.541-54.738c31.474-12.229,69.445,15.108,98.736-1.321    c29.291-16.429,32.509-31.685,56.195-65.033c18.75-26.396,48.947-66.454,48.947-66.454s-9.762-9.089-16.548-9.681    c-6.786-0.592-27.479,25.581-31.69,33.79c-4.211,8.208,5.547,33.614,5.547,33.614'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1620.027'
                y1='4026.351'
                x2='1640.391'
                y2='4043.227'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1540.835'
                y1='4096.36'
                x2='1573.791'
                y2='4124.23'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1700.132,3989.936c-8.582,3.938-17.372,6.564-25.784,9.779c-8.408,3.215-15.67,4.296-23.802,4.879    c-8.13,0.583-22.159-0.247-28.297,0.72c-6.139,0.968-13.528,10.485-13.528,10.485'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1802.161,3601.629c0,0,16.383,34.121,5.264,67.6c-10.289,30.981-39.773,47.807-20.079,105.139    c16.174,47.085,19.253,46.7,23.302,74.294'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='1201.002,3499.018 1138.059,3469.843 1085.295,3449.748 1071.351,3477.134 1037.684,3467.206    '
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4455.501'
                y1='1042.992'
                x2='4455.501'
                y2='1071.917'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='5177.629'
                y1='2448.878'
                x2='5203.282'
                y2='2448.072'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4634.682'
                y1='447.294'
                x2='4634.045'
                y2='475.987'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1751.785,4495.539c-0.484,15.636-1.097,18.551-7.526,37.731c-6.43,19.183-10.265,22.819-2.708,36.952'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1756.422,4564.915c0,0,7.66,12.653,14.441,25.437c6.781,12.782,18.519,35.989,22.694,49.162'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1726.387'
                y1='4578.367'
                x2='1794.977'
                y2='4546.728'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2527.02,4004.338c0,0-13.333,21.158-22.351,24.887c-9.018,3.729-16.655,2.424-16.655,2.424'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2103.081'
                y1='3959.128'
                x2='2184.108'
                y2='3994.329'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2519.778,4695.438l-40.534-44.914c0,0-8.985,6.854-16.201,23.701c-7.068,16.503-1.642,35.038-1.642,35.038    s27.025,4.391,34.506,4.383c5.672-0.006,14.066-7.466,25.047-19.488c10.088-11.045,22.1-34.196,22.1-34.196    s0-18.015-25.178-23.848c-21.23-4.918-38.632,14.41-38.632,14.41'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4044.713'
                y1='4891.778'
                x2='4056.313'
                y2='4842.468'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4875.444,3432.93c0,0,29.781,10.942,41.765,10.228'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2196.942'
                y1='4342.783'
                x2='2227.688'
                y2='4391.805'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3516.779'
                y1='1870.931'
                x2='3551.18'
                y2='1914.137'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2642.125,1928.333 2845.049,1928.333 2845.049,1984.167'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2642.125,1748.667 2642.125,1724.375 2688.75,1724.375 2688.75,1748.667'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2743.625,1748.667 2743.625,1724.375 2788.775,1724.375 2788.775,1748.667'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1154.934'
                y1='1440.279'
                x2='1131.6'
                y2='1392.612'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='641.618'
                y1='2767.607'
                x2='607.557'
                y2='2804.359'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2187.978'
                y1='2514.643'
                x2='2218.277'
                y2='2462.982'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3245.358,2283.416c0,0,42.643-39.087,17.052-70.845c-13.232-16.421,1.226-34.627,1.226-34.627    l36.539-52.435'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3334.227'
                y1='2218.104'
                x2='3484.184'
                y2='2314.373'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2793.625,1723.375 2793.625,1694.25 2638.5,1694.25 2638.5,1724.375 2642.125,1724.375'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1964.612'
                y1='2402.208'
                x2='1992.042'
                y2='2291.308'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2102.167'
                y1='2520.414'
                x2='2120.535'
                y2='2464.261'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2151.937'
                y1='2550.035'
                x2='2168.44'
                y2='2500.413'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='2345.683,2554.025 2412.5,2569.875 2399.499,2601.118'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1753.444,1731.688l59.227-40.727l25.608-8.629l16.694-4.381c0.046-2.426,0.24-34.734,0.24-34.734    s-16.682-7.832-17.179-10.365c-0.646-3.292,4.202-23.009,5.773-29.728c3.876-16.581,15.548-54.664,15.548-54.664    s12.641-58.184,20.897-85.289c7.09-23.278,14.356-92.164,14.356-92.164s0.37-42.319,0.383-51.229    c0.013-8.075-1.582-17.476-17.182-26.656c-58.835-34.621-41.918-62.547-41.918-62.547'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4338.001,2266.312c17.832-17.44,62.438-46.991,76.438-54.667l7.208-4.127c0,0,20.468-9.718,52.945-15.815    c28.005-5.257,38.009-9.49,49.167-16.688c15.5-10,20.525-16.631,30.614-32.937c10.876-17.578,42.996-55.892,42.996-55.892    c15.99-24.67,31.178-39.844,55.927-50.705c24.75-10.863,37.976-14.948,56.5-18.928c26.553-5.703,56.952-8.668,89.176,1.725'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4335.576,2982.056c-8.307-9.15-19.278-23.773-24.275-35.672c-17.537-41.76-71.347-85.503-71.347-85.503    s-16.892-16.688-23.756-49.062'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M4539.302,3257.811c0,0-18.681-11.715-29.715-25.497c-11.034-13.782-28.702-40.09-35.215-54.083    c0,0-23.493-45.93-28.912-74.339'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4514.009'
                y1='2784.912'
                x2='4313.543'
                y2='2947.817'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5061.989,2897.139c-13.326-10.876-24.491-29.623-27.924-39.653c-3.927-11.476-22.174-75.95-22.174-75.95'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M5083.792,3236.87c0,0-25.023,10.217-47.618,16.752'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3304.73'
                y1='2264.036'
                x2='3351.477'
                y2='2294.663'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2733.283'
                y1='1872.667'
                x2='2788.775'
                y2='1872.667'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2642.125'
                y1='1828.166'
                x2='2697.439'
                y2='1828.166'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2733.407'
                y1='1828.166'
                x2='2788.721'
                y2='1828.166'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2672.333'
                y1='1984.167'
                x2='2672.333'
                y2='2010.738'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1916.94,2453.011c0,0-18.321,40.95-27.521,57.053'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1826.748,2511.373c0,0,41.193-18.6,80.559,8.17'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3741.76,3369.793c49.76-9.208,60.573-41.248,60.573-41.248s24.904-3.332,49.619,3.812    s34.381,13.477,34.381,13.477s-2.824,20.29,2.834,33.5c4.502,10.512,6.445,23.01,48.859,36.687    c16.626,5.361,38.338,10.738,54.152,17.502c18.183,7.771,28.194,23.597,30.568,34.479l2.678,27.334c0,0,1.836,29.362,2.419,41.819    c0.275,5.85,0.447,18-0.357,26.256c-0.805,8.257-4.223,12.112-11.9,12.446c-7.679,0.335-24.286-8.347-33.557-11.393    c-9.27-3.045-30.028-10.977-56.695-22.294c0,0-82.101-57.302-118.632-31.987s-9.202,50.42-35.202,60.369    s-58.127-26.031-87.621-26.671c-63.652-1.382-58.031-102.867-58.031-102.867'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3569.714,3427.817c7.779-20.914,103.286,52.183,142.594,22.596s42.691-51.413,30.815-77.569    s-44.912-110.886-55.295-144.612c-12.566-40.82,8.145-63.66,42.633-60.986c19.538,1.515,24.804-25.91,52.054-29.41    c44.228-5.681,49.195-45.907,31.734-73.327c-11.56-18.153-8.164-32.558-20.406-50.986c-13.562-20.415-43.992-48.835-63.382-86.855    c-17.767-34.84-50.313-76.55-69.795-102.417c-3.875,1.815-11.251,5.365-13.917,7.032c-4.334-6.365-11.895-24.448-24.917-32.448    c-10.703-6.575-46.839-0.946-78.419-0.167s-76.724-3.417-76.724-3.417c-34.938-14.937-88.356-47.583-130.276-47.583    s-46.491,28.667-46.414,41.667s4.517,90.858,10.674,118.962s26.111,54.805,39.514,73.481    c20.819,29.011,44.285,35.473,65.631,64.465c29.912,40.628-4.279,79.18-22.779,101.18s-49.478,16.658-84.143,7.291    c-31.017-8.381-36.421-5.068-66.751-4.366c-29.644,0.686-32.265-2.162-37.375-16.349c-6.495-18.028-1.95-30.279,1.765-41.131    c5.412-15.808,7.381-26.109,19.791-43.625c23.394-33.021,50.96-30.552,59.596-53.25c4.708-12.373,4.857-43.492,4.074-57.855    s-7.321-24.407-12.764-70.164c-4.29-36.068-4.506-52.46-7.82-64.002'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3489.773,4109.27c0,0,21.704,7.832,31.853,19.764c9.629,11.321,16.838,18.008,30.591,12.341    c15.903-6.554,19.851-28.863,32.242-33.835s52.672-12.637,52.672-12.637l-10.034-55.093'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3620.386,4098.292c0,0,2.281,19.411,12.812,33.929'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2968.298,3117.584c0,0,11.913-21.229,28.962-39.337c15.774-16.755,22.751-20.463,22.751-20.463'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2996.461,3023.404c0,0,1.83,27.512,6.542,48.917'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2999.997,2995.783c0,0,4.462-20.727,15.17-32.762c14.375-16.157,30.847-22.43,54.34-33.892'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='814.768'
                y1='4328.249'
                x2='762.619'
                y2='4280.976'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1248.95,3525.709c0,0,13.455-22.681,31.142-23.601c17.686-0.92,63.02-2.336,70.228,1.997    s8.921,8.551,13.156,21.895c5.719,18.021,9.301,24.613,9.301,24.613'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1504.077,3540.889c0,0,25,23.841,55.75,41.842s62.75,34.251,62.75,34.251'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3162.546,1626.399 3192.75,1616.038 3227.141,1694.902'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3981.667,2420.667c0,0-1.667,40.436,14.594,49.667c6.447,3.66,18.24,7.176,23.906,24.75    s19.607,68.044,19.554,88.98c-0.038,14.802-3.876,24.804,2.418,38.938'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3326.809,2667.042c0,0,25.821-1.817,60.562,8.097s46.275,13.542,46.275,13.542'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3388.322,2675.411 3396.79,2651.499 3440.057,2665.014'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3341.477,3162.739c0,0,8.626,20.203,15.551,47.395c7.14,28.033-5.159,34.729-17.444,37.965    l-116.083,30.568l-15.167-1.634'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3163.445,3069.876c0,0,10.597-10.744,41.844-1.987'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3144.393'
                y1='3105.163'
                x2='3170.738'
                y2='3085.779'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2902.019'
                y1='3160.609'
                x2='2967.316'
                y2='3160.609'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3289.997,2741.208c0,0,13.28-13.733,57.258-9.765c44.726,4.036,182.024,67.28,182.024,67.28'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2884.667'
                y1='3200.39'
                x2='2884.667'
                y2='3160.822'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3113.853'
                y1='2809.311'
                x2='3095.721'
                y2='2778.029'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3208.446,2824.45c0,0,36.67-5.994,53.549-6.472'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3215.016,2849.067c0,0,28.155-5.895,49.32-6.418'
              />
              <polyline
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='3176.083,3045.157 3191.772,3044.646 3203.124,3030.136'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3191.494'
                y1='3116.186'
                x2='3152.224'
                y2='3127.783'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2629.615,3509.84c0,0,40.108-1.74,56.08-0.998'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2637.279,3485.879c0,0,5.721,11.835,32.013,11.835'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2441.397,3120.624c0,0-15.57,14.829-14.692,23.561s3.097,21.813,2.821,22.231'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2303.873,3238.477c0,0,33.376-17.592,56.814-36.097'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2513.061'
                y1='2564.691'
                x2='2493.5'
                y2='2596.432'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2003.897,2698.176c0,0-14.451,7.782-19.791,5.282s-8.646-12.462-16.731-11.278s-18.91,7.044-18.91,7.044'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1952.036,2587.588c0,0,16.205,43.246,24.709,79.079'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2087.39'
                y1='2979.5'
                x2='2087.39'
                y2='3058.333'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2152.391'
                y1='3178.07'
                x2='2180.17'
                y2='3216.599'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2130.241,3169.084c0,0-21.957-15.329-22.764-21.911c-0.561-4.577-1.644-24.673-1.644-24.673'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2071.5'
                y1='3061.763'
                x2='2071.5'
                y2='3084.677'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2126.689'
                y1='2980'
                x2='2126.689'
                y2='3011.319'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2253.756'
                y1='2980'
                x2='2253.756'
                y2='3009.874'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2161.192'
                y1='3143.457'
                x2='2174.916'
                y2='3159.804'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2190.058'
                y1='3191.602'
                x2='2235.681'
                y2='3188.774'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2540.01,5074.805c0,0-3.554-12.326-23.951-28.103c-22.165-17.144-56.604-24.301-84.524-40.597    s-38.968-59.953-63.682-63.317s-62.064-8.337-82.728-47.245'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2777.82'
                y1='3077.917'
                x2='2805.397'
                y2='3077.917'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2103.5'
                y1='2721.417'
                x2='2057.864'
                y2='2721.417'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1966.776'
                y1='4384.309'
                x2='1993.625'
                y2='4379.618'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2099.744'
                y1='3705.028'
                x2='2068.287'
                y2='3651.058'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3096.534'
                y1='2829.059'
                x2='3063.021'
                y2='2805.282'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3047.982'
                y1='2623.761'
                x2='3074.172'
                y2='2602.884'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3221.064,2654.203c0,0,15.829,2.184,18.882,4.157s-0.358,10.96,7.408,11.581s14.158-0.391,14.158-0.391'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3017.395,2537.39c0,0-0.987,18.627-16.343,31.877'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1868.242,2505.366c0,0-4.666,20.152-14.954,43.143'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='1941.374'
                y1='2426.787'
                x2='2040'
                y2='2456.76'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M2257.5,2480.281c0,0-6.333,28.906-2.75,31.312s45.854,16.295,45.719,21.184s-4.469,34.141-4.469,34.141'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2238.502'
                y1='2550.035'
                x2='2216.388'
                y2='2600.667'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M1980.417,2491.223c0,0-31.917-4.556-36.734,3.863c-4.714,8.24-3.02,29.239,1.4,30.485    c4.419,1.247,35.418,0.297,35.418,0.297'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2054.709'
                y1='2340.493'
                x2='2045.667'
                y2='2373.837'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2061.332'
                y1='2434.006'
                x2='2068.087'
                y2='2415.333'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2113.5'
                y1='2919.667'
                x2='2089.18'
                y2='2919.667'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M279.829,3346.759c0,0,6.917,11.851,33.293,22.918'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2198.226'
                y1='2670.5'
                x2='2198.226'
                y2='2710.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2248.5'
                y1='2685.5'
                x2='2248.5'
                y2='2710.5'
              />
              <line
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2173.5'
                y1='2685.083'
                x2='2173.5'
                y2='2670.197'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2047.125'
                y1='4709.5'
                x2='2011.204'
                y2='4691.369'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='3171.124'
                y1='2768.578'
                x2='3136.172'
                y2='2768.578'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2915.816'
                y1='3186.417'
                x2='2899.216'
                y2='3186.417'
              />
              <path
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M3277.5,2664.096c0,0,2.609,18.847,10.647,23.034'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='4590.293'
                y1='1829.466'
                x2='4746.695'
                y2='1803.72'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2491.404'
                y1='3959.556'
                x2='2485.705'
                y2='4053.177'
              />
              <line
                fill='none'
                stroke='#252526'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                x1='2698.84'
                y1='3719.249'
                x2='2745.115'
                y2='3752.09'
              />
            </g>
            <line
              fill='none'
              stroke='#252526'
              strokeWidth='2.5'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='2088.04'
              y1='2910'
              x2='2088.04'
              y2='2943.5'
            />
          </g>
          <g xmlns='http://www.w3.org/2000/svg' id='NetVRk_Centers'>
            <g id='Education_District_NetVRk_Centers_1_'>
              <radialGradient
                id='NetVRk_Center_00000012457875305914307150000002121697546231824519_'
                cx='5072.2266'
                cy='8178.6821'
                r='49.9928'
                gradientTransform='matrix(-0.1672 0.4713 -0.4713 -0.1672 7178.9795 2402.0261)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_49_'
                fill='url(#NetVRk_Center_00000012457875305914307150000002121697546231824519_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='2461.087,3393.171 2444.371,3440.294 2491.494,3457.01 2508.21,3409.888'
              />

              <radialGradient
                id='NetVRk_Center_00000111897358025025324750000010081684960442073239_'
                cx='6044.3545'
                cy='10540.7373'
                r='49.9956'
                gradientTransform='matrix(-0.2473 0.4347 -0.4347 -0.2473 7288.5938 3515.4412)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_48_'
                fill='url(#NetVRk_Center_00000111897358025025324750000010081684960442073239_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='1202.393,3502.108 1177.676,3545.571 1221.139,3570.287 1245.856,3526.824'
              />

              <radialGradient
                id='NetVRk_Center_00000111896477526473668570000002507176773020952748_'
                cx='4393.876'
                cy='7673.5947'
                r='50'
                gradientTransform='matrix(0 0.5 -0.5 0 6573.3779 871.1572)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_47_'
                x='2711.581'
                y='3043.095'
                fill='url(#NetVRk_Center_00000111896477526473668570000002507176773020952748_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />

              <radialGradient
                id='NetVRk_Center_00000137102105215135453120000008500578837224355000_'
                cx='5724.7925'
                cy='8942.5732'
                r='50.0084'
                gradientTransform='matrix(-0.4344 0.2475 -0.2475 -0.4344 6805.0107 6121.5371)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_46_'
                fill='url(#NetVRk_Center_00000137102105215135453120000008500578837224355000_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='2114.071,3620.164 2070.633,3644.923 2095.392,3688.363 2138.83,3663.604'
              />

              <radialGradient
                id='NetVRk_Center_00000048488373491694401770000004421829693715389883_'
                cx='7246.5537'
                cy='8252.6982'
                r='50'
                gradientTransform='matrix(0 0.5 -0.5 0 6573.3779 871.1572)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_45_'
                x='2422.029'
                y='4469.434'
                fill='url(#NetVRk_Center_00000048488373491694401770000004421829693715389883_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />
            </g>
            <g id='Creative_District_NetVRk_Centers_1_'>
              <radialGradient
                id='NetVRk_Center_00000078019836548149171880000005571947118702637724_'
                cx='4811.752'
                cy='6830.5889'
                r='50'
                gradientTransform='matrix(0 0.5 -0.5 0 6573.3779 871.1572)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_44_'
                x='3133.083'
                y='3252.033'
                fill='url(#NetVRk_Center_00000078019836548149171880000005571947118702637724_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />

              <radialGradient
                id='NetVRk_Center_00000031193776067783745440000013066904941690850192_'
                cx='4611.75'
                cy='6196.3184'
                r='50'
                gradientTransform='matrix(0 0.5 -0.5 0 6573.3779 871.1572)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_43_'
                x='3450.219'
                y='3152.032'
                fill='url(#NetVRk_Center_00000031193776067783745440000013066904941690850192_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />

              <radialGradient
                id='NetVRk_Center_00000083810027913579951000000014273134142370556037_'
                cx='3949.0254'
                cy='6876.4609'
                r='50'
                gradientTransform='matrix(0 0.5 -0.5 0 6573.3779 871.1572)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_42_'
                x='3110.147'
                y='2820.67'
                fill='url(#NetVRk_Center_00000083810027913579951000000014273134142370556037_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />

              <radialGradient
                id='NetVRk_Center_00000062879945210062541810000004914570814022396336_'
                cx='5112.7188'
                cy='4436.1309'
                r='50'
                gradientTransform='matrix(0 0.5 -0.5 0 6573.3779 871.1572)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_41_'
                x='4330.312'
                y='3402.517'
                fill='url(#NetVRk_Center_00000062879945210062541810000004914570814022396336_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />

              <radialGradient
                id='NetVRk_Center_00000090269282594464389170000000140992835908073129_'
                cx='6534.6284'
                cy='5338.9053'
                r='50'
                gradientTransform='matrix(0 0.5 -0.5 0 6573.3779 871.1572)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_40_'
                x='3878.925'
                y='4113.472'
                fill='url(#NetVRk_Center_00000090269282594464389170000000140992835908073129_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />
            </g>
            <g id='Business_District_NetVRk_Centers_1_'>
              <radialGradient
                id='NetVRk_Center_00000107574312995653399950000017039414209401507485_'
                cx='3186'
                cy='3010.9995'
                r='25'
                gradientTransform='matrix(0 -1 1 0 -542.4995 5997.5)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_39_'
                x='2443.5'
                y='2786.5'
                fill='url(#NetVRk_Center_00000107574312995653399950000017039414209401507485_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />

              <radialGradient
                id='NetVRk_Center_00000066493839561523381710000009279710575061596084_'
                cx='3669.9058'
                cy='8948.9609'
                r='49.997'
                gradientTransform='matrix(0.4816 0.1346 -0.1346 0.4816 1411.0105 -2550.1082)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_38_'
                fill='url(#NetVRk_Center_00000066493839561523381710000009279710575061596084_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='1943.1,2271.027 1991.254,2284.488 2004.714,2236.334 1956.56,2222.873'
              />

              <radialGradient
                id='NetVRk_Center_00000073686227742318142080000003027038953146844323_'
                cx='4110.2363'
                cy='9464.6514'
                r='49.9895'
                gradientTransform='matrix(0.4727 0.1633 -0.1633 0.4727 1718.5031 -2580.5713)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_37_'
                fill='url(#NetVRk_Center_00000073686227742318142080000003027038953146844323_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='2084.042,2580.038 2131.301,2596.364 2147.627,2549.104 2100.367,2532.778'
              />

              <radialGradient
                id='NetVRk_Center_00000070117009896572478330000011343481626888104626_'
                cx='2875.5305'
                cy='11667.9287'
                r='49.999'
                gradientTransform='matrix(0.4488 -0.2205 0.2205 0.4488 -2745.5137 -3174.5745)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_36_'
                fill='url(#NetVRk_Center_00000070117009896572478330000011343481626888104626_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='1106.391,1461.4 1151.266,1439.35 1129.215,1394.474 1084.34,1416.525'
              />

              <radialGradient
                id='NetVRk_Center_00000151541401283936719080000007949076199185068425_'
                cx='3104.7297'
                cy='11382.4453'
                r='49.9983'
                gradientTransform='matrix(-0.3407 0.366 -0.366 -0.3407 5827.564 5508.7729)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_00000045589302845599337550000011176674996853240448_'
                fill='url(#NetVRk_Center_00000151541401283936719080000007949076199185068425_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='602.743,2731.577 568.675,2768.174 605.271,2802.242 639.339,2765.646'
              />
            </g>
            <g id='Adult_District_NetVRk_Centers_1_'>
              <radialGradient
                id='NetVRk_Center_00000170984246303316451600000007865574012501775259_'
                cx='3771.3838'
                cy='7479.4805'
                r='49.9936'
                gradientTransform='matrix(-0.3536 0.3536 -0.3536 -0.3536 6694.1611 3661.8279)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_34_'
                fill='url(#NetVRk_Center_00000170984246303316451600000007865574012501775259_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='2715.855,2315.29 2680.5,2350.645 2715.855,2386 2751.211,2350.645'
              />

              <radialGradient
                id='NetVRk_Center_00000031911149574443291100000014628967328846507177_'
                cx='2746.9795'
                cy='8503.3203'
                r='50'
                gradientTransform='matrix(-0.5 0 0 -0.5 4089.3457 5918.5771)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_00000016060307587965984930000010032303740385605775_'
                x='2690.856'
                y='1641.917'
                fill='url(#NetVRk_Center_00000031911149574443291100000014628967328846507177_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />

              <radialGradient
                id='NetVRk_Center_00000052820248776527833880000006935272716649038771_'
                cx='3850.4629'
                cy='10675.5801'
                r='49.9936'
                gradientTransform='matrix(-0.3536 -0.3536 0.3536 -0.3536 830.8775 6017.6143)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_32_'
                fill='url(#NetVRk_Center_00000052820248776527833880000006935272716649038771_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='3279.594,881.206 3244.239,845.85 3208.884,881.206 3244.239,916.561'
              />

              <radialGradient
                id='NetVRk_Center_00000004532912582886883430000014101287573901211776_'
                cx='2995.7329'
                cy='8398.2529'
                r='49.9921'
                gradientTransform='matrix(-0.5001 -0.0011 0.0011 -0.5001 4249.4331 6159.4038)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_31_'
                fill='url(#NetVRk_Center_00000004532912582886883430000014101287573901211776_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='2785.559,1931.196 2735.559,1931.088 2735.451,1981.088 2785.451,1981.196'
              />

              <radialGradient
                id='NetVRk_Center_00000065056366159621409910000011746872206096195222_'
                cx='4974.3188'
                cy='8541.4551'
                r='49.9902'
                gradientTransform='matrix(-0.4842 -0.1255 0.1255 -0.4842 3565.1052 6114.583)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_30_'
                fill='url(#NetVRk_Center_00000065056366159621409910000011746872206096195222_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='2258.841,1337.113 2210.438,1324.575 2197.901,1372.978 2246.304,1385.515'
              />
            </g>
            <g id='Entertainment_District_NetVRk_Centers_1_'>
              <radialGradient
                id='NetVRk_Center_00000056398892578140654630000004906667071501518728_'
                cx='3147.2656'
                cy='6707.9111'
                r='50'
                gradientTransform='matrix(0 0.5 -0.5 0 6573.3779 871.1572)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_29_'
                x='3194.422'
                y='2419.79'
                fill='url(#NetVRk_Center_00000056398892578140654630000004906667071501518728_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='50'
              />

              <radialGradient
                id='NetVRk_Center_00000055680875950428456210000002626627993363951522_'
                cx='2225.0146'
                cy='6126.2471'
                r='49.9939'
                gradientTransform='matrix(0.3146 0.3887 -0.3887 0.3146 5265.1924 -868.4163)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_00000130647895380843698440000002885520433949154488_'
                fill='url(#NetVRk_Center_00000055680875950428456210000002626627993363951522_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='3548.749,1920.06 3580.205,1958.925 3619.069,1927.469 3587.614,1888.604'
              />

              <radialGradient
                id='NetVRk_Center_00000039856203989446899660000008772201321772895388_'
                cx='2409.165'
                cy='6019.4189'
                r='49.9986'
                gradientTransform='matrix(-0.2693 0.4213 -0.4213 -0.2693 6785.6079 2725.2793)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_27_'
                fill='url(#NetVRk_Center_00000039856203989446899660000008772201321772895388_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='3593.239,2084.703 3566.31,2126.831 3608.439,2153.759 3635.368,2111.631'
              />

              <radialGradient
                id='NetVRk_Center_00000037670074327286519120000005981254867882345127_'
                cx='175.1625'
                cy='2702.5181'
                r='50.0042'
                gradientTransform='matrix(-1.000000e-04 0.5 -0.5 -1.000000e-04 5938.7656 965.1431)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <rect
                id='NetVRk_Center_26_'
                x='4562.489'
                y='1027.455'
                fill='url(#NetVRk_Center_00000037670074327286519120000005981254867882345127_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='50'
                height='49.999'
              />

              <radialGradient
                id='NetVRk_Center_00000119834231563877481090000016793904369516994989_'
                cx='2918.5833'
                cy='6191.3789'
                r='49.9986'
                gradientTransform='matrix(-0.2693 0.4213 -0.4213 -0.2693 6785.6079 2725.2793)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRk_Center_18_'
                fill='url(#NetVRk_Center_00000119834231563877481090000016793904369516994989_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='3383.605,2253.012 3356.677,2295.14 3398.806,2322.068 3425.734,2279.94'
              />
            </g>
            <g id='Main_NetVRk_Center_1_'>
              <rect
                id='NetVRkCenter_2_'
                x='2686'
                y='2670'
                opacity='0.4'
                fill='#FFFFFF'
                fillOpacity='0.4'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                width='200'
                height='200'
              />

              <radialGradient
                id='NetVRkCenter_00000103222836571472981630000014927123083083418553_'
                cx='2786'
                cy='2770'
                r='100'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' stopColor='#C900FC' />
                <stop offset='0.2' stopColor='#5521FC' />
                <stop offset='0.4' stopColor='#C900FC' />
                <stop offset='0.6' stopColor='#003AFC' />
                <stop offset='0.8' stopColor='#C900FC' />
                <stop offset='1' stopColor='#4626FC' />
              </radialGradient>

              <polygon
                id='NetVRkCenter_1_'
                opacity='0.4'
                fill='url(#NetVRkCenter_00000103222836571472981630000014927123083083418553_)'
                stroke='#FFFFFF'
                strokeMiterlimit='10'
                points='2826,2730 2826,2670 2686,2670 2686,2810 2746,2810 2746,2870 2886,2870 2886,2730'
              />
            </g>
          </g>
          <g xmlns='http://www.w3.org/2000/svg' id='TrillionairesRow'>
            <g id='TrillionairesRow_1_'>
              <path
                id='Trillionaire_Land_49_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2639.324,2912.22c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2641.11,2908.231,2639.324,2910.017,2639.324,2912.22z'
              />
              <path
                id='Trillionaire_Land_48_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2650.574,2921.095c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2652.36,2917.106,2650.574,2918.892,2650.574,2921.095z'
              />
              <path
                id='Trillionaire_Land_47_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2664.199,2925.085c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2665.985,2921.095,2664.199,2922.881,2664.199,2925.085z'
              />
              <path
                id='Trillionaire_Land_46_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2679.574,2921.96c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2681.36,2917.97,2679.574,2919.756,2679.574,2921.96z'
              />
              <path
                id='Trillionaire_Land_45_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2677.824,2933.71c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2679.61,2929.72,2677.824,2931.506,2677.824,2933.71z'
              />
              <path
                id='Trillionaire_Land_44_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2679.574,2947.96c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2681.36,2943.97,2679.574,2945.756,2679.574,2947.96z'
              />
              <path
                id='Trillionaire_Land_43_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2686.574,2960.96c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2688.36,2956.97,2686.574,2958.756,2686.574,2960.96z'
              />
              <path
                id='Trillionaire_Land_42_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2700.324,2964.21c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2702.11,2960.22,2700.324,2962.006,2700.324,2964.21z'
              />
              <path
                id='Trillionaire_Land_41_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2717.324,2971.085c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2719.11,2967.095,2717.324,2968.881,2717.324,2971.085z'
              />
              <path
                id='Trillionaire_Land_40_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2726.199,2957.46c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2727.985,2953.47,2726.199,2955.256,2726.199,2957.46z'
              />
              <path
                id='Trillionaire_Land_39_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2715.824,2936.699c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2717.61,2932.709,2715.824,2934.495,2715.824,2936.699z'
              />
              <path
                id='Trillionaire_Land_38_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2725.803,2945.574c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2727.589,2941.584,2725.803,2943.37,2725.803,2945.574z'
              />
              <path
                id='Trillionaire_Land_37_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2717.178,2924.731c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2718.964,2920.741,2717.178,2922.527,2717.178,2924.731z'
              />
              <path
                id='Trillionaire_Land_36_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2729.792,2919.231c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2731.578,2915.241,2729.792,2917.027,2729.792,2919.231z'
              />
              <path
                id='Trillionaire_Land_35_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2746.206,2923.949c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2747.992,2919.959,2746.206,2921.745,2746.206,2923.949z'
              />
              <path
                id='Trillionaire_Land_34_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2755.167,2921.356c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2756.953,2917.366,2755.167,2919.152,2755.167,2921.356z'
              />
              <path
                id='Trillionaire_Land_33_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2747.542,2946.574c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2749.328,2942.584,2747.542,2944.37,2747.542,2946.574z'
              />
              <path
                id='Trillionaire_Land_32_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2767.042,2942.199c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2768.828,2938.209,2767.042,2939.995,2767.042,2942.199z'
              />
              <path
                id='Trillionaire_Land_31_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2766.792,2965.303c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2768.578,2961.313,2766.792,2963.099,2766.792,2965.303z'
              />
              <path
                id='Trillionaire_Land_30_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2777.792,2956.678c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2779.578,2952.688,2777.792,2954.474,2777.792,2956.678z'
              />
              <path
                id='Trillionaire_Land_29_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2777.792,2975.803c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2779.578,2971.813,2777.792,2973.599,2777.792,2975.803z'
              />
              <path
                id='Trillionaire_Land_28_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2793.292,2975.053c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2795.078,2971.063,2793.292,2972.849,2793.292,2975.053z'
              />
              <path
                id='Trillionaire_Land_27_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2791.542,2960.553c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2793.328,2956.563,2791.542,2958.349,2791.542,2960.553z'
              />
              <path
                id='Trillionaire_Land_26_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2803.042,2947.428c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2804.828,2943.438,2803.042,2945.224,2803.042,2947.428z'
              />
              <path
                id='Trillionaire_Land_25_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2795.063,2930.303c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2796.849,2926.313,2795.063,2928.099,2795.063,2930.303z'
              />
              <path
                id='Trillionaire_Land_24_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2807.813,2933.428c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2809.599,2929.438,2807.813,2931.224,2807.813,2933.428z'
              />
              <path
                id='Trillionaire_Land_23_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2820.313,2928.053c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2822.099,2924.063,2820.313,2925.849,2820.313,2928.053z'
              />
              <path
                id='Trillionaire_Land_22_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2829.313,2924.084c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2831.099,2920.094,2829.313,2921.881,2829.313,2924.084z'
              />
              <path
                id='Trillionaire_Land_21_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2849.813,2910.709c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2851.599,2906.719,2849.813,2908.506,2849.813,2910.709z'
              />
              <path
                id='Trillionaire_Land_20_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2856.313,2930.303c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2858.099,2926.313,2856.313,2928.099,2856.313,2930.303z'
              />
              <path
                id='Trillionaire_Land_19_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2872.896,2928.553c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2874.683,2924.563,2872.896,2926.349,2872.896,2928.553z'
              />
              <path
                id='Trillionaire_Land_18_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2891.063,2921.094c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2892.85,2917.105,2891.063,2918.891,2891.063,2921.094z'
              />
              <path
                id='Trillionaire_Land_17_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2885.98,2895.761c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2887.766,2891.771,2885.98,2893.557,2885.98,2895.761z'
              />
              <path
                id='Trillionaire_Land_16_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2868.241,2880.553c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2870.027,2876.563,2868.241,2878.349,2868.241,2880.553z'
              />
              <path
                id='Trillionaire_Land_15_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2852.324,2946.189c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2854.11,2942.199,2852.324,2943.985,2852.324,2946.189z'
              />
              <path
                id='Trillionaire_Land_14_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2840.449,2954.168c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2842.235,2950.178,2840.449,2951.964,2840.449,2954.168z'
              />
              <path
                id='Trillionaire_Land_13_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2827.574,2955.563c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2829.36,2951.573,2827.574,2953.36,2827.574,2955.563z'
              />
              <path
                id='Trillionaire_Land_12_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2832.574,2978.043c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2834.36,2974.053,2832.574,2975.839,2832.574,2978.043z'
              />
              <path
                id='Trillionaire_Land_11_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2822.199,2989.543c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2823.985,2985.553,2822.199,2987.339,2822.199,2989.543z'
              />
              <path
                id='Trillionaire_Land_10_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2818.574,3006.793c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2820.36,3002.803,2818.574,3004.589,2818.574,3006.793z'
              />
              <path
                id='Trillionaire_Land_9_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2824.302,3023.043c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2826.088,3019.053,2824.302,3020.839,2824.302,3023.043z'
              />
              <path
                id='Trillionaire_Land_8_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2826.324,3036.543c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2828.11,3032.553,2826.324,3034.339,2826.324,3036.543z'
              />
              <path
                id='Trillionaire_Land_7_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2864.292,2964.668c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2866.078,2960.678,2864.292,2962.464,2864.292,2964.668z'
              />
              <path
                id='Trillionaire_Land_6_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2879.542,2971.668c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2881.328,2967.678,2879.542,2969.464,2879.542,2971.668z'
              />
              <path
                id='Trillionaire_Land_5_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2888.792,2983.032c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2890.578,2979.042,2888.792,2980.828,2888.792,2983.032z'
              />
              <path
                id='Trillionaire_Land_4_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2804.792,2967.824c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2806.578,2963.834,2804.792,2965.62,2804.792,2967.824z'
              />
              <path
                id='Trillionaire_Land_3_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2816.542,2971.063c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2818.328,2967.073,2816.542,2968.86,2816.542,2971.063z'
              />
              <path
                id='Trillionaire_Land_2_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2770.542,2928.074c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2772.328,2924.084,2770.542,2925.87,2770.542,2928.074z'
              />
              <path
                id='Trillionaire_Land_1_'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2741.374,2956.324c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2743.16,2952.334,2741.374,2954.12,2741.374,2956.324z'
              />
              <path
                id='Trillionaire_Land'
                fill='#FF2727'
                stroke='#FFC7C7'
                strokeMiterlimit='10'
                d='M2737.771,2967.073c0,2.203,1.786,3.989,3.989,3.989    c2.204,0,3.99-1.787,3.99-3.989c0-2.204-1.786-3.99-3.99-3.99C2739.557,2963.084,2737.771,2964.87,2737.771,2967.073z'
              />
            </g>
          </g>
          <g xmlns='http://www.w3.org/2000/svg' id='Districts'>
            <g id='Creative_District_4_'>
              <g>
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  d='M3261.447,2705.934c0.327-0.041,0.658-0.082,0.99-0.121'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='3.9911,6.9844'
                  d='M3269.389,2705.144c2.39-0.182,4.884-0.327,7.468-0.435'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='1.9955,6.9844,3.9911,6.9844'
                  d='M3280.349,2704.588c40.443-1.139,100.788,6.292,141.768,22.646     c48.028,19.167,88.927,29.19,162.443,36.383c73.516,7.192,265.656,36.356,448.349,53.468     c182.693,17.112,330.522-14.997,425.899-91.773c95.376-76.777,196.411-166.783,405.753-162.168     c130.235,2.871,221.107,51.41,273.23,127.911'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  d='M5139.732,2693.959l0.553,0.833c0,0,0.328-0.091,0.956-0.28'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='3.9974,6.9954'
                  d='M5147.886,2692.341c2.006-0.698,4.368-1.554,7.039-2.577'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='1.9987,6.9954,3.9974,6.9954'
                  d='M5158.183,2688.49c51.104-20.354,191.808-94.09,156.478-279.229     c-42.963-225.131-127.164-312.816-161-411.139c-34.682-100.781-61.004-235.448,28.346-396.083     c89.351-160.635-86.182-422.416-72.6-568.275c13.583-145.859,288.701-193.26,285.063-456.311     c-1.96-141.728-299.167-267.387-496.75-268.59s-343.704,62.555-520.824,63.543c-177.12,0.987-504.838-145.63-657.777-123.974     c-152.939,21.656-137.429,243.072-178.751,351.531c-41.322,108.46-139.677,237.894-112.148,372.791     c27.528,134.897,100.391,200.802,199.267,236.279c0,0,105.041,25.498,78.346,96.863c-26.694,71.365-25.114,89.907,3.832,194.504     c28.946,104.597-2.208,249.083-103.977,270.338c-101.769,21.255-278.736,2.599-350.604,44.861s-133.011,102.916-162.599,210.091     c-29.589,107.174-76.085,92.968-119.333,158.853s-49.778,78.096-57.429,143.47c-7.651,65.375-23.926,128.134-33.204,155.496     c-9.279,27.362-3.021,65.923,45.124,110.424c48.144,44.5,43.968,79.516,89.8,118.822s78.564,30.203,78.564,30.203     c59.662-29.159,133.085-32.96,165.44-37.026c38.015-4.777,112.641,2.133,160.669,21.3c48.028,19.167,88.927,29.19,162.443,36.383     c72.916,7.134,262.527,35.881,443.874,53.046'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  d='M4031.913,2816.991c0.332,0.031,0.664,0.062,0.996,0.093'
                />
              </g>
            </g>
            <g id='Education_District'>
              <path
                fill='none'
                stroke='#FFFFFF'
                strokeWidth='2'
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeDasharray='1.9992,6.9972,3.9984,6.9972'
                d='M2842.903,3183.038c-3.512,12.732-5.459,26.918-4.614,43.363c2.192,42.618-0.173,117.976,0.712,166.632    s-3.104,87.396-0.16,115.922c3.606,34.955,13.307,47.172,35.36,68.071c31.43,29.785,33.176,60.507,24.144,92.485    c-9.031,31.977-30.535,68.22-41.644,165.444c-3.561,31.162-17.324,133.45-23.786,289.312    c-5.468,131.881,18.923,261.357,93.648,421.051c74.726,159.694,28.781,278.074-111.72,342.625c0,0-107.831,34.848-92.336,112.77    c15.495,77.922,30.064,251.311-133.426,252.01c-163.49,0.699-1046.823-98.523-1290.157-355.474    c-243.335-256.95-152.07-665.384,147.11-864.685s278.793-250.203,295.763-394.299c16.97-144.096,128.211-231.128,263.574-306.814    c135.363-75.686,245.363-111.191,282.067-126.04c36.704-14.85,63.743-46.996,97.575-85.209c0,0,75.218-138.6,131.9-176.321    c56.682-37.722,112.608-33.294,139.45-3.409c26.841,29.885,45.578,41.306,86.256,46.382c40.678,5.076,63.288,13.093,74.775,38.321    c11.487,25.228,30.912,46.599,30.027,73.543L2842.903,3183.038L2842.903,3183.038z'
              />
            </g>
            <g id='Creative_District_00000126322744494564888290000002388573828048453542_'>
              <path
                fill='none'
                stroke='#FFFFFF'
                strokeWidth='2'
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeDasharray='1.9986,6.9951,3.9972,6.9951'
                d='M5164.412,2740.746c47.539,115.712-28.572,217.21,32.755,386.925    c61.327,169.715-31.679,293.599-31.679,293.599c-173.463,209.352-306.37,303.791-97.584,474.621    c208.786,170.831,62.375,568.017-81.094,692.533c-143.469,124.517-98.896,258.523-66.314,394.385    c32.581,135.862-36.803,300.16-210.571,332.903c-173.769,32.743-244.681-136.955-446.092-230.095    c-201.411-93.14-311.439-104.669-450.812-30.2c-139.372,74.47-443.981,78.026-610.34-8.262    c-166.359-86.287-201.39-342.144-276.116-501.837c-74.726-159.694-99.116-289.169-93.648-421.051    c6.462-155.862,20.226-258.15,23.786-289.312c11.109-97.224,32.613-133.467,41.644-165.444c9.031-31.978,7.286-62.7-24.144-92.485    c-22.053-20.899-31.754-33.116-35.36-68.071c-2.943-28.526,1.045-67.265,0.16-115.922s1.48-124.014-0.712-166.632    c-2.193-42.618,14.377-70.068,28.311-96.333c13.934-26.265,61.784-40.259,83.422-46.322c21.638-6.062,9.548-28.571,3.425-55.227    s5.555-28.342,27.406-66.436c21.851-38.094-23.134-27.901-26.312-67.888c-3.179-39.987,35.832-41.154,41.79-59.077    c5.958-17.924,40.129-52.644,69.928-74.395c62.473-45.601,157.175-50.012,195.19-54.789c38.015-4.777,112.641,2.133,160.669,21.3    c48.028,19.167,88.927,29.19,162.443,36.383c73.516,7.192,265.656,36.356,448.349,53.468    c182.693,17.112,330.522-14.997,425.899-91.773c0,0,165.499-169.744,392.455-162.809    C5107.82,2562.295,5164.412,2740.746,5164.412,2740.746L5164.412,2740.746z'
              />
            </g>
            <g id='Business_District'>
              <g>
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  d='M2566.565,2573.823c-0.202-0.613-0.317-0.944-0.317-0.944c-0.304-0.137-0.608-0.273-0.911-0.411'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='3.9955,6.9921'
                  d='M2558.999,2569.514c-2.275-1.093-4.509-2.2-6.71-3.323'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='1.9978,6.9921,3.9955,6.9921'
                  d='M2549.184,2564.585c-35.993-18.85-64.061-41.988-122.401-72.063     c-67.191-34.638-140.021-14.476-202.114-60.232c-62.093-45.755-115.572-109.486-141.823-168.073     c-26.251-58.587-118.483-112.796-196.85-194.136c-78.367-81.34-190.554-133.512-368.972-222.584     s-141.644-291.506-141.644-291.506c9.05-178.027,38.994-336.226-115.458-465.567S946.814,785.632,916.148,629.818     c0,0-15.501-85.899-101.236-141.716c-85.735-55.816-300.53-100.05-415.378,32.826C284.686,653.804,428.16,759.515,312.37,854.506     c-115.79,94.991-192.003,161.388-130.686,470.076c61.317,308.688,74.37,402.204,7.237,564.225     c-67.133,162.021-107.874,522.907-72.618,768.447c35.256,245.54,10.233,463.691,2.788,623.725s131.65,291.633,311.752,404.881     c180.101,113.248,83.807,359.846,128.98,593.598c45.174,233.753,167.597,353.266,337.307,353.053     c158.318-0.199,241.286-51.159,258.998-63.45'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  d='M1158.969,4567.021c0.528-0.396,0.79-0.605,0.79-0.605c-0.012-0.333-0.025-0.665-0.037-0.997'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='3.9946,6.9906'
                  d='M1159.518,4558.433c-0.057-2.496-0.096-4.992-0.119-7.489'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='1.9973,6.9906,3.9946,6.9906'
                  d='M1159.38,4547.447c-0.464-194.896,104.602-393.609,286.655-514.885     c299.18-199.302,278.793-250.203,295.763-394.299c16.97-144.096,128.211-231.128,263.574-306.814     c135.363-75.686,245.363-111.191,282.067-126.04c36.704-14.85,63.743-46.996,97.575-85.209c0,0,75.218-138.6,131.9-176.321     c24.81-16.511,49.476-24.947,71.621-26.707'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  d='M2592.027,2916.948c0.333-0.016,0.665-0.031,0.996-0.044c0,0,0.116-0.321,0.301-0.949'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='4.0977,7.171'
                  d='M2594.821,2908.948c0.329-2.139,0.625-4.691,0.805-7.636'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='2.0489,7.171,4.0977,7.171'
                  d='M2595.777,2897.728c0.298-12.474-1.598-30.619-10.41-53.206c-17.561-45.008,3.654-87.433-1.23-130.912     c-4.884-43.479-8.852-91.867-10.372-107.659c-1.052-10.929-4.248-22.572-6.116-28.705'
                />
              </g>
            </g>
            <g id='Adult_District'>
              <g>
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  d='M3623.118,309.97c0.185-0.276,0.37-0.552,0.556-0.827c0,0-0.309-0.139-0.909-0.405'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='3.996,6.993'
                  d='M3616.35,305.965c-1.946-0.827-4.259-1.798-6.913-2.893'
                />
                <path
                  fill='none'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeMiterlimit='10'
                  strokeDasharray='1.998,6.993,3.996,6.993'
                  d='M3606.201,301.744c-47.458-19.358-182.687-69.591-294.373-63.429     c-137.961,7.611-319.993,35.93-429.334,48.397c-109.341,12.467-261.118-54.003-358.232,35.469     c-97.114,89.472-319.164,204.778-437.705,139.357c-118.542-65.421-210.492-245.766-531.572-230.521     s-370.876,118.878-425.319,185.534c-54.443,66.656-244.184,57.453-213.518,213.267s189.323,331.265,343.775,460.605     s124.508,287.541,115.458,465.567c0,0-36.774,202.434,141.644,291.506s290.605,141.243,368.972,222.584     c78.367,81.341,170.6,135.549,196.85,194.136c26.251,58.587,79.73,122.318,141.823,168.073     c62.093,45.755,134.923,25.593,202.114,60.232s94.228,60.075,139.465,80.357c151.139,67.764,127.789-92.264,210.975-56.603     c94.565,40.539,105.295-32.766,105.295-32.766c9.279-27.362,25.553-90.121,33.204-155.496     c7.651-65.375,14.181-77.585,57.429-143.47s89.744-51.679,119.333-158.853c29.588-107.174,90.731-167.828,162.599-210.091     s248.836-23.607,350.604-44.861c101.768-21.255,132.922-165.741,103.977-270.338c-28.945-104.597-30.526-123.139-3.832-194.504     c26.694-71.365-78.346-96.863-78.346-96.863c-98.876-35.476-171.739-101.381-199.267-236.279     c-27.528-134.897,70.826-264.331,112.148-372.791c28.988-76.086,30.007-207.76,80.832-287.064'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
