import gql from 'graphql-tag';

export const fetchAllClaimedLandsQuery = (skip = 0) => {
  const queryString = `
    query {
      tokens(
        first: 1000
        skip: ${skip}
        orderBy: tokenId
        orderDirection: asc
      ) {
        id
        tokenId
        owner
      }
    }
  `;

  return gql(queryString);
};

export const fetchPersonalClaimedLandsQuery = (address: string) => {
  const queryString = `
    query {
      tokens (
        where: { owner: "${address}" }
      ) {
        id
        tokenId
        owner
      }
    }
  `;

  return gql(queryString);
};
