import { walletconnect } from 'utils/web3React';
import useAccountLibrary from 'hooks/useActiveWeb3';

type Props = {
  onConnect: () => void;
  children: JSX.Element;
};

const WalletConnect = ({ onConnect, children }: Props) => {
  const { activate } = useAccountLibrary();
  const onConnectWallet = () => {
    onConnect();
    activate(walletconnect);
  };

  return (
    <div className='w-full'>
      <div className='w-full' onClick={onConnectWallet}>
        {children}
      </div>
    </div>
  );
};

export default WalletConnect;
