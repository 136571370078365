import React from 'react';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { CONNECTOR_LOCAL_KEY } from 'config/constants';
import { shortenAddress } from 'utils/helper';

export default ({ showModal }) => {
  const history = useHistory();
  const { active, account, deactivate } = useWeb3React();

  const handleClick = () => {
    if (active) {
      showModal && showModal(false);
      deactivate();
      window.localStorage.removeItem(CONNECTOR_LOCAL_KEY);
    } else {
      history.push('/wallet');
    }
  };

  return (
    <div
      className='flex items-center mobile:hidden mobileUp:bg-background mobileUp:border mobileUp:border-r-0 mobileUp:border-third mobileUp:px-4 mobileUp:py-2 -mr-4 text-white text-23px mobileUp:rounded-l-full cursor-pointer'
      onClick={handleClick}
    >
      <img src='/images/wallet.svg' className='mr-4' alt='Wallet' />
      <span className='mobile:hidden'>
        {active ? shortenAddress(account) : 'Connect Wallet'}
      </span>
    </div>
  );
};
