import classNames from 'classnames';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './index.scss';

const Sidebar = ({ show, onHideSidebar }) => {
  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split('/')[1];
  return (
    <div
      className={classNames(
        'sidebar-wrapper fixed mobile:left-0 font-teko mobile:top-0 mobile:z-50 h-full flex',
        show ? 'show' : ''
      )}
    >
      <div
        className={classNames(
          'sidebar z-40 transform h-full no-scrollbar w-52 flex-shrink-0 bg-backgroundAlt border-r border-third -my-1 p-4 transition-transform duration-200 ease-in-out translate-x-0'
        )}
      >
        <Link className='max-h-10 mobileUp:hidden' to='/'>
          <img src='/images/logo.svg' alt='logo' />
        </Link>
        <ul className='mt-3'>
          <li
            className={`px-4 py-2 rounded-sm -mr-4 ${
              page === 'land' &&
              'border border-r-0 border-third rounded-l-full bg-background'
            }`}
          >
            <NavLink
              exact
              to='/land'
              activeClassName='active-menu-item'
              className={`flex items-center text-27px transition duration-150 uppercase ${
                page === 'land'
                  ? 'text-white'
                  : 'text-lightPurple hover:text-white'
              }`}
            >
              Mint Map
            </NavLink>
          </li>
          {/* <li
            className={`px-4 py-2 rounded-sm -mr-4 ${
              page === 'lands' &&
              'border border-r-0 border-third rounded-l-full bg-background'
            }`}
          >
            <NavLink
              exact
              to='/lands'
              activeClassName='active-menu-item'
              className={`flex items-center text-27px transition duration-150 uppercase ${
                page === 'lands'
                  ? 'text-white'
                  : 'text-lightPurple hover:text-white'
              }`}
            >
              Lands
            </NavLink>
          </li> */}
          <li
            className={`px-4 py-2 rounded-sm -mr-4 ${
              page === 'transports' &&
              'border border-r-0 border-third rounded-l-full bg-background'
            }`}
          >
            <NavLink
              exact
              to='/transports'
              activeClassName='active-menu-item'
              className={`flex items-center text-27px transition duration-150 uppercase ${
                page === 'transports'
                  ? 'text-white'
                  : 'text-lightPurple hover:text-white'
              }`}
            >
              Transports
            </NavLink>
          </li>
          <li
            className={`px-4 py-2 rounded-sm -mr-4 ${
              page === 'avatars' &&
              'border border-r-0 border-third rounded-l-full bg-background'
            }`}
          >
            <NavLink
              exact
              to='/avatars'
              activeClassName='active-menu-item'
              className={`flex items-center text-27px transition duration-150 uppercase ${
                page === 'avatars'
                  ? 'text-white'
                  : 'text-lightPurple hover:text-white'
              }`}
            >
              Avatars
            </NavLink>
          </li>
          <li
            className={`px-4 py-2 rounded-sm -mr-4 ${
              page === 'bonus-packs' &&
              'border border-r-0 border-third rounded-l-full bg-background'
            }`}
          >
            <NavLink
              exact
              to='/bonus-packs'
              activeClassName='active-menu-item'
              className={`flex bonus-center text-27px transition duration-150 uppercase ${
                page === 'bonus-packs'
                  ? 'text-white'
                  : 'text-lightPurple hover:text-white'
              }`}
            >
              Bonus Packs
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
