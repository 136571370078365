import { GraphQLClient } from 'graphql-request';

const ENDPOINTS = {
  1: 'https://api.thegraph.com/subgraphs/name/furysheep/netvrk-rinkeby',
  4: 'https://api.thegraph.com/subgraphs/name/furysheep/netvrk-rinkeby',
};

export const netvrkClient = (chainId: string | number) => {
  return new GraphQLClient(ENDPOINTS[chainId]);
};
