import styled from 'styled-components';

export const StyledModalOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 10;
`;

interface IStyledModalWrapper {
  width?: string;
  height?: string;
  maxWidth?: string;
}
export const StyledModalWrapper = styled.div<IStyledModalWrapper>`
  width: ${props => (!props.width ? '570px' : props.width + 'px')};
  height: ${props => (!props.height ? '504px' : props.height + 'px')};
  max-width: ${props => (!props.width ? 'inherit' : props.maxWidth + 'px')};
`;

export const StyledModal = styled.div`
  position: relative;
  border-radius: 1rem;
  border-width: 1px;

  background-color: #252525;
  width: 100%;
  height: 100%;
`;

export const StyledBorderLogo = styled.div`
  position: absolute;
  margin-top: -13.5px;
  display: inline-block;
  background: transparent;

  ::after,
  ::before {
    content: ' ';
    position: absolute;
    top: 12.5px;
    width: 5px;
    height: 0;
    overflow: hidden;
    border-top: 1px solid #252525;
    border-bottom: 1px solid #252525;
  }
  ::after {
    left: -5px;
  }
  ::before {
    right: -5px;
  }
`;

export const StyledModalHeader = styled.div`
  position: absolute;
  right: 0;
  height: 46px;
  width: 46px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
`;

interface IStyledModalTitle {
  color: string;
}
export const StyledModalTitle = styled.div<IStyledModalTitle>`
  width: 100%;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: ${props => props.color};
`;

export const StyledModalBody = styled.div`
  border-radius: 1rem;
`;
