import React from 'react';

import LandSidebar from './land';
import { TransportSidebar } from './transport';
import { AvatarSidebar } from './avatar';
import { BonuspackSidebar } from './bonuspack';
import './index.scss';

export const DashboardSidebar = ({
  context,
}: {
  context: 'land' | 'transport' | 'avatar' | 'bonuspack';
}) => {
  return context === 'land' ? (
    <LandSidebar />
  ) : context === 'transport' ? (
    <TransportSidebar />
  ) : context === 'avatar' ? (
    <AvatarSidebar />
  ) : (
    <BonuspackSidebar />
  );
};
