import React from 'react';

import { ModalWrapper, ModalHeader, ModalBody, ModalFooter } from '../Modal';
import { FormButton } from '../Form';

import toast from 'react-hot-toast';
import { useChain, useUpdateChain } from 'context/chain/context';
import { mintMultiLands } from 'context/chain/contracts';
import { useLands, useUpdateLands } from 'context/land/context';

import './index.scss';

export type IModalMintProps = {
  open: boolean;
  mintedIds: string[];
  onHide: () => void | undefined;
};

export const LandMintPreviewModal: React.FC<IModalMintProps> = ({
  open,
  mintedIds,
  onHide,
}: IModalMintProps) => {
  const { totalList, ParcelMinterContract } = useChain();
  const { selectedLands } = useLands();
  const { dispatch } = useUpdateLands();
  const { dispatch: dispatchChain } = useUpdateChain();

  const [loading, setLoading] = React.useState(false);
  const [minted, setMinted] = React.useState(false);

  const listedLandCount = (selectedLands && selectedLands.length) || 0;

  const handleClose = () => {
    dispatch(state => ({
      ...state,
      selectedLands: [],
    }));
    onHide();
  };

  const handleMint = async () => {
    if (listedLandCount === 0) return;

    setLoading(true);
    try {
      const tokens = selectedLands.filter(
        token => !mintedIds.includes(token.id)
      );

      const tokenIds = tokens.map(l => `${l.id}`);
      const tokenWithProof = tokenIds.map(id => ({
        id: id,
        proof: selectedLands
          .filter(item => item.id === id)
          .map(item => item.proof)[0],
      }));

      await mintMultiLands(ParcelMinterContract, tokenWithProof);

      dispatchChain(state => {
        const { list } = state;
        const claimedLands = [...(state.claimedLands || []), ...tokens];
        const mintedLands = [...(state.mintedLands || []), ...tokens];
        const newList = Object.keys(list).reduce((value, landType) => {
          return {
            ...value,
            [landType]: (list[landType] || []).map(item => ({
              ...item,
              checked: tokenIds.includes(`${item.id}`) || item.checked,
              disabled: tokenIds.includes(`${item.id}`) || item.disabled,
            })),
          };
        }, {});

        return {
          ...state,
          list: newList,
          claimedLands,
          mintedLands,
        };
      });

      setMinted(true);
    } catch (error) {
      toast.error('Problem with minting the land.');
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setMinted(false);
  }, [open, setMinted]);

  return (
    <ModalWrapper open={open}>
      <>
        <ModalHeader onHide={onHide}>
          <div className='modal-header__description'>
            <h5>
              {minted
                ? 'MINT REPORT'
                : loading
                ? 'OPENING WALLET'
                : 'MINT PREVIEW'}
            </h5>
            <p>
              {minted
                ? `Yay! You successfully minted new lands.`
                : loading
                ? ``
                : `You are about to mint ${listedLandCount} / ${totalList} items.`}
            </p>
          </div>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div className='mint-spinner'>
              <img alt='minting' src='/images/minting.gif' />
            </div>
          ) : (
            <ul>
              {selectedLands &&
                selectedLands
                  .filter(land => !mintedIds.includes(land.id))
                  .map(land => (
                    <li key={land.id} className='modal-row'>
                      <div className='modal-row__icon'>
                        <svg
                          width='11'
                          height='9'
                          viewBox='0 0 11 9'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.93902 6.21994L1.88289 4.1472L1 5.03723L3.93902 8L10 1.89003L9.11711 1L3.93902 6.21994Z'
                            fill={minted ? '#3DFF73' : '#C397F7'}
                            stroke={minted ? '#3DFF73' : '#C397F7'}
                          />
                        </svg>
                      </div>
                      <div
                        className='modal-row__text'
                        style={{ color: minted ? '#3DFF73' : '#fff' }}
                      >
                        {land.label}
                      </div>
                    </li>
                  ))}
            </ul>
          )}
        </ModalBody>
        <ModalFooter>
          <>
            {/* {unListedLandCount > 0 && (
              <div className='modal-footer__description'>
                <p>You have {unListedLandCount} un-minted items.</p>
                <p>Would you like to proceed anyway?</p>
              </div>
            )} */}
            {minted ? (
              <div>
                <FormButton label='CLOSE' onClick={handleClose} />
              </div>
            ) : (
              <div className='modal-footer__actions'>
                <FormButton label='CLOSE' onClick={onHide} />
                <FormButton
                  label='MINT NOW'
                  loading={loading}
                  disabled={listedLandCount === 0}
                  onClick={handleMint}
                />
              </div>
            )}
          </>
        </ModalFooter>
      </>
    </ModalWrapper>
  );
};
