import { useEffect, useRef } from 'react';
import MetaMaskOnboarding from '@metamask/onboarding';
import { injected } from '../../utils/web3React';
import useAccountLibrary from '../../hooks/useActiveWeb3';

type Props = {
  onConnect: () => void;
  children: JSX.Element;
};

const MetamaskConnect = ({ onConnect, children }: Props) => {
  const { active, error, activate, account } = useAccountLibrary();

  const onboarding = useRef<MetaMaskOnboarding>();
  useEffect(() => {
    onboarding.current = new MetaMaskOnboarding();
  }, []);

  useEffect(() => {
    if (active || error) {
      onboarding.current?.stopOnboarding();
    }
  }, [active, error]);

  const onConnectMetamask = () => {
    onConnect();
    activate(injected);
  };
  const installMetamask = () => onboarding.current?.startOnboarding();

  if (typeof account !== 'string') {
    const hasMetaMaskOrWeb3Available =
      MetaMaskOnboarding.isMetaMaskInstalled() ||
      (window as any)?.ethereum ||
      (window as any)?.web3;

    return (
      <div className='w-full'>
        {hasMetaMaskOrWeb3Available ? (
          <div className='w-full' onClick={onConnectMetamask}>
            {children}
          </div>
        ) : (
          <div onClick={installMetamask}>{children}</div>
        )}
      </div>
    );
  }

  return null;
};

export default MetamaskConnect;
