import React from 'react';

import Router from './router';

function App() {
  return (
    <React.Fragment>
      <Router />
    </React.Fragment>
  );
}

export default React.memo(App);
