import React, { createContext, useContext, useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'bignumber.js';
import toast from 'react-hot-toast';

import { ActiveChainId } from 'config/constants';
import { useAuth } from 'state/user/hooks';

import {
  RootLand,
  ParcelMinter,
  RootTransport,
  TransportMinter,
  RootAvatar,
  AvatarMinter,
  RootBonusPack,
  BonusPackMinter,
  fetchLandsFromMock,
  IChainInitialState,
  IUpdateChainInitialState,
} from 'context/chain/contracts';

import { CONNECTOR_LOCAL_KEY, ConnectorNames } from 'config/constants';
import { injected, walletconnect } from 'utils/web3React';
import { fetchMapData, mapGroupData, mapGroups } from 'utils/map';
import { fetchNFTData, groupNFTData } from 'utils/nfts';
import { fetchPersonalClaimedLands } from 'services';

const getMintedLandIds = async (landIds, minterContract) => {
  const availableLandIds = [],
    mintedLandIds = [];

  for (const landId of landIds) {
    const isMinted = await minterContract.isMinted(landId);

    if (isMinted) {
      mintedLandIds.push(`${landId}`);
    } else {
      availableLandIds.push(`${landId}`);
    }
  }

  return [availableLandIds, mintedLandIds];
};

const getClaimedLands = (lands, availableLandIds) => {
  return lands.filter(({ tokenId }) => !availableLandIds.includes(tokenId));
};

const getMintedLands = (lands, mintedLandIds) => {
  return lands.filter(({ tokenId }) => mintedLandIds.includes(tokenId));
};

export const ChainInitialState: IChainInitialState = {
  RootLandContract: null,
  ParcelMinterContract: null,
  RootTransportContract: null,
  TransportMinterContract: null,
  RootAvatarContract: null,
  AvatarMinterContract: null,
  RootBonusPackContract: null,
  BonusPackMinterContract: null,

  parcels: {
    Mega_Land: new BigNumber(0),
    Giant_Land: new BigNumber(0),
    Large_Land: new BigNumber(0),
    Medium_Land: new BigNumber(0),
    Standard_Land: new BigNumber(0),
  },
  lands: [],
  myLands: [],
  claimedLands: [],
  mintedLands: [],
  landTypes: [],
  groups: [],
  list: [],
  totalList: 0,
  transports: {
    all: [],
    grouped: {},
    owned: [],
  },
  avatars: {
    all: [],
    grouped: {},
    owned: [],
  },
  bonuspacks: {
    all: [],
    grouped: {},
    owned: [],
  },
  isLoaded: false,
  firebaseAuthState: false,
};

export const UpdateChainInitialState: IUpdateChainInitialState = {};

export const ChainContext = createContext(ChainInitialState);
export const useChain = () => useContext(ChainContext);

export const UpdateChainContext = createContext(UpdateChainInitialState);
export const useUpdateChain = () => useContext(UpdateChainContext);

export default ({ children }) => {
  const { loggedIn } = useAuth();
  const { chainId, library, active, account, error, activate, deactivate } =
    useWeb3React();

  // Context value
  const [value, dispatch] = useState(ChainInitialState);

  const selectLand = (item, checked) => {
    dispatch(v => {
      const { list } = v;

      const index = list[item.landType].findIndex(l => l.id === item.id);
      if (index > -1) {
        list[item.landType][index].checked = checked;

        const classnames = document
          .getElementById(item.label)
          .getAttribute('class');
        if (checked && !classnames.includes('activated')) {
          document
            .getElementById(item.label)
            .setAttribute('class', `${classnames} activated`);
        }

        if (!checked && classnames.includes('activated')) {
          const classList = classnames.split(' ');
          const index = classList.indexOf('activated');

          classList.splice(index, 1);
          document
            .getElementById(item.label)
            .setAttribute('class', classList.join(' '));
        }

        return {
          ...v,
          list,
        };
      }

      return { ...v };
    });
  };

  // Contract Instance Creation
  useEffect(() => {
    const contextInitializer = async () => {
      if (chainId !== ActiveChainId) {
        toast.error('Wrong network');
        return;
      }

      const loadData = new Promise(async (resolve, reject) => {
        try {
          const RootLandContract = RootLand(library, chainId);
          const ParcelMinterContract = ParcelMinter(library, chainId);
          const RootTransportContract = RootTransport(library, chainId);
          const TransportMinterContract = TransportMinter(library, chainId);
          const RootAvatarContract = RootAvatar(library, chainId);
          const AvatarMinterContract = AvatarMinter(library, chainId);
          const RootBonusPackContract = RootBonusPack(library, chainId);
          const BonusPackMinterContract = BonusPackMinter(library, chainId);

          const nftData = await fetchNFTData(account);

          const myLands = await fetchPersonalClaimedLands(chainId, account);
          const lands = await fetchMapData();
          const landTypes = mapGroupData(lands);
          const groups = mapGroups(lands);

          const allocatedLandIds = nftData.lands.map(d => d.tokenId);
          const [availableLandIds, mintedLandIds] = await getMintedLandIds(
            allocatedLandIds,
            ParcelMinterContract
          );

          const claimedLands = getClaimedLands(lands, availableLandIds);
          const mintedLands = getMintedLands(lands, mintedLandIds);

          const parcels = fetchLandsFromMock(availableLandIds, groups);
          let total = 0;
          const data = landTypes.reduce((val, landType) => {
            val[landType.name] =
              (groups &&
                groups[landType.name] &&
                groups[landType.name]
                  .filter(group =>
                    allocatedLandIds.includes(`${group.tokenId}`)
                  )
                  .map(group => ({
                    id: group.tokenId,
                    label: group.id,
                    landType: landType.name,
                    textColor: '#fff',
                    checked: mintedLandIds.includes(group.tokenId),
                    disabled: mintedLandIds.includes(group.tokenId),
                    proof: nftData.lands
                      .filter(d => d.tokenId === group.tokenId)
                      .map(d => d.proof)[0],
                  }))) ||
              [];

            total += val[landType.name].length;

            return val;
          }, {});

          const transports = nftData.transports;
          const transportsGroupBy = groupNFTData('transport', transports);
          const avatars = nftData.avatars;
          const avatarsGroupBy = groupNFTData('avatar', avatars);
          const bonuspacks = nftData.bonuspacks;
          const bonuspacksGroupBy = groupNFTData('bonuspack', bonuspacks);

          const myTransports = (
            await Promise.all(
              transports.map(async d => {
                const isMinted = await TransportMinterContract.isMinted(
                  d.tokenId
                );
                return isMinted ? d.tokenId : null;
              })
            )
          ).filter(id => id);
          const myAvatars = (
            await Promise.all(
              avatars.map(async d => {
                const isMinted = await AvatarMinterContract.isMinted(d.tokenId);
                return isMinted ? d.tokenId : null;
              })
            )
          ).filter(id => id);
          const myBonuspacks = (
            await Promise.all(
              bonuspacks.map(async d => {
                const isMinted = await BonusPackMinterContract.isMinted(
                  d.tokenId
                );
                return isMinted ? d.tokenId : null;
              })
            )
          ).filter(id => id);

          dispatch({
            RootLandContract,
            ParcelMinterContract,
            RootTransportContract,
            TransportMinterContract,
            RootAvatarContract,
            AvatarMinterContract,
            RootBonusPackContract,
            BonusPackMinterContract,
            parcels,
            lands,
            myLands,
            claimedLands,
            mintedLands,
            groups,
            landTypes,
            list: data,
            totalList: total,
            transports: {
              all: transports,
              grouped: transportsGroupBy,
              owned: myTransports,
            },
            avatars: {
              all: avatars,
              grouped: avatarsGroupBy,
              owned: myAvatars,
            },
            bonuspacks: {
              all: bonuspacks,
              grouped: bonuspacksGroupBy,
              owned: myBonuspacks,
            },
            isLoaded: true,
            firebaseAuthState: true,
          });

          resolve(true);
        } catch (e) {
          console.log('ERROR INITIALIZING CONTEXT', e);
          reject(e);
        }
      });

      toast.promise(loadData, {
        loading: 'Loading Data',
        success: 'Got the data',
        error: 'Error during load.',
      });
    };

    if (active) {
      contextInitializer();
    } else {
      dispatch(ChainInitialState);
    }
  }, [active, account, library, chainId, dispatch]);

  // Wallet Connection
  useEffect(() => {
    const connector = window.localStorage.getItem(CONNECTOR_LOCAL_KEY);
    if ((!connector && active) || !loggedIn) {
      deactivate();
    }

    if (!connector || (connector && active) || !loggedIn) return;

    switch (connector) {
      case ConnectorNames.Injected:
        activate(injected);
        break;

      case ConnectorNames.WalletConnect:
        activate(walletconnect);
        break;
    }
  }, [error, active, activate, deactivate, loggedIn]);

  return (
    <UpdateChainContext.Provider value={{ dispatch, selectLand }}>
      <ChainContext.Provider value={value}>{children}</ChainContext.Provider>
    </UpdateChainContext.Provider>
  );
};
