import React, { useState } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { CONNECTOR_LOCAL_KEY } from 'config/constants';

import { useMe } from 'state/user/hooks';
import { shortenAddress } from 'utils/helper';
import UserMenu from './UserMenu';

import Wallet from './wallet';
import WalletOptionsModal from 'components/WalletOptionsModal';

import './styles.scss';

const menuItems = [
  {
    id: 'land',
    path: '/land',
    label: 'Mint Map',
  },
  // {
  //   id: 'lands',
  //   path: '/lands',
  //   label: 'Lands',
  // },
  {
    id: 'transports',
    path: '/transports',
    label: 'Transports',
  },
  {
    id: 'avatars',
    path: '/avatars',
    label: 'Avatars',
  },
  {
    id: 'bonus-packs',
    path: '/bonus-packs',
    label: 'Bonus Packs',
  },
];

const Header = ({ onShowSidebar }) => {
  const history = useHistory();
  const [walletModalVisibility, showWalletModal] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split('/')[1];

  const { account } = useMe();
  const { active, account: wallet, deactivate } = useWeb3React();

  const handleShowWalletModal = (flag: boolean) => {
    showWalletModal(flag);
  };

  const toggleMenu = () => setMenuOpened(!menuOpened);

  const handleWallet = () => {
    if (active) {
      showWalletModal(false);
      deactivate();
      window.localStorage.removeItem(CONNECTOR_LOCAL_KEY);
    } else {
      history.push('/wallet');
      setMenuOpened(false);
    }
  };

  return (
    <>
      <WalletOptionsModal
        isVisible={walletModalVisibility}
        onClose={() => handleShowWalletModal(false)}
      />
      <header className='sticky top-0 z-50 py-1 bg-dark font-teko'>
        <div
          className='px-4 border-t border-b sm:px-6 lg:px-8'
          style={{ borderColor: '#38333E' }}
        >
          <div className='flex h-16 -mb-px'>
            <div
              className='flex items-center justify-center w-auto pr-4 bg-right bg-no-repeat'
              style={{ backgroundImage: 'url(/images/menu-left.png)' }}
            >
              <Link className='max-h-10' to='/'>
                <img src='/images/logo.svg' alt='logo' />
              </Link>
            </div>
            <div className='header-body'>
              {/* <h1>The NFTs are locked for 1 month</h1> */}
            </div>
            <div
              className='flex items-center pl-4 bg-left bg-no-repeat bg-none no-bg-mobile'
              style={{ backgroundImage: 'url(/images/menu-right.png)' }}
            >
              {/* <Notifications /> */}
              <div className='relative font-play inline-flex mr-3 mobile:hidden'>
                <a
                  href='https://guide.netvrk.co/'
                  className='w-8 h-8 flex items-center justify-center transition duration-150 rounded-full'
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <img src='/images/help.svg' alt='Help' />
                </a>
              </div>
              <UserMenu />
              <Wallet showModal={handleShowWalletModal} />
              <div className='relative mobileUp:hidden'>
                {menuOpened ? (
                  <img
                    src='/images/close.svg'
                    onClick={toggleMenu}
                    alt='Menu'
                  />
                ) : (
                  <img
                    src='/images/hamburger.svg'
                    onClick={toggleMenu}
                    alt='Menu'
                  />
                )}

                {menuOpened && (
                  <div
                    className='fixed left-0 right-0 bottom-0 z-60 bg-black text-white flex flex-col transform transition-transform duration-200 ease-in-out translate-x-0'
                    style={{ top: 82 }}
                  >
                    <div className='relative border-0 border-b border-third py-4'>
                      <img
                        className='w-20 mx-auto'
                        src='/images/user.svg'
                        alt='User'
                      />
                      <p className='text-center text-3xl my-3'>
                        {account.username}
                      </p>
                      <div
                        className='absolute flex bg-black py-1 px-4 rounded-full border border-third wallet-address items-center'
                        onClick={handleWallet}
                      >
                        <img
                          src='/images/wallet.svg'
                          className='mr-4'
                          alt='Wallet'
                        />
                        <div className='text-23px w-32'>
                          {active ? shortenAddress(wallet) : 'Connect Wallet'}
                        </div>
                      </div>
                    </div>
                    <div className='flex-1 bg-dark flex flex-col items-center justify-between py-10'>
                      {menuItems.map(({ id, path, label }) => (
                        <div
                          className={`px-4 pt-2 text-center my-1 w-64 flex justify-center ${
                            page === id
                              ? 'border border-r-0 border-third rounded-full bg-background'
                              : ''
                          }`}
                          key={id}
                        >
                          <NavLink
                            exact
                            to={path}
                            activeClassName='active-menu-item'
                            className={`flex items-center text-5xl transition duration-150 uppercase ${
                              page === id
                                ? 'text-white'
                                : 'text-menuLightPurple'
                            }`}
                          >
                            {label}
                          </NavLink>
                        </div>
                      ))}
                      <a
                        href='https://guide.netvrk.co/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className='w-14'
                          src='/images/help.svg'
                          alt='Help'
                        />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
