export const ENV = process.env.REACT_APP_ENVIRONMENT || 'production';
export const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_DATA_DECRYPTION_KEY =
  process.env.REACT_APP_DATA_DECRYPTION_KEY;

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
}

export const ActiveChainId =
  ENV === 'production' ? ChainId.MAINNET : ChainId.RINKEBY;

export const NetworkContextName = 'NETWORK';

export const RPC = {
  [ChainId.MAINNET]: `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  [ChainId.ROPSTEN]: `https://eth-ropsten.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  [ChainId.RINKEBY]: `https://eth-rinkeby.alchemyapi.io/v2/${ALCHEMY_KEY}`,
};

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
}

export const CONNECTOR_LOCAL_KEY = 'netvrk_wallet_connector_v1';
