import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AppState, useAppDispatch } from '..';
import { updateUserDetails, signOutUser } from './actions';
import { IUser } from '../types';

export const useMe = () => {
  const { account, profile } = useSelector<AppState, AppState['user']>(
    state => state.user
  );

  return { account, profile };
};

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const { account } = useSelector<AppState, AppState['user']>(
    state => state.user
  );

  const signOut = useCallback(() => {
    dispatch(signOutUser());
  }, [dispatch]);

  return {
    loggedIn: !!account.uid,
    isVerified: !!account.isVerified,
    signOut,
  };
};

export const useUpdateUser = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (user: IUser) => {
      dispatch(updateUserDetails(user));
    },
    [dispatch]
  );
};
