import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  StyledModalOverlay,
  StyledModalWrapper,
  StyledModal,
  StyledModalHeader,
  StyledModalTitle,
  StyledModalBody,
} from './styles';
import { IModal, defaultModalOptions } from './interface';
import useWindowSize from '../../hooks/useWindowSize';

const Modal = ({
  show,
  onClose,
  children,
  title,
  ModalOptions = defaultModalOptions,
}: IModal) => {
  const [isBrowser, setIsBrowser] = useState(false);
  // const modalWrapperRef = useRef();
  const modalOptions = { ...defaultModalOptions, ...ModalOptions };
  const { width } = useWindowSize();
  if (+modalOptions.width >= width) {
    modalOptions.width = (width * 90) / 100 + '';
  }

  // const backDropHandler = (e) => {
  //   if (!show) return;
  //   if (!modalWrapperRef?.current?.contains(e.target)) {
  //     console.log('what');
  //     onClose();
  //   }
  // };

  useEffect(() => {
    setIsBrowser(true);
    // window.addEventListener('click', backDropHandler);
    // return () => window.removeEventListener('click', backDropHandler);
  }, []);

  const handleCloseClick = e => {
    e.preventDefault();
    onClose();
  };

  const modalContent = show ? (
    <StyledModalOverlay
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
        zIndex: 1000000,
      }}
    >
      <StyledModalWrapper
        // ref={modalWrapperRef}
        width={modalOptions.width}
        height={modalOptions.height}
        maxWidth={modalOptions.maxWidth}
      >
        <StyledModal>
          <div className='w-full flex justify-center'>
            {/* <StyledBorderLogo>
              <img src='./images/tokemak_logo.svg' width={45} alt='MM' />
            </StyledBorderLogo> */}
          </div>
          {onClose && (
            <StyledModalHeader onClick={handleCloseClick}>x</StyledModalHeader>
          )}
          <StyledModalTitle color={modalOptions.titleColor}>
            {title}
          </StyledModalTitle>
          <StyledModalBody>{children}</StyledModalBody>
        </StyledModal>
      </StyledModalWrapper>
    </StyledModalOverlay>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal-root')
    );
  } else {
    return null;
  }
};

export default Modal;
