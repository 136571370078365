import React from 'react';
import ReactDOM from 'react-dom';
import Providers from './Providers';
import './styles.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
      <div id='modal-root'></div>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);
