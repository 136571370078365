import Header from 'components/header';
import './index.scss';

export const HomeLayout = ({ noHeader = false, children }) => {
  return (
    <>
      {!noHeader && <Header />}
      <div className='bg-black'>
        <div className='container max-w-7xl mx-auto'>{children}</div>
      </div>
    </>
  );
};
