import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';

import { useChain } from 'context/chain/context';
import { Layout, DashboardSidebar } from 'components/Atoms';
import SubHeader from './SubHeader';
import Card from './Card';
import { BonuspacksWrapper } from './styles';

const BonuspacksDashboardMain = () => {
  const [myBonuspackIds, setMyBonuspackIds] = useState([]);
  const [myBonuspackImages, setMyBonuspackImages] = useState({});
  const [filteredBonuspackIds, setFilteredBonuspackIds] = useState([]);
  const [pageReady, setPageReady] = useState(false);

  const {
    bonuspacks: { all: allBonuspacks, owned: myBonuspacks },
    RootBonusPackContract,
  } = useChain();

  const [viewMode, setViewMode] = useState(1);

  useEffect(() => {
    setMyBonuspackIds(myBonuspacks);
    setFilteredBonuspackIds(myBonuspacks);
    setPageReady(true);
  }, [myBonuspacks]);

  useEffect(() => {
    const fetchImages = async function () {
      const images = {};
      try {
        await Promise.all(
          myBonuspackIds.map(async tokenId => {
            const tokenURI = await RootBonusPackContract.tokenURI(tokenId);
            const {
              data: { image },
            } = await axios.get(tokenURI);
            images[tokenId] = image;
          })
        );
      } catch (e) {}
      setMyBonuspackImages(images);
    };
    RootBonusPackContract && fetchImages();
  }, [myBonuspackIds, RootBonusPackContract]);

  const onFilter = useCallback(
    (type: string) => {
      if (!myBonuspackIds.length) return;
      if (type === 'All Bonuspack Types') {
        setFilteredBonuspackIds(myBonuspackIds);
        return;
      }
      const filteredIds = myBonuspackIds.filter(tokenId => {
        const bonuspack = allBonuspacks.find(d => d.tokenId === tokenId);
        return bonuspack && bonuspack.type === type;
      });

      setFilteredBonuspackIds(filteredIds);
    },
    [allBonuspacks, myBonuspackIds]
  );

  return (
    <Layout>
      <DashboardSidebar context='bonuspack' />
      <div className='flex w-full px-6'>
        <BonuspacksWrapper>
          {pageReady && (
            <div className='w-full px-2 sm:px-6 mt-2 sm:mt-6 flex flex-col'>
              <h1 className='primary-heading'>Bonus Packs</h1>
              <p className='sub-heading'>
                Successfully minted bonuspacks will appear here.
              </p>
              <SubHeader
                viewMode={viewMode}
                setViewMode={setViewMode}
                onFilter={onFilter}
              />
              <div
                className={cx(
                  'mt-6 pb-4',
                  viewMode === 1 ? 'grid-view' : 'row-view'
                )}
              >
                {filteredBonuspackIds.map(tokenId => (
                  <div
                    key={tokenId}
                    className={cx(
                      viewMode === 1 ? 'h-220 tabletUp:h-320' : 'h-420',
                      ' flex items-center'
                    )}
                  >
                    <Card
                      image={myBonuspackImages[tokenId] || null}
                      tokenId={tokenId}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </BonuspacksWrapper>
      </div>
    </Layout>
  );
};

export default BonuspacksDashboardMain;
