/* eslint-disable no-useless-escape */
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  User as FirebaseUser,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  addDoc,
  deleteDoc,
  getDocs,
  setDoc,
  query,
  where,
} from 'firebase/firestore/lite';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import firebaseConfig from 'config/firebase';
import { randomEmail } from 'utils/helper';
import { ILand } from 'state/types';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export const register = async (username, email, password, wallet) => {
  email = email || randomEmail();
  const q1 = query(collection(db, 'users'), where('email', '==', email));
  let querySnapshot = await getDocs(q1);

  if (querySnapshot.size) {
    throw new Error('Email already exists');
  }
  const q2 = query(collection(db, 'users'), where('username', '==', username));
  querySnapshot = await getDocs(q2);
  if (querySnapshot.size) {
    throw new Error('Username already exists');
  }
  const res = await createUserWithEmailAndPassword(auth, email, password);
  const user = res.user;
  await addDoc(collection(db, 'users'), {
    uid: user.uid,
    email,
    username,
    wallet,
  });
  await sendEmailVerification(res.user);
};

export const login = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const validateEmail = email =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const loginWithUsername = async (username, password) => {
  const field = validateEmail(username) ? 'email' : 'username';
  const q = query(collection(db, 'users'), where(field, '==', username));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    throw new Error('User does not exist');
  }
  const profile = querySnapshot.docs[0].data();

  await login(profile.email, password);
};

export const me = async () => {
  // Assumes firebase auth is available
  const user = auth.currentUser;
  const q = query(collection(db, 'users'), where('uid', '==', user.uid));
  const querySnapshot = await getDocs(q);
  const extendedUser: {
    user: FirebaseUser;
    data: any;
  } = { user, data: {} };

  if (querySnapshot.size) {
    const data = querySnapshot.docs[0].data();
    extendedUser.data = { ...data };
  }

  return extendedUser;
};

export const updateUserEmail = async newEmail => {
  const user = auth.currentUser;
  if (!user) {
    await updateEmail(user, newEmail);
  }
};

export const updateUserPassword = async newPassword => {
  const user = auth.currentUser;
  if (!user) {
    await updatePassword(user, newPassword);
  }
};

export const updateUserProfile = async data => {
  const user = auth.currentUser;
  const q = query(collection(db, 'users'), where('uid', '==', user.uid));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size) {
    const profileData = querySnapshot.docs[0].data();
    const docRef = querySnapshot.docs[0].ref;
    const updatedProfileData = { ...profileData, ...data };
    await setDoc(docRef, updatedProfileData);
  }
};

export const userLands = async (uid: string) => {
  // Assumes the user is already loggedin
  const q = query(collection(db, 'lands'), where('uid', '==', uid));
  const querySnapshot = await getDocs(q);

  // const lands = await Promise.all(
  //   querySnapshot.docs.map(
  //     landDoc =>
  //       new Promise(async (resolve, reject) => {
  //         const imageRef = ref(storage, landDoc.data().image);
  //         const land = await getDownloadURL(imageRef).then(imageUrl => ({
  //           image: imageUrl,
  //           landId: landDoc.data().landId,
  //         }));
  //         resolve(land);
  //       })
  //   )
  // );
  const lands: ILand[] = querySnapshot.docs.map(landDoc => ({
    image: landDoc.data().image,
    landId: landDoc.data().landId,
  }));

  return lands;
};

export const uploadLandImage = async (params: {
  uid: string;
  landId: string;
  landType?: string;
  landWidth?: number;
  landHeight?: number;
  image: File;
}) => {
  const { uid, landId, image, landType, landWidth, landHeight } = params;
  // Upload image to cloud storage
  const fileExt = image.name.split('.').pop();
  const storageRef = ref(storage, `lands/${landId}.${fileExt}`);
  await uploadBytes(storageRef, image);
  const imageUrl = await getDownloadURL(storageRef);

  // Delete existing land in firestore
  await deleteLandImage(landId);
  // Save image path in firestore
  await addDoc(collection(db, 'lands'), {
    uid,
    landId,
    image: imageUrl,
    type: landType || '',
    width: landWidth || 0,
    height: landHeight || 0,
  });

  return imageUrl;
};

export const deleteLandImage = async (landId: string) => {
  const q = query(collection(db, 'lands'), where('landId', '==', landId));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size) {
    const docRef = querySnapshot.docs[0].ref;
    await deleteDoc(docRef);
  }
};

export const sendResetEmail = async email => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error(error.message);
  }
};
