/* eslint-disable react-hooks/exhaustive-deps */
import { LandTooltip, Map } from 'components/Atoms';
import { HomeLayout } from 'components/Atoms/HomeLayout';
import { useChain } from 'context/chain/context';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'state/user/hooks';
import { mapInitializer } from 'utils/map';
import './index.scss';

export default () => {
  const { loggedIn } = useAuth();
  const history = useHistory();

  const { lands, claimedLands, landTypes, isLoaded } = useChain();
  const [isMapInitialized, setMapInitialized] = useState(false);
  // Initialize map
  useEffect(() => {
    const initializeMap = () => {
      console.log('initializeMap', lands, landTypes, claimedLands);
      mapInitializer(lands, landTypes, claimedLands);
      setTimeout(() => {
        setMapInitialized(true);
      }, 1000 * 2);
    };

    !isMapInitialized && initializeMap();
  }, [
    lands,
    claimedLands,
    landTypes,
    isLoaded,
    isMapInitialized,
    setMapInitialized,
  ]);

  useEffect(() => {
    if (loggedIn) {
      history.push('/land');
    }
  }, [loggedIn]);

  return (
    <HomeLayout>
      <Helmet>
        <title>Netvrk - Explore metaverse</title>
      </Helmet>
      <div className='grid grid-cols-1 my-16 items-center'>
        <div className='col-span-1'>
          <div className='flex justify-center items-center mx-16 mb-16'>
            <div className='space-y-4 bg-gray-800 p-6 rounded-xl mt-8'>
              <p className='text-white homepage-info'>
                Login and Connect your wallet to mint land and other Netvrk
                assets.
              </p>
              <a
                className='homepage-btn btn btn-blue bg-gradient-blue px-4 py-2  rounded-full text-white uppercase mx-auto'
                href='/login'
              >
                LOGIN AND CONNECT WALLET
              </a>
            </div>
          </div>
        </div>
        <div className='col-span-1'>
          <p className='text-white homepage-info mb-4 text-center md:text-left'>
            Preview the Land of Netvrk's Metaverse
          </p>
          <div className='homepage-map_container'>
            <Map />
            <LandTooltip />
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};
