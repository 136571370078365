import cx from 'classnames';

export default ({ selected, onClick }) => {
  return (
    <div
      className={cx(
        selected ? 'opacity-30' : '',
        'hover:opacity-30 cursor-pointer'
      )}
      onClick={onClick}
    >
      <svg
        width='23'
        height='25'
        viewBox='0 0 23 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='23' height='11' fill='white' />
        <rect y='14' width='23' height='11' fill='white' />
      </svg>
    </div>
  );
};
