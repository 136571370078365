import React from 'react';
import { Helmet } from 'react-helmet';

import { Layout, DashboardSidebar } from 'components/Atoms';
import Land from 'components/Dashboard/Land';

export default () => {
  return (
    <Layout>
      <>
        <Helmet>
          <title>Netvrk - Dashboard</title>
        </Helmet>
        <DashboardSidebar context='land' />
        <Land />
      </>
    </Layout>
  );
};
