import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

type DropdownMenuProps = {
  items: string[];
  onClick: (item: string) => void;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  onClick,
}: DropdownMenuProps) => {
  const [selected, setSelected] = useState(items[0]);
  const handleClick = item => {
    setSelected(item);
    onClick(item);
  };
  return (
    <Menu as='div' className='relative inline-block text-left font-teko'>
      <div>
        <Menu.Button className='inline-flex justify-between items-center w-full px-4 py-2 text-23px font-thin text-white uppercase tracking-widest leading-none bg-black rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 min-w-180 border border-dropdown'>
          {selected}
          <ChevronDownIcon
            className='w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100'
            aria-hidden='true'
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute z-10 left-0 w-56 mt-2 origin-top-right bg-black divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border border-dropdown'>
          <div className='px-1 py-1 '>
            {items.map((item, index) => (
              <Menu.Item key={index} onClick={() => handleClick(item)}>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary' : ''
                    } group flex rounded-md items-center w-full px-2 py-2 text-white text-md font-thin uppercase tracking-widest leading-none`}
                  >
                    {item}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
