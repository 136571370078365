import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AppState, useAppDispatch } from '..';
import { setLands as _setLands, updateLand as _updateLand } from './actions';
import { ILand } from '../types';

export const useLands = () => {
  const dispatch = useAppDispatch();
  const { lands } = useSelector<AppState, AppState['lands']>(
    state => state.lands
  );

  const setLands = useCallback(
    (lands: ILand[]) => dispatch(_setLands(lands)),
    [dispatch]
  );

  const updateLand = useCallback(
    (land: ILand) => dispatch(_updateLand(land)),
    [dispatch]
  );

  return { lands, setLands, updateLand };
};
