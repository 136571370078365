import React from 'react';

import './index.scss';
import Header from 'components/header';

export const AuthLayout = ({ title, noHeader = false, children }) => {
  return (
    <>
      {!noHeader && <Header />}
      <div className='box-wrapper'>
        <div className='box-container'>
          <div className='box-container__header'>
            <h1>{title}</h1>
          </div>
          <div className='box-container__content'>
            <div className='box-container__container'>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
