import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { useAuth } from 'state/user/hooks';

interface PrivateRouteProps extends RouteProps {
  component: any;
  requireWallet?: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, requireWallet, ...rest } = props;
  const { loggedIn, isVerified } = useAuth();
  const { active } = useWeb3React();

  if (!loggedIn || !isVerified)
    return (
      <Route
        {...rest}
        render={routeProps => (
          <Redirect
            to={{ pathname: '/login', state: { from: routeProps.location } }}
          />
        )}
      />
    );

  if (!active && requireWallet)
    return (
      <Route
        {...rest}
        render={routeProps => (
          <Redirect
            to={{ pathname: '/wallet', state: { from: routeProps.location } }}
          />
        )}
      />
    );

  return (
    <Route {...rest} render={routeProps => <Component {...routeProps} />} />
  );
};

export default PrivateRoute;
