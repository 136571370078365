import React from 'react';
import cx from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import './index.scss';

export type FormInputProps = {
  name?: string;
  type?: string;
  value?: string;
  placeholder?: string;
  validation?: boolean;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onEnter?: () => void;
};

export type FormLinkProps = {
  link?: string;
  label?: string;
};

export type FormButtonProps = {
  id?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string | undefined | null;
  onClick?: () => void;
};

export type FormRowProps = {
  link?: string;
  label?: string;
};

export type FormCheckProps = {
  label?: string;
  checked: boolean;
  color?: string;
  textColor?: string;
  variant?:
    | 'orange'
    | 'purple'
    | 'lightPurple'
    | 'grey'
    | 'blue'
    | 'green'
    | 'yellow';
  disabled?: boolean;
  onClick: (checked: boolean) => void;
};

export const FormInput: React.FC<FormInputProps> = ({
  name,
  type,
  value,
  placeholder,
  validation,
  onChange,
  onBlur,
  onEnter,
}: FormInputProps) => {
  return (
    <div className='form-input'>
      {validation && (
        <p className='form-validation-error'>This field is required</p>
      )}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        className={cx({ 'validation--error': validation })}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={event => {
          event.key === 'Enter' && onEnter();
        }}
      />
    </div>
  );
};

export const FormLink: React.FC<FormLinkProps> = ({
  link,
  label,
}: FormLinkProps) => {
  return (
    <div className='form-link'>
      <Link to={link}>{label}</Link>
    </div>
  );
};

export const FormButton: React.FC<FormButtonProps> = ({
  id,
  label,
  loading = false,
  disabled = false,
  className,
  onClick,
}: FormButtonProps) => {
  return (
    <div
      className={cx('form-button', {
        ...(className
          ? {
              [className]: true,
            }
          : {}),
      })}
    >
      <button
        id={id}
        className={cx({ disabled: loading || disabled })}
        disabled={loading || disabled}
        onClick={onClick}
      >
        {loading && (
          <svg
            className='w-5 h-5 border-2 border-white border-solid rounded-full animate-spin'
            viewBox='0 0 24 24'
          />
        )}
        {label}
      </button>
    </div>
  );
};

export const FormRow: React.FC<FormRowProps> = ({
  link,
  label,
}: FormRowProps) => {
  const history = useHistory();

  const handleClick = () => {
    link && history.push(link);
  };

  return (
    <div className='form-row'>
      <a onClick={handleClick}>{label}</a>
    </div>
  );
};

export const FormCheck: React.FC<FormCheckProps> = ({
  label,
  checked,
  color,
  textColor,
  variant,
  disabled = false,
  onClick,
}: FormCheckProps) => {
  const className = cx('form-check', {
    ...(variant ? { [`text-${variant}`]: variant } : {}),
    ...(disabled ? { 'opacity-50': true, disabled: true } : {}),
    'form-check--checked': checked,
  });
  const svgStyle = {
    ...(color
      ? {
          fill: color,
          stroke: color,
        }
      : {}),
  };
  const labelStyle = {
    ...(textColor ? { color: textColor } : {}),
  };

  const handleClick = () => {
    !disabled && onClick && onClick(!checked);
  };

  return (
    <div className={className} onClick={handleClick}>
      {checked ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
        >
          <path
            d='M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm-5.041-15c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z'
            {...svgStyle}
          />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
        >
          <path
            d='M5 2c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z'
            {...svgStyle}
          />
        </svg>
      )}
      <span style={labelStyle}>{label}</span>
    </div>
  );
};
