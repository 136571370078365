import styled from 'styled-components';

export const BonuspacksWrapper = styled.div`
  width: 100%;
  .grid-view {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 15px;
    row-gap: 15px;
  }
  .row-view {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .detail {
    flex: 0 0 30px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 11px;
  }
  .thumbnail {
    flex: 0 0 calc(100% - 30px);
    background-color: #2f2b34;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: center;
    }
    .uploader {
      padding: 15px;
      .content {
        height: 100%;
        border: 2px dashed #4c4852;
      }
    }
    .image,
    .uploader {
      height: 100%;
      background-size: contain;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .add-image {
    color: #c397f7;
    font-family: Teko;
    text-decoration: underline;
    font-size: 24px;
  }
  @media (max-width: 1300px) {
    .grid-view {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  @media (max-width: 600px) {
    .grid-view {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .add-image {
      font-size: 18px;
    }
  }
`;

export const CardSettingModalWrapper = styled.div`
  .thumbnail {
    height: 233px;
    background-color: #2f2b34;
    margin-left: -60px;
    margin-right: -60px;
    .image {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .uploader {
      height: 100%;
      padding: 15px;
      .content {
        height: 100%;
        border: 2px dashed #4c4852;
      }
    }
  }
  .add-image {
    color: #c397f7;
    font-family: Teko;
    text-decoration: underline;
    font-size: 24px;
  }
`;
