import {
  BACKIMAGE_URLS,
  // MAP_PROPERTIES_TO_DISABLE,
  IMAGE_SIZE,
  MAP_COLORS,
  MAP_STROKERS,
  MAP_TOOLBAR_HEIGHT,
  MAP_TOOLBAR_WIDTH,
  ZOOM_DURATION,
} from 'config/map';
import * as d3 from 'd3';

export const fetchMapData = async () => {
  const result = [];
  try {
    const text = await fetch('./data/data.csv').then(response =>
      response.text()
    );

    const lines = text.split('\r').join('').split('\n');
    // for (let i = 0; i < lines.length; i++) {
    //   lines[i] = lines[i].replace(/\s/, '');
    // }

    const headers = lines[0].split(',');
    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const current = lines[i].split(',');
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = current[j];
      }

      const tInfo = current[0].split('_');

      result.push({
        tokenId: tInfo[tInfo.length - 1],
        ...obj,
      });
    }
  } catch (error) {
    console.error(error.message || error);
  }

  return result;
};

// -------------------------------------------------
// Map CSV data to groups

export const mapGroupData = items => {
  return (
    (items &&
      items
        .map(d => d.group2)
        .filter((v, idx, self) => self.indexOf(v) === idx)
        .filter(gn => !!gn)
        .map((gn, gnIdx) => ({
          name: gn,
          color: MAP_COLORS[gnIdx],
          stroke: MAP_STROKERS[gnIdx],
          visible: true,
        }))) ||
    []
  );
};

export const mapGroups = items => {
  const data = {};
  for (const item of items) {
    if (!data[item.group2]) {
      data[item.group2] = [];
    }

    data[item.group2].push(item);
  }

  return data;
};

export const mapInitializer = (itemsData, groups, lands = []) => {
  const svg = d3.select('#map');
  const zoomWrapper = d3.select('#map-zoom-wrapper');
  const mapContent = d3.select('#map-content');
  const mapBackground = d3.select('#map-background');
  const backgroundDarkness = d3.select('#map-background-darkness');

  const landTooltipEl = document.querySelector('#new-land-tooltip');
  const landTooltipContainerEl = document.querySelector(
    '#new-land-tooltip-container'
  );
  const landTooltipIconEl = document.querySelector('#new-land-tooltip-icon');
  const landTooltipLandEl = document.querySelector('#new-land-tooltip__land');

  let regionClicked = false;
  let selectedItemIdx;
  let selectedItemsIdxs = [];

  let selectedEl = null;

  function showClickedItemInfo(item) {
    // console.log('DEBUG-showClickedItemInfo', item);
    /*
    // Adding <p> to LandType groups
    //
    if (item) {
      const className = item.getAttribute('class');
      if (className && className.includes('disabled')) {
        return;
      }
      const element = clickedItemContentEl.querySelector(`#sland_${item.id}`);
      if (element) {
        clickedItemContentEl.removeChild(element);
        if (!clickedItemContentEl.innerHTML) {
          const el = document.createElement('p');
          el.id = 'sland_none_id';
          el.innerHTML = 'None';
          clickedItemContentEl.appendChild(el);

          mintItemBtn.setAttribute('class', 'disabled');
        }
      } else {
        const nonEl = clickedItemContentEl.querySelector('#sland_none_id');
        if (nonEl) {
          clickedItemContentEl.removeChild(nonEl);

          mintItemBtn.removeAttribute('class');
          mintItemBtn.removeAttribute('disabled');
        }

        const el = document.createElement('p');
        el.id = `sland_${item.id}`;
        el.innerHTML = item.id;
        el.style.color = item.getAttribute('fill');
        clickedItemContentEl.appendChild(el);
      }
    } else {
      clickedItemContentEl.innerHTML = '';
      mintItemBtn.setAttribute('disabled', '');
      mintItemBtn.setAttribute('class', 'disabled');
    }
    */
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // function clearGroupsSection() {
  // itemGroupsEl.innerHTML = '';
  // }

  // function isValid(v) {
  //   return (v === 0 || v) && v !== 'NaN' && !isNaN(v);
  // }

  // eslint-disable-next-line consistent-return
  function createRect(id, idx, group, x, y, w, h, meta) {
    const rect = mapContent.append('rect');
    rect
      .attr('id', id)
      .attr('data-item-idx', idx)
      .attr('class', 'item ' + group.name)
      .attr('x', x)
      .attr('y', y)
      .attr('width', w)
      .attr('height', h)
      .attr('fill', group.color)
      .attr('stroke', group.stroke);
    if (meta !== '') {
      rect.attr('transform', meta);
    }

    let isDisabled = false;

    // check if disabled by type
    // for (let i = 0; i < MAP_PROPERTIES_TO_DISABLE.group2.length; i++) {
    //   if (group.name === MAP_PROPERTIES_TO_DISABLE.group2[i]) {
    //     rect.classed('disabled', true);
    //     isDisabled = true;
    //     break;
    //   }
    // }

    if (!isDisabled) {
      // if still enabled, check by ID
      const lland = lands.find(land => `${land.tokenId}` === `${idx + 1}`);
      if (lland) {
        rect.classed('disabled', true);
        rect.attr('owner', lland.owner);
        isDisabled = true;
      }
      // for (let i = 0; i < MAP_PROPERTIES_TO_DISABLE.id.length; i++) {
      //   if (id === MAP_PROPERTIES_TO_DISABLE.id[i]) {
      //     rect.classed('disabled', true);
      //     isDisabled = true;
      //     break;
      //   }
      // }
    }

    return rect;
  }

  function createPolygon(id, idx, group, p) {
    const polygon = mapContent.append('polygon');

    polygon
      .attr('id', id)
      .attr('data-item-idx', idx)
      .attr('class', 'item ' + group.name)
      .attr('fill', group.color)
      .attr('stroke', group.stroke)
      .attr('points', p.split('$').join(','));

    let isDisabled = false;

    if (!isDisabled) {
      // if still enabled, check by ID
      const lland = lands.find(land => `${land.tokenId}` === `${idx + 1}`);
      if (lland) {
        polygon.classed('disabled', true);
        polygon.attr('owner', lland.owner);
        isDisabled = true;
      }
    }

    return polygon;
  }

  function initialize() {
    // ------------------------------------------------
    // Create items

    const items = [];
    itemsData.forEach((d, i) => {
      const group = groups.find(g => g.name === d.group2);

      if (!d.metadata || (d.metadata && d.metadata.indexOf('matrix') !== -1)) {
        items.push(
          createRect(d.id, i, group, d.x, d.y, d.width, d.height, d.metadata)
        );
      } else {
        items.push(createPolygon(d.id, i, group, d.metadata));
      }
    });

    // LandSelection component is not showing
    // createGroupsSection(itemsData, groups);

    // ------------------------------------------------
    // Item selection

    function updateItemSelection(item) {
      if (
        selectedItemsIdxs.indexOf(item.getAttribute('data-item-idx')) !== -1
      ) {
        deselectItem(item);
      } else {
        selectItem(item);
      }
    }

    const selectItem = item => {
      d3.select(item).classed('selected', true);
      selectedItemIdx = item.getAttribute('data-item-idx');
      selectedItemsIdxs.push(selectedItemIdx);
    };

    function deselectItem(item) {
      d3.select(item).classed('selected', false);
      selectedItemIdx = item.getAttribute('data-item-idx');
      const indexOverSelectedItemsIdxs =
        selectedItemsIdxs.indexOf(selectedItemIdx);
      if (indexOverSelectedItemsIdxs !== -1) {
        selectedItemsIdxs.splice(indexOverSelectedItemsIdxs, 1);
      }
      selectedItemIdx = null;
    }

    function deselectItems() {
      mapContent.select('.selected').classed('selected', false);
      selectedItemIdx = null;
      selectedItemsIdxs = [];
    }

    // ------------------------------------------------
    // Zooming

    // Create zooming instance

    const svgCenter = [IMAGE_SIZE * 0.5, IMAGE_SIZE * 0.5];
    const zoomBound = [1, 50];

    function zoomed({ transform }) {
      zoomTransformData = transform;
      zoomWrapper.attr('transform', zoomTransformData);
      backgroundDarkness.attr('opacity', zoomTransformData.k / zoomBound[1]);
    }

    const zoom = d3
      .zoom()
      .extent([
        [0, 0],
        [IMAGE_SIZE, IMAGE_SIZE],
      ])
      .scaleExtent(zoomBound)
      .on('start', () => {
        mapBackground.selectAll('.zoom-level').classed('active', false);
      })
      .on('zoom', zoomed)
      .on('end', () => {
        if (zoomTransformData.k >= 2) {
          // calculate visible area
          let x =
            IMAGE_SIZE -
            (IMAGE_SIZE + zoomTransformData.x / zoomTransformData.k);
          let y =
            IMAGE_SIZE -
            (IMAGE_SIZE + zoomTransformData.y / zoomTransformData.k);
          const ratio = svg.node().clientWidth / svg.node().clientHeight;
          let width, height;
          if (ratio < 1) {
            width = IMAGE_SIZE / zoomTransformData.k;
            height = width / ratio;
            y -= 0.5 * (height - width);
          } else {
            height = IMAGE_SIZE / zoomTransformData.k;
            width = height * ratio;
            x -= 0.5 * (width - height);
          }

          let cellSize, baseID, classList, imagesArray;
          if (zoomTransformData.k >= 4) {
            // console.log('zoom level 2');
            mapBackground.selectAll('.zoom-level-one').remove();
            cellSize = IMAGE_SIZE / 4;
            baseID = 'zoom_2_';
            classList = 'zoom-level zoom-level-two active';
            imagesArray = BACKIMAGE_URLS.quadrupleZoom;
          } else if (zoomTransformData.k >= 2) {
            // console.log('zoom level 1');
            mapBackground.selectAll('.zoom-level-two').remove();
            cellSize = IMAGE_SIZE / 2;
            baseID = 'zoom_1_';
            classList = 'zoom-level zoom-level-one active';
            imagesArray = BACKIMAGE_URLS.doubleZoom;
          }

          x = x / cellSize;
          y = y / cellSize;
          width = width / cellSize;
          height = height / cellSize;
          for (let i = Math.floor(x); i <= Math.floor(x + width); i++) {
            for (let j = Math.floor(y); j <= Math.floor(y + height); j++) {
              if (
                i >= 0 &&
                j >= 0 &&
                i * cellSize < IMAGE_SIZE &&
                j * cellSize < IMAGE_SIZE
              ) {
                const tail = mapBackground.select(
                  '#' + baseID + '' + j + '_' + i
                );
                if (tail.empty()) {
                  const image = mapBackground.append('image');
                  image
                    .attr('id', baseID + '' + j + '_' + i)
                    .attr('class', classList)
                    .attr('x', i * cellSize)
                    .attr('y', j * cellSize)
                    .attr('width', cellSize)
                    .attr('height', cellSize)
                    .attr('xlink:href', imagesArray[j * 4 + i]);
                } else {
                  tail.classed('active', true);
                }
              }
            }
          }

          if (zoomTransformData.k >= 4) {
            mapBackground.selectAll('.zoom-level-two:not(.active)').remove();
          } else if (zoomTransformData.k >= 2) {
            mapBackground.selectAll('.zoom-level-one:not(.active)').remove();
          }
        } else {
          mapBackground.selectAll('.zoom-level').remove();
        }

        // console.log('DEBUG-zoomed', { regionClicked });
        hideTooltip();
      });

    let zoomTransformData = d3.zoomTransform(svg);

    // Call zooming

    svg.call(zoom);

    // Initial zooming for filling whole page
    zoom.scaleBy(svg.transition().duration(ZOOM_DURATION), 4);

    mapContent.selectAll('.item').on('click', function () {
      const classnames = (this.getAttribute('class') || '').split(' ');
      if (!classnames.includes('selected')) {
        classnames.push('selected');
      }

      this.setAttribute('class', classnames.join(' '));

      selectedEl = this;
      regionClicked = true;
      // showTooltip(this, 1);

      const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?landId=${this.id}`;

      window.history.pushState({ path: newUrl }, '', newUrl);

      // zoomToNode(this);
    });

    mapContent.selectAll('.item').on('mouseover', function () {
      !regionClicked && showTooltip(this, 0.8);
    });

    mapContent.selectAll('.item').on('mouseleave', function () {
      // console.log('DEBUG-regionClicked', { regionClicked });

      !regionClicked && hideTooltip();
    });

    const hideTooltip = () => {
      // console.log('Debug-hiding');

      if (selectedEl) {
        const classnames = (selectedEl.getAttribute('class') || '').split(' ');
        if (classnames.includes('selected')) {
          const index = classnames.indexOf('selected');
          classnames.splice(index, 1);
        }

        selectedEl.setAttribute('class', classnames.join(' '));
      }

      (landTooltipLandEl as any).innerHTML = '';
      (landTooltipEl as any).style.display = 'none';

      regionClicked = false;
    };

    mapContent.selectAll('.district').on('click', function () {
      deselectItems();
    });

    const zoomToNode = (el, predefinedZoom = null) => {
      const box = el.getBBox();

      const bLeft = box.x || 0;
      const bTop = box.y || 0;
      const bWidth = box.width || 0;
      const bHeight = box.height || 0;

      const itemSize = Math.max(bWidth, bHeight);
      const scale = Math.min(zoomBound[1], IMAGE_SIZE / itemSize);
      const duration =
        Math.abs(scale / zoomTransformData.k) > 7
          ? ZOOM_DURATION * 2
          : ZOOM_DURATION;
      svg
        .transition()
        .duration(duration)
        .call(
          zoom.transform,
          d3.zoomIdentity
            .translate(svgCenter[0], svgCenter[1])
            .scale(predefinedZoom ? predefinedZoom : scale)
            .translate(
              -(bLeft + bLeft + bWidth) / 2,
              -(bTop + bTop + bHeight) / 2
            )
          // d3.pointer(el, svg.node())
        );

      setTimeout(() => {
        showTooltip(el, 1);

        selectedEl = el;
        regionClicked = true;
      }, duration + 100);
      // (landTooltipLandEl as any).innerHTML = '';
      // (landTooltipEl as any).style.display = 'none';
    };

    const showTooltip = (el, opacity) => {
      // console.log('DEBUG-showTooltip', { opacity });

      const color = el.getAttribute('fill');

      // Set color
      (landTooltipIconEl as any).style.background = color;
      (landTooltipIconEl as any).style.display = 'flex';

      // Positioning tooltip
      const pos = d3.select(el).node().getBoundingClientRect();

      let left = pos.left;
      let top = pos.top;

      // Classnames
      const classname = el.getAttribute('class');

      if (classname.includes('disabled')) {
        left = left - MAP_TOOLBAR_WIDTH / 2 + pos.width / 2;
        top = top - MAP_TOOLBAR_HEIGHT;

        (landTooltipEl as any).style.left = `${left}px`;
        (landTooltipEl as any).style.top = `${top}px`;

        (landTooltipLandEl as any).innerHTML = el.id;

        (landTooltipEl as any).style.opacity = opacity;
        (landTooltipEl as any).style.display = 'block';

        // const ttClassnames = (landTooltipEl.getAttribute('class') || '').split(
        //   ' '
        // );
        // if (!ttClassnames.includes('disabled')) {
        //   ttClassnames.push('disabled');
        // }

        // landTooltipEl.setAttribute('class', ttClassnames.join(' '));

        (landTooltipIconEl as any).style.display = 'none';

        // (landTooltipLandEl as any).innerHTML = 'Unavailable';

        (landTooltipContainerEl as any).style.display = 'flex';
        // (landTooltipEl as any).style.background = '#1F1C22';
        // (landTooltipEl as any).style.display = 'block';

        return;
      }
      (landTooltipContainerEl as any).style.display = 'grid';

      left = left - MAP_TOOLBAR_WIDTH / 2 + pos.width / 2;
      top = top - MAP_TOOLBAR_HEIGHT;

      (landTooltipEl as any).style.left = `${left}px`;
      (landTooltipEl as any).style.top = `${top}px`;

      if (classname.includes('activated')) {
        landTooltipIconEl.innerHTML = `
          <svg
            width='22'
            height='18'
            viewBox='0 0 22 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.87803 12.4399L3.76578 8.2944L2 10.0745L7.87803 16L20 3.78006L18.2342 2L7.87803 12.4399Z'
              fill='white'
              stroke='white'
              strokeWidth='2'
            />
          </svg>
        `;
      } else {
        landTooltipIconEl.innerHTML = `
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7.49097" y="19.5984" width="19.5984" height="4.61652" transform="rotate(-90 7.49097 19.5984)" fill="white"/>
            <rect x="7.49097" y="19.5984" width="19.5984" height="4.61652" transform="rotate(-90 7.49097 19.5984)" fill="white"/>
            <rect x="7.49097" y="19.5984" width="19.5984" height="4.61652" transform="rotate(-90 7.49097 19.5984)" fill="white"/>
            <rect y="7.49097" width="19.5984" height="4.61652" fill="white"/>
            <rect y="7.49097" width="19.5984" height="4.61652" fill="white"/>
            <rect y="7.49097" width="19.5984" height="4.61652" fill="white"/>
          </svg>
        `;
      }

      const ttClassnames = (landTooltipEl.getAttribute('class') || '').split(
        ' '
      );
      if (ttClassnames.includes('disabled')) {
        const dIndex = ttClassnames.indexOf('disabled');
        ttClassnames.splice(dIndex, 1);
      }

      landTooltipEl.setAttribute('class', ttClassnames.join(' '));

      (landTooltipLandEl as any).innerHTML = el.id;

      (landTooltipEl as any).style.opacity = opacity;
      (landTooltipEl as any).style.display = 'block';
    };

    const zoomInBtn = document.querySelector('#map-zoom-in-btn');
    const zoomOutBtn = document.querySelector('#map-zoom-out-btn');
    const zoomResetBtn = document.querySelector('#map-zoom-reset-btn');

    (zoomInBtn as any).onclick = function () {
      if (itemsData[selectedItemIdx]) {
        const el = document.querySelector('#' + itemsData[selectedItemIdx].id);
        zoomToNode(el, zoomTransformData.k * 2);
      } else {
        zoom.scaleBy(svg.transition().duration(ZOOM_DURATION), 7);
      }
    };

    (zoomOutBtn as any).onclick = function () {
      if (itemsData[selectedItemIdx]) {
        const el = document.querySelector('#' + itemsData[selectedItemIdx].id);
        zoomToNode(el, zoomTransformData.k * 0.5);
      } else {
        zoom.scaleBy(svg.transition().duration(ZOOM_DURATION), 0.3);
      }
    };

    (zoomResetBtn as any).onclick = function () {
      svg
        .transition()
        .duration(ZOOM_DURATION)
        .call(
          zoom.transform,
          d3.zoomIdentity,
          d3.zoomTransform(svg.node()).invert(svgCenter)
        );

      deselectItems();
      showClickedItemInfo(null);
    };

    // ------------------------------------------------
    // Auto zoom to the item from URL

    const selectLandById = id => {
      const selected = zoomWrapper.select('#' + id).node();
      showClickedItemInfo(selected);
      updateItemSelection(selected);
      zoomToNode(selected);
    };

    const getParameterByName = (name, url) => {
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);

      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const parsedID = urlParams.get('landId');

    parsedID && selectLandById(parsedID);

    (function (history) {
      const pushState = history.pushState;
      history.pushState = function (state, ...args) {
        if (args && args.length > 1) {
          const landId = getParameterByName('landId', args[1]);
          landId && selectLandById(landId);
        }

        pushState.call(history, state, ...args);
      };
    })(window.history);
  }

  initialize();
};

export const mapHideSquare = (mapId, groupId, visible) => {
  const mapEl = document.getElementById(mapId);
  const items = mapEl.querySelectorAll(`.${groupId}`);

  items.forEach(item => {
    item.setAttribute('opacity', visible ? '1' : '0');
  });
};

export const mapSelectSquare = (squareId, selected) => {
  const squareEl = document.getElementById(squareId);
  const classNames = (squareEl.getAttribute('class') || '').split(' ');

  if (selected) {
    classNames.push('selected');
  } else {
    const index = classNames.findIndex(c => c === 'selected');

    // console.log('DEBUG-', { index });
    classNames.splice(index, 1);
    // console.log('DEBUG-', { classNames });
  }

  squareEl.setAttribute('class', classNames.join(' '));
};
