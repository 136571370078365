import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';

import store from 'state';
import ChainProvider from 'context/chain/context';
import LandProvider from 'context/land/context';

import { getLibrary } from 'utils/web3React';

const Providers: React.FC = ({ children }) => {
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
          <ChainProvider>
            <LandProvider>{children}</LandProvider>
          </ChainProvider>
        </Provider>
      </Web3ReactProvider>
      <Toaster
        position='top-right'
        toastOptions={{
          style: {
            fontSize: '14px',
            minWidth: '250px',
          },
        }}
      />
    </>
  );
};

export default Providers;
