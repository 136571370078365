import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import { FormButton } from 'components/Atoms';
import { ActiveChainId } from 'config/constants';
import { useMe } from 'state/user/hooks';
import { CONNECTOR_LOCAL_KEY, ConnectorNames } from 'config/constants';
import { injected, walletconnect, resetWalletConnector } from 'utils/web3React';
import { shortenAddress } from 'utils/helper';

import './index.scss';

export const WalletConnect = () => {
  const { account } = useMe();
  const { active, activate, account: walletAccount } = useWeb3React();
  const history = useHistory();

  const [connecting, setConnecting] = useState<boolean>(false);
  const [labels, setLabels] = useState<any>({});

  const handleMetaConnect = async () => {
    if (!window.ethereum) return;

    setConnecting(true);

    try {
      const chainId = await (window as any).ethereum.request({
        method: 'eth_chainId',
      });

      if (![`0x${ActiveChainId}`].includes(chainId)) {
        // Request to change metmask network to ETH-MAINNET
        await (window as any).ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${ActiveChainId}` }],
        });
      }

      window.localStorage.setItem(CONNECTOR_LOCAL_KEY, ConnectorNames.Injected);
      await activate(injected);

      toast.success('Wallet Connected');
    } catch (error) {
      toast.error('Problem connecting your metamask.');
    }

    setConnecting(false);
  };

  const handleTrustConnect = async () => {
    setConnecting(true);

    try {
      resetWalletConnector(walletconnect);
      await activate(walletconnect);

      window.localStorage.setItem(
        CONNECTOR_LOCAL_KEY,
        ConnectorNames.WalletConnect
      );

      toast.success('Wallet Connected');
    } catch (error) {
      toast.error('Problem connecting your wallet connect device.');
    }

    setConnecting(false);
  };

  const handleClaimLand = () => {
    history.push('/land');
  };

  useEffect(() => {
    if (connecting) {
      setLabels({
        title: 'Connecting...',
        description: 'Attempting to connect to your Metamask Wallet',
      });

      return;
    }

    if (walletAccount) {
      setLabels({
        title: 'Wallet Connected',
        description: `You are now connected to Metamask wallet ${shortenAddress(
          walletAccount
        )}\nYou can now claim/mint your land, click below to get started!`,
      });

      return;
    }

    setLabels({
      title: `Welcome, ${account.username}`,
      description: 'Connect a wallet to claim/mint your lands',
    });
  }, [connecting, walletAccount, account]);

  return (
    <div className='wallet-connect'>
      <div className='wallet-connect__container'>
        <h1>{labels.title}</h1>
        <p>{labels.description}</p>
        {active ? (
          <FormButton label='CLAIM YOUR LAND' onClick={handleClaimLand} />
        ) : (
          <div className='wallet-connect__body mobile:mx-4'>
            <div
              className='metamask wallet-connect__connector'
              onClick={handleMetaConnect}
            >
              <span>Metamask</span>
              <img src='/images/metamask.svg' alt='metamask' />
            </div>
            <div
              className='wallet-connect__connector'
              onClick={handleTrustConnect}
            >
              <span>WalletConnect</span>
              <img src='/images/walletconnect.svg' alt='metamask' />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
