import { netvrkClient } from 'subgraph/client';
import {
  fetchAllClaimedLandsQuery,
  fetchPersonalClaimedLandsQuery,
} from 'subgraph/query';

import { TOTAL_SUPPLY } from 'config/map';

export const fetchAllClaimedLands = async (chainId, address = null) => {
  const lands = [];
  try {
    const client = netvrkClient(chainId);
    for (let i = 0; i < TOTAL_SUPPLY; i += 1000) {
      const { tokens } = await client.request(fetchAllClaimedLandsQuery(i));
      if (!tokens || !tokens.length) break;

      lands.push(...tokens);
    }
  } catch (error) {
    console.error(error);
  }

  return lands;
};

export const fetchPersonalClaimedLands = async (chainId, address: string) => {
  const myLands = [];
  try {
    const client = netvrkClient(chainId);
    const { tokens } = await client.request(
      fetchPersonalClaimedLandsQuery(address.toLowerCase())
    );
    if (!tokens || !tokens.length) return [];

    myLands.push(...tokens);
  } catch (error) {
    console.error(error);
  }

  return myLands;
};
