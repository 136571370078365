import React from 'react';

import { useChain, useUpdateChain } from 'context/chain/context';
import { useLands, useUpdateLands } from 'context/land/context';

import './index.scss';

export const LandTooltip = () => {
  const { lands } = useChain();
  const { selectedLands } = useLands();
  const { selectLand } = useUpdateLands();
  const { selectLand: selectLandChain } = useUpdateChain();

  const handleClick = () => {
    const selectedLand = document.getElementById(
      'new-land-tooltip__land'
    ).innerHTML;

    if (!selectedLand) return;

    const index = selectedLands.findIndex(
      sland => sland.label === selectedLand
    );

    const land = lands.find(l => l.id === selectedLand);
    if (!land) return;

    const payload = {
      id: land.tokenId,
      label: land.id,
      landType: land.group2,
      textColor: '#fff',
    };
    selectLandChain(payload, !(index > -1));
    selectLand(payload, !(index > -1));

    if (index === -1) {
      document.getElementById('new-land-tooltip-icon').innerHTML = `
        <svg
          width='22'
          height='18'
          viewBox='0 0 22 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.87803 12.4399L3.76578 8.2944L2 10.0745L7.87803 16L20 3.78006L18.2342 2L7.87803 12.4399Z'
            fill='white'
            stroke='white'
            strokeWidth='2'
          />
        </svg>
      `;
    } else {
      document.getElementById('new-land-tooltip-icon').innerHTML = `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="7.49097" y="19.5984" width="19.5984" height="4.61652" transform="rotate(-90 7.49097 19.5984)" fill="white"/>
          <rect x="7.49097" y="19.5984" width="19.5984" height="4.61652" transform="rotate(-90 7.49097 19.5984)" fill="white"/>
          <rect x="7.49097" y="19.5984" width="19.5984" height="4.61652" transform="rotate(-90 7.49097 19.5984)" fill="white"/>
          <rect y="7.49097" width="19.5984" height="4.61652" fill="white"/>
          <rect y="7.49097" width="19.5984" height="4.61652" fill="white"/>
          <rect y="7.49097" width="19.5984" height="4.61652" fill="white"/>
        </svg>
      `;
    }
  };

  return (
    <div id='new-land-tooltip' className='new-land-tooltip'>
      <div
        id='new-land-tooltip-container'
        className='new-land-tooltip__container'
      >
        <div
          id='new-land-tooltip-icon'
          className='new-land-tooltip__icon'
          onClick={handleClick}
        />
        <p id='new-land-tooltip__land' className='new-land-tooltip__land'></p>
      </div>
    </div>
  );
};
