import cx from 'classnames';

export default ({ selected, onClick }) => {
  return (
    <div
      className={cx(
        selected ? 'opacity-30' : '',
        'hover:opacity-30 cursor-pointer'
      )}
      onClick={onClick}
    >
      <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='9' width='7' height='7' fill='white' />
        <rect x='18' width='7' height='7' fill='white' />
        <rect width='7' height='7' fill='white' />
        <rect x='9' y='9' width='7' height='7' fill='white' />
        <rect x='18' y='9' width='7' height='7' fill='white' />
        <rect x='9' y='18' width='7' height='7' fill='white' />
        <rect x='18' y='18' width='7' height='7' fill='white' />
        <rect y='9' width='7' height='7' fill='white' />
        <rect y='18' width='7' height='7' fill='white' />
      </svg>
    </div>
  );
};
