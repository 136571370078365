import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';

import { useChain } from 'context/chain/context';
import { Layout, DashboardSidebar } from 'components/Atoms';
import SubHeader from './SubHeader';
import Card from './Card';
import { TransportsWrapper } from './styles';

const TransportsDashboardMain = () => {
  const [myTransportIds, setMyTransportIds] = useState([]);
  const [myTransportImages, setMyTransportImages] = useState({});
  const [filteredTransportIds, setFilteredTransportIds] = useState([]);
  const [pageReady, setPageReady] = useState(false);

  const {
    transports: { all: allTransports, owned: myTransports },
    RootTransportContract,
  } = useChain();

  const [viewMode, setViewMode] = useState(1);

  useEffect(() => {
    setMyTransportIds(myTransports);
    setFilteredTransportIds(myTransports);
    setPageReady(true);
  }, [myTransports]);

  useEffect(() => {
    const fetchImages = async function () {
      const images = {};
      try {
        await Promise.all(
          myTransportIds.map(async tokenId => {
            const tokenURI = await RootTransportContract.tokenURI(tokenId);
            const {
              data: { image },
            } = await axios.get(tokenURI);
            images[tokenId] = image;
          })
        );
      } catch (e) {}
      setMyTransportImages(images);
    };
    RootTransportContract && fetchImages();
  }, [myTransportIds, RootTransportContract]);

  const onFilter = useCallback(
    (type: string) => {
      if (!myTransportIds.length) return;
      if (type === 'All Transport Types') {
        setFilteredTransportIds(myTransportIds);
        return;
      }
      const filteredIds = myTransportIds.filter(tokenId => {
        const transport = allTransports.find(d => d.tokenId === tokenId);
        return transport && transport.type === type;
      });

      setFilteredTransportIds(filteredIds);
    },
    [allTransports, myTransportIds]
  );

  return (
    <Layout>
      <DashboardSidebar context='transport' />
      <div className='flex w-full px-6'>
        <TransportsWrapper>
          {pageReady && (
            <div className='w-full px-2 sm:px-6 mt-2 sm:mt-6 flex flex-col'>
              <h1 className='primary-heading'>Transports</h1>
              <p className='sub-heading'>
                Successfully minted transports will appear here.
              </p>
              <SubHeader
                viewMode={viewMode}
                setViewMode={setViewMode}
                onFilter={onFilter}
              />
              <div
                className={cx(
                  'mt-6 pb-4',
                  viewMode === 1 ? 'grid-view' : 'row-view'
                )}
              >
                {filteredTransportIds.map(tokenId => (
                  <div
                    key={tokenId}
                    className={cx(
                      viewMode === 1 ? 'h-220 tabletUp:h-320' : 'h-420',
                      ' flex items-center'
                    )}
                  >
                    <Card
                      image={myTransportImages[tokenId] || null}
                      tokenId={tokenId}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </TransportsWrapper>
      </div>
    </Layout>
  );
};

export default TransportsDashboardMain;
