import { useWeb3React } from '@web3-react/core';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';

export default function useAccountLibrary<T = any>() {
  const {
    connector,
    library,
    chainId,
    account,
    active,
    error,
    activate,
    deactivate,
  } = useWeb3React<T>();

  try {
    const search = window?.location?.search;
    if (search) {
      const params = new URLSearchParams(search);
      const act = params.get('acct');
      if (act && account) {
        // account = act;
      }
    }
  } catch (err) {
    console.log(err);
    console.log('Could not load querystring acct');
  }

  return {
    connector,
    library,
    chainId,
    account,
    active,
    error,
    activate,
    deactivate,
  } as Web3ReactContextInterface<T>;
}
