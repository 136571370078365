import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  AuthLayout,
  FormInput,
  FormButton,
  FormRow,
  FormLink,
} from 'components/Atoms';

import { IUser } from 'state/types';
import { useLands } from 'state/lands/hooks';
import { useAuth, useUpdateUser } from 'state/user/hooks';
import { loginWithUsername, me, userLands } from 'utils/firebase';

export default () => {
  const history = useHistory();
  const { setLands } = useLands();

  const { loggedIn } = useAuth();
  const updateUser = useUpdateUser();

  const [form, setForm] = useState({
    username: '',
    password: '',
  });
  const [validation, setValidation] = useState({
    username: false,
    password: false,
  });
  const [loggingIn, setLoggingIn] = useState(false);

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleBlur = event => {
    setValidation({
      ...validation,
      [event.target.name]: !form[event.target.name],
    });
  };

  const handleLogin = async () => {
    if (!form.username || !form.password) {
      setValidation({
        username: !form.username,
        password: !form.password,
      });
      return;
    }

    setLoggingIn(true);

    try {
      await loginWithUsername(form.username, form.password);

      const fUser = await me();

      if (!fUser.user.emailVerified) {
        throw new Error('Email is not verified');
      }

      const user: IUser = {
        account: {
          uid: fUser.user.uid,
          email: fUser.user.email,
          username: fUser.data.username,
          isVerified: fUser.user.emailVerified,
        },
        profile: {
          wallet: fUser.data.wallet || '',
          points: fUser.data.points || 0,
        },
      };
      updateUser(user);

      const lands = await userLands(fUser.user.uid);
      setLands(lands);

      toast.success('Successfully logged in');
      history.push('/land');
    } catch (error) {
      toast.error(error.message);
    }

    setLoggingIn(false);
  };

  if (!loggingIn && loggedIn) {
    return <Redirect to='/land' />;
  }

  return (
    <AuthLayout title='Login'>
      <Helmet>
        <title>Netvrk - Login</title>
      </Helmet>
      <>
        <FormInput
          type='text'
          name='username'
          value={form.username}
          placeholder='Username or Email'
          validation={validation.username}
          onChange={handleChange}
          onBlur={handleBlur}
          onEnter={handleLogin}
        />
        <FormInput
          type='password'
          name='password'
          value={form.password}
          placeholder='Password'
          validation={validation.password}
          onChange={handleChange}
          onBlur={handleBlur}
          onEnter={handleLogin}
        />
        <FormRow link='/forgot-password' label='Forgot Password?' />
        <FormButton label='LOGIN' onClick={handleLogin} loading={loggingIn} />
        <FormLink label='New user? Sign up here' link='/signup' />
      </>
    </AuthLayout>
  );
};
