import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import cx from 'classnames';
import { useChain } from 'context/chain/context';
import { useLands } from 'state/lands/hooks';
import useActiveWeb3 from 'hooks/useActiveWeb3';
import { Layout } from 'components/Atoms';
import SubHeader from './SubHeader';
import LandCard from './LandCard';
import { LandsWrapper } from './styles';

const LandsDashboardMain = () => {
  const [myLandIds, setMyLandIds] = useState([]); // All my lands from graph server
  const [filteredLandIds, setFilteredLandIds] = useState([]);
  const [pageReady, setPageReady] = useState(false);

  const { chainId, account: web3Address } = useActiveWeb3();
  const { lands } = useLands(); // Only lands with image uploaded to cloud
  const { lands: allLands, myLands } = useChain();

  const [viewMode, setViewMode] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      const myLandIds = myLands.map(l => l.tokenId);
      setMyLandIds(myLandIds);
      setFilteredLandIds(myLandIds);
      setPageReady(true);
    };

    web3Address && fetch();
  }, [chainId, web3Address, myLands]);

  const onFilter = useCallback(
    (landType: string) => {
      if (!allLands.length) return;
      if (landType === 'All Land Types') {
        setFilteredLandIds(myLandIds);
        return;
      }
      const realLandType = landType.replaceAll(' ', '_');
      const landIds = allLands
        .filter(land => land.group2 === realLandType)
        .map(land => land.id.substring(land.id.lastIndexOf('_') + 1))
        .filter(landId => myLandIds.includes(landId));

      setFilteredLandIds(landIds);
    },
    [allLands, myLandIds]
  );

  return (
    <Layout>
      <Helmet>
        <title>Netvrk Mint Dashboard</title>
        <meta name='description' content='Mint NFTs through the dashboard' />
      </Helmet>
      <div className='flex w-full desktop:w-3/4 px-6 mx-auto'>
        <LandsWrapper>
          {pageReady && (
            <div className='w-full px-2 sm:px-6 mt-2 sm:mt-6 flex flex-col'>
              <h1 className='primary-heading'>Lands</h1>
              <p className='sub-heading'>
                Successfully minted lands from the mint map will appear here.
              </p>
              <SubHeader
                viewMode={viewMode}
                setViewMode={setViewMode}
                onFilter={onFilter}
              />
              <div
                className={cx(
                  'mt-6 pb-4',
                  viewMode === 1 ? 'grid-view' : 'row-view'
                )}
              >
                {filteredLandIds.map(landId => {
                  const land = lands.find(l => l.landId === landId);
                  return (
                    <div
                      key={landId}
                      className={cx(
                        viewMode === 1 ? 'h-220 tabletUp:h-320' : 'h-420',
                        ' flex items-center'
                      )}
                    >
                      {land && land.image !== '' ? (
                        <LandCard image={land.image} landId={landId} />
                      ) : (
                        <LandCard image={null} landId={landId} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </LandsWrapper>
      </div>
    </Layout>
  );
};

export default LandsDashboardMain;
