import React from 'react';

import { ModalWrapper, ModalHeader, ModalBody, ModalFooter } from '../Modal';
import { FormButton } from '../Form';

import toast from 'react-hot-toast';
import { useChain, useUpdateChain } from 'context/chain/context';
import { mintMultiTransports } from 'context/chain/contracts';

import './index.scss';

export type IModalMintProps = {
  tokenIds: string[];
  open: boolean;
  onHide: () => void | undefined;
};

export const TransportMintPreviewModal: React.FC<IModalMintProps> = ({
  tokenIds,
  open,
  onHide,
}: IModalMintProps) => {
  const {
    transports: { all: allTransports, owned: myTransports },
    TransportMinterContract,
  } = useChain();
  const { dispatch } = useUpdateChain();

  const [loading, setLoading] = React.useState(false);
  const [minted, setMinted] = React.useState(false);

  const handleMint = async () => {
    if (tokenIds.length === 0) return;

    setLoading(true);
    try {
      const tokenWithProof = tokenIds.map(id => ({
        id: id,
        proof: allTransports
          .filter(item => item.tokenId === id)
          .map(item => item.proof)[0],
      }));

      await mintMultiTransports(TransportMinterContract, tokenWithProof);
      dispatch(state => {
        const { transports } = state;
        return {
          ...state,
          transports: {
            ...transports,
            owned: myTransports.concat(...tokenIds),
          },
        };
      });
      setMinted(true);
    } catch (error) {
      toast.error('Problem with minting the transports.');
    }
    setLoading(false);
  };

  const handleHide = () => {
    setMinted(false);
    onHide();
  };

  const transportLabel = (tokenId: string) => {
    const transport = allTransports.find(d => d.tokenId === tokenId);
    return transport ? `${transport.type}_${tokenId}` : '<unknown>';
  };

  return (
    <ModalWrapper open={open}>
      <>
        <ModalHeader onHide={handleHide}>
          <div className='modal-header__description'>
            <h5>
              {minted
                ? 'MINT REPORT'
                : loading
                ? 'OPENING WALLET'
                : 'MINT PREVIEW'}
            </h5>
            <p>
              {minted
                ? `Yay! You successfully minted transports.`
                : loading
                ? ``
                : tokenIds.length > 0
                ? `You are about to mint ${tokenIds.length} items.`
                : 'You have no transports to mint.'}
            </p>
          </div>
        </ModalHeader>
        {tokenIds.length && (
          <ModalBody>
            {loading ? (
              <div className='mint-spinner'>
                <img alt='minting' src='/images/minting.gif' />
              </div>
            ) : (
              <ul>
                {tokenIds.map(tokenId => (
                  <li key={tokenId} className='modal-row'>
                    <div className='modal-row__icon'>
                      <svg
                        width='11'
                        height='9'
                        viewBox='0 0 11 9'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M3.93902 6.21994L1.88289 4.1472L1 5.03723L3.93902 8L10 1.89003L9.11711 1L3.93902 6.21994Z'
                          fill={minted ? '#3DFF73' : '#C397F7'}
                          stroke={minted ? '#3DFF73' : '#C397F7'}
                        />
                      </svg>
                    </div>
                    <div
                      className='modal-row__text'
                      style={{ color: minted ? '#3DFF73' : '#fff' }}
                    >
                      {transportLabel(tokenId)}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </ModalBody>
        )}
        <ModalFooter>
          <>
            {/* {unListedCount > 0 && (
              <div className='modal-footer__description'>
                <p>You have {unListedCount} un-minted items.</p>
                <p>Would you like to proceed anyway?</p>
              </div>
            )} */}
            {minted ? (
              <div>
                <FormButton label='CLOSE' onClick={handleHide} />
              </div>
            ) : (
              <div className='modal-footer__actions'>
                <FormButton label='CANCEL' onClick={handleHide} />
                <FormButton
                  label='MINT NOW'
                  loading={loading}
                  disabled={tokenIds.length === 0}
                  onClick={handleMint}
                />
              </div>
            )}
          </>
        </ModalFooter>
      </>
    </ModalWrapper>
  );
};
