import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';

import { useChain } from 'context/chain/context';
import { Layout, DashboardSidebar } from 'components/Atoms';
import SubHeader from './SubHeader';
import Card from './Card';
import { AvatarsWrapper } from './styles';

const AvatarsDashboardMain = () => {
  const [myAvatarIds, setMyAvatarIds] = useState([]);
  const [myAvatarImages, setMyAvatarImages] = useState({});
  const [filteredAvatarIds, setFilteredAvatarIds] = useState([]);
  const [pageReady, setPageReady] = useState(false);

  const {
    avatars: { all: allAvatars, owned: myAvatars },
    RootAvatarContract,
  } = useChain();

  const [viewMode, setViewMode] = useState(1);

  useEffect(() => {
    setMyAvatarIds(myAvatars);
    setFilteredAvatarIds(myAvatars);
    setPageReady(true);
  }, [myAvatars]);

  useEffect(() => {
    const fetchImages = async function () {
      const images = {};
      try {
        await Promise.all(
          myAvatarIds.map(async tokenId => {
            const tokenURI = await RootAvatarContract.tokenURI(tokenId);
            const {
              data: { image },
            } = await axios.get(tokenURI);
            images[tokenId] = image;
          })
        );
      } catch (e) {}
      setMyAvatarImages(images);
    };
    RootAvatarContract && fetchImages();
  }, [myAvatarIds, RootAvatarContract]);

  const onFilter = useCallback(
    (type: string) => {
      if (!myAvatarIds.length) return;
      if (type === 'All Avatar Types') {
        setFilteredAvatarIds(myAvatarIds);
        return;
      }
      const filteredIds = myAvatarIds.filter(tokenId => {
        const avatar = allAvatars.find(d => d.tokenId === tokenId);
        return avatar && avatar.type === type;
      });

      setFilteredAvatarIds(filteredIds);
    },
    [allAvatars, myAvatarIds]
  );

  return (
    <Layout>
      <DashboardSidebar context='avatar' />
      <div className='flex w-full px-6'>
        <AvatarsWrapper>
          {pageReady && (
            <div className='flex flex-col w-full px-2 mt-2 sm:px-6 sm:mt-6'>
              <h1 className='primary-heading'>Avatars</h1>
              <p className='sub-heading'>
                Successfully minted avatars will appear here.
              </p>
              <SubHeader
                viewMode={viewMode}
                setViewMode={setViewMode}
                onFilter={onFilter}
              />
              <div
                className={cx(
                  'mt-6 pb-4',
                  viewMode === 1 ? 'grid-view' : 'row-view'
                )}
              >
                {filteredAvatarIds.map(tokenId => (
                  <div
                    key={tokenId}
                    className={cx(
                      viewMode === 1 ? 'h-220 tabletUp:h-320' : 'h-420',
                      ' flex items-center'
                    )}
                  >
                    <Card
                      image={myAvatarImages[tokenId] || null}
                      tokenId={tokenId}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </AvatarsWrapper>
      </div>
    </Layout>
  );
};

export default AvatarsDashboardMain;
