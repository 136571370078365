import React from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, FormButton } from 'components/Atoms';

import './index.scss';

export default () => {
  const history = useHistory();

  const handleRedirectHome = () => {
    history.push('/');
  };

  return (
    <Layout>
      <div
        className='not-found'
        style={{ backgroundImage: 'url(/images/star.png)' }}
      >
        <div className='not-found__container'>
          <div className='not-found__header font-teko'>
            4<img src='/images/404.svg' alt='404' />4
          </div>
          <h1>Oops! Page not found.</h1>
          <FormButton label='BACK HOME' onClick={handleRedirectHome} />
        </div>
      </div>
    </Layout>
  );
};
