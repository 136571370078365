import { createReducer } from '@reduxjs/toolkit';

import { IUser } from 'state/types';
import { updateUserDetails, signOutUser } from './actions';

const initialState: IUser = {
  account: {
    uid: null,
    email: null,
    username: null,
    isVerified: false,
  },
  profile: {
    wallet: null,
    points: 0,
  },
};

export default createReducer(initialState, builder =>
  builder
    .addCase(updateUserDetails, (_state, { payload }) => payload)
    .addCase(signOutUser, (_state, _) => initialState)
);
