import { createReducer } from '@reduxjs/toolkit';

import { ILand } from 'state/types';
import { setLands, updateLand } from './actions';

const initialState: {
  lands: ILand[];
} = {
  lands: [],
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setLands, (state, { payload }) => {
      state.lands = payload;
    })
    .addCase(updateLand, (state, { payload }) => {
      const landIndex = state.lands.findIndex(l => l.landId === payload.landId);
      if (landIndex > -1) {
        state.lands[landIndex] = payload;
      } else {
        state.lands.push(payload);
      }
    })
);
