import { useEffect, useState } from 'react';

import { ActiveChainId } from '../config/constants';
import { Modal, MetamaskConnect, WalletConnect } from '.';
import useIsConnected from '../hooks/useIsConnected';
import useAccountLibrary from '../hooks/useActiveWeb3';

import {
  injected,
  walletconnect,
  resetWalletConnector,
} from '../utils/web3React';

import { CONNECTOR_LOCAL_KEY, ConnectorNames } from '../config/constants';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

export default function WalletOptionsModal({ isVisible, onClose }: Props) {
  const isConnected = useIsConnected();
  const { activate, connector } = useAccountLibrary();
  const [activatingConnector, setActivatingConnector] = useState<any>();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const onConnect = async (flag: number) => {
    if (flag === 1) {
      if (window.ethereum) {
        try {
          const chainId = await (window as any).ethereum.request({
            method: 'eth_chainId',
          });

          // if (!['0x1', '0x3', '0x4'].includes(chainId)) {
          //   // Request to change metmask network to ETH-MAINNET
          //   await (window as any).ethereum.request({
          //     method: 'wallet_switchEthereumChain',
          //     params: [{ chainId: '0x1' }],
          //   });
          // }

          if (![`0x${ActiveChainId}`].includes(chainId)) {
            // Request to change metmask network to ETH-MAINNET
            await (window as any).ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: `0x${ActiveChainId}` }],
            });
          }

          setActivatingConnector(injected);
          window.localStorage.setItem(
            CONNECTOR_LOCAL_KEY,
            ConnectorNames.Injected
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        alert(
          'MetaMask is not installed. Please consider installing it: https://metamask.io/download.html'
        );
      }
    } else if (flag === 2) {
      resetWalletConnector(walletconnect);
      setActivatingConnector(walletconnect);
      activate(walletconnect);

      window.localStorage.setItem(
        CONNECTOR_LOCAL_KEY,
        ConnectorNames.WalletConnect
      );
    } else return;
  };

  if (isConnected) return null;

  const content = (
    <>
      <div className='px-8 pt-2 flex flex-col font-favorit text-20px cursor-pointer'>
        <MetamaskConnect onConnect={() => onConnect(1)}>
          <div className='flex justify-between w-full px-4 bg-gradient-alt text-white items-center rounded-md mb-2'>
            <span className='leading-10'>Metamask</span>
            <img
              src='/images/metamask.svg'
              alt='metamask'
              width={20}
              height={20}
            />
          </div>
        </MetamaskConnect>
        <WalletConnect onConnect={() => onConnect(2)}>
          <div className='flex justify-between w-full px-4 bg-gradient-alt text-white items-center rounded-md'>
            <span className='leading-10'>WalletConnect</span>
            <img
              src='/images/walletconnect.svg'
              alt='walletConnect'
              width={20}
              height={20}
            />
          </div>
        </WalletConnect>
      </div>
      <div className='absolute bottom-0 w-full text-center'>
        <hr />
        <div
          className='flex items-center w-full justify-center cursor-pointer py-1'
          onClick={onClose}
        >
          <span className='text-white text-sm'>&lt; Go Back</span>
        </div>
      </div>
    </>
  );

  const modalOptions = {
    titleColor: '#B5FF00',
    width: '400',
    height: '366',
  };
  return (
    <Modal
      title='Connect Wallet'
      show={isVisible}
      onClose={onClose}
      ModalOptions={modalOptions}
    >
      {content}
    </Modal>
  );
}
