export const landContract = {
  1: '0x244FC4178fa685Af909c88b4D4CD7eB9127eDb0B',
  4: '0xD0a3145081dcd7C1A167BdE0665E2B3b39BeeB55',
};

export const parcelMinterContract = {
  1: '0x4Cd85724e8bF3F9bfAf731C1e5C7d11Ba4F98476',
  4: '0xc9da3A983f913D6334B04751376fF3B1EFDDcA25',
};

export const rootTransportContract = {
  1: '0xB95aBD5fa9E71f1981505c3D9A7800c369b0718c',
  4: '0x493140C2FfD4e65cE513a6A14A0A4a6F3d4EcCc0',
};

export const transportMinterContract = {
  1: '0xA6e4e55320aC29374458c402Ea2139b21a7e9b68',
  4: '0xd66a0FE8F5364153F4dB38B85d7860D398c60E2F',
};

export const rootAvatarContract = {
  1: '0xdc403FCDf735426e77Fdd3bbD6223a3ac03eF3b3',
  4: '0xbC07CeAc26b7C30DF1c3b78debC7f8E01f7CAfD6',
};

export const avatarMinterContract = {
  1: '0xE59Bfcaf06F41fF4819ed716dD7a4Ae432159EBD',
  4: '0x324D69Dcf8384bF314D71596f7af87b259631E9E',
};

export const rootBonusPackContract = {
  1: '0xBCbEaE3620b3280dF67143Ad7eC45d67C5A4355e',
  4: '0xE11582122F1383850F027D3Da74Caa40a7CC01B6',
};

export const bonusPackMinterContract = {
  1: '0x79869a94503C1Bd72dc07521eD6661659F7CD368',
  4: '0xE163e643C295857C81AFbBA3bA61786676B626aD',
};
