import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { IUser } from 'state/types';

import { useMe } from 'state/user/hooks';
import { useUpdateUser } from 'state/user/hooks';
import { updateUserEmail, updateUserProfile, me } from 'utils/firebase';

import { Layout, AuthLayout, FormInput, FormButton } from 'components/Atoms';

const Account = () => {
  const { account } = useMe();
  const [isUpdating, setUpdating] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const updateUser = useUpdateUser();

  useEffect(() => {
    setEmail(account.email);
    setUsername(account.username);
  }, [account]);

  const onUpdate = useCallback(async () => {
    setUpdating(true);

    try {
      if (email !== '' && email !== account.email) {
        await updateUserEmail(email);
      }

      if (username !== '' && username !== account.username) {
        await updateUserProfile({ username });
      }

      const fUser = await me();

      // if (!fUser.user.emailVerified) {
      //   throw new Error('Email is not verified');
      // }

      const user: IUser = {
        account: {
          uid: fUser.user.uid,
          email: fUser.user.email,
          username: fUser.data.username,
          // isVerified: fUser.user.emailVerified,
          isVerified: true,
        },
        profile: {
          wallet: fUser.data.wallet || '',
          points: fUser.data.points || 0,
        },
      };

      updateUser(user);

      toast.success('Your profile has been updated');
    } catch (error) {
      toast.error(error.message);
    }

    setUpdating(false);
  }, [account, username, email, updateUser]);

  return (
    <Layout>
      <Helmet>
        <title>Netvrk - Account</title>
      </Helmet>
      <AuthLayout title='Account' noHeader>
        <FormInput
          type='text'
          name='username'
          value={username}
          placeholder='Username'
          onChange={e => setUsername(e.target.value)}
          onEnter={onUpdate}
        />
        <FormInput
          type='email'
          name='email'
          value={email}
          placeholder='Email'
          onChange={e => setEmail(e.target.value)}
          onEnter={onUpdate}
        />
        <FormButton label='Update' loading={isUpdating} onClick={onUpdate} />
      </AuthLayout>
    </Layout>
  );
};

export default Account;
