import React, { useCallback, useState } from 'react';

type Props = {
  onImageSelect?: (file: File) => void;
};

const ImageUpload: React.FC<Props> = ({ onImageSelect }) => {
  const [imageSrc, setImageSrc] = useState('');

  const onSelect = useCallback(
    (event: any) => {
      const file = event.target.files[0];
      if (!file) return;
      const fileReader = new FileReader();
      fileReader.onload = e => setImageSrc(e.target.result.toString());
      fileReader.onerror = () => setImageSrc(null);
      fileReader.readAsDataURL(file);

      onImageSelect(file);
    },
    [setImageSrc, onImageSelect]
  );

  return (
    <div className='uploader'>
      <label className='content relative flex flex-col justify-center items-center gap-2'>
        <input
          type='file'
          className='absolute opacity-0 w-full h-full z-0 cursor-pointer'
          accept='image/*'
          onDrop={onSelect}
          onChange={onSelect}
        />
        {imageSrc === '' ? (
          <svg
            width='103'
            height='83'
            viewBox='0 0 103 83'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M92.1086 0.621826H10.7753C5.16839 0.621826 0.608643 5.19253 0.608643 10.8129V71.9594C0.608643 77.5798 5.16839 82.1505 10.7753 82.1505H92.1086C97.7156 82.1505 102.275 77.5798 102.275 71.9594V10.8129C102.275 5.19253 97.7156 0.621826 92.1086 0.621826ZM23.4836 15.9085C25.5059 15.9085 27.4454 16.7137 28.8753 18.1471C30.3053 19.5805 31.1086 21.5246 31.1086 23.5518C31.1086 25.5789 30.3053 27.523 28.8753 28.9564C27.4454 30.3898 25.5059 31.1951 23.4836 31.1951C21.4614 31.1951 19.5219 30.3898 18.092 28.9564C16.662 27.523 15.8586 25.5789 15.8586 23.5518C15.8586 21.5246 16.662 19.5805 18.092 18.1471C19.5219 16.7137 21.4614 15.9085 23.4836 15.9085ZM51.442 66.8639H15.8586L36.192 41.3862L43.817 51.5772L59.067 31.1951L87.0253 66.8639H51.442Z'
              fill='#242128'
            />
          </svg>
        ) : (
          <img src={imageSrc} className='h-100' alt='' />
        )}
        <p className='add-image text-center'>DROP IMAGE OR BROWSE</p>
      </label>
    </div>
  );
};

export default ImageUpload;
