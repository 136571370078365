import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AuthLayout, FormInput, FormButton } from 'components/Atoms';
import { useAuth } from 'state/user/hooks';

export default () => {
  const [title, setTitle] = useState('Change Password');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();

  const { loggedIn } = useAuth();
  if (loggedIn) {
    return <Redirect to='/land' />;
  }

  const callback = () => {
    setIsLoading(true);
    setTimeout(() => {
      setSubmitted(true);
      setTitle('Success!');
    }, 1000);
  };

  return (
    <AuthLayout title={title}>
      <Helmet>
        <title>Netvrk - Reset Password</title>
      </Helmet>
      {submitted ? (
        <div className='text-center text-sm text-white font-play'>
          <div className='mb-6'>
            Your password for [username] has succesfully been updated. Please
            click the button below to login.
          </div>
          <FormButton label='LOGIN' onClick={() => history.push('/login')} />
        </div>
      ) : confirmed ? (
        <>
          <FormInput
            type='password'
            value={password1}
            placeholder='Password'
            onChange={e => setPassword1(e.target.value)}
            onEnter={callback}
          />
          <FormInput
            type='password'
            value={password2}
            placeholder='Confirm Password'
            onChange={e => setPassword2(e.target.value)}
            onEnter={callback}
          />
          <FormButton label='SUBMIT' loading={isLoading} onClick={callback} />
        </>
      ) : (
        <div className='text-center text-sm text-white font-play'>
          <div className='text-md font-bold mb-2'>Hi, [username]</div>
          <div className='mb-6'>
            We’ve received a request to reset your password for the Netvrk
            dashboard. To reset, simply click the button below.
          </div>
          <FormButton
            label='RESET PASSWORD'
            loading={isLoading}
            onClick={() => setConfirmed(true)}
          />
          If you did not make this request, you may safely ignore this message.
          Your account remains safe and your current password will not be
          changed.
          <div className='text-md font-bold mt-2'>
            Thank you,
            <br />
            The Netvrk Team
          </div>
        </div>
      )}
    </AuthLayout>
  );
};
