import React, { useState, createContext, useContext } from 'react';
import classNames from 'classnames';

type ExpansionStep = 'collapsed' | 'half-expanded' | 'expanded';

type ResponsiveSidebarContextType = {
  mintExpanded: ExpansionStep;
  setMintExpanded?: (status: ExpansionStep) => void;
};

const initialState: ResponsiveSidebarContextType = {
  mintExpanded: 'collapsed',
};

const ResponsiveSidebarContext =
  createContext<ResponsiveSidebarContextType>(initialState);

export const useResponsiveSidebarContext = () =>
  useContext(ResponsiveSidebarContext);

const ResponsiveSidebar: React.FC = ({ children }) => {
  const { mintExpanded, setMintExpanded } = useResponsiveSidebarContext();

  const toggleSideBar = () => {
    if (mintExpanded === 'collapsed') {
      setMintExpanded('half-expanded');
    } else if (mintExpanded === 'half-expanded') {
      setMintExpanded('expanded');
    } else {
      setMintExpanded('collapsed');
    }
  };

  return (
    <div
      className={classNames('map-sidebar', {
        'half-expanded': mintExpanded === 'half-expanded',
        expanded: mintExpanded === 'expanded',
      })}
    >
      <div className='w-full mobileUp:hidden' onClick={toggleSideBar}>
        <div className='w-12 h-1 bg-secondary rounded-full mx-auto my-2'></div>
      </div>
      {children}
    </div>
  );
};

const SidebarProvider: React.FC = ({ children }) => {
  const [mintExpanded, setMintExpanded] = useState<ExpansionStep>('collapsed');
  const value = { mintExpanded, setMintExpanded };

  return (
    <ResponsiveSidebarContext.Provider value={value}>
      <ResponsiveSidebar>{children}</ResponsiveSidebar>
    </ResponsiveSidebarContext.Provider>
  );
};

export default SidebarProvider;
