import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAuth, useMe } from 'state/user/hooks';
import useWeb3Auth from 'hooks/useWeb3Auth';

const UserMenu = () => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const dropdown = React.useRef(null);

  const history = useHistory();
  const { account } = useMe();
  const { signOut } = useAuth();
  const { logout } = useWeb3Auth();

  const handleSignout = () => {
    setDropdownOpen(!dropdownOpen);
    signOut();
    logout();
    history.push('/login');
  };
  // close on click outside
  React.useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        !dropdown.current ||
        dropdown.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  return (
    <div className='relative inline-flex mr-3 mobile:hidden'>
      <button
        className='w-8 h-8 inline-flex justify-center items-center group'
        aria-haspopup='true'
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img src='/images/user.svg' alt='User Avatar' />
      </button>

      <div
        className={`${
          dropdownOpen ? 'visible' : 'invisible'
        } absolute -right-16 w-44 mt-10`}
      >
        <div
          ref={dropdown}
          className='bg-backgroundAlt font-play text-white notifications rounded-b-lg'
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className='rounded-t-lg text-23px text-center font-bold py-1'>
            {account.username}
          </div>
          <div className='text-xs border-t-2 border-dark font-semibold px-6 py-3'>
            <Link to='/account' className='hover:text-lightPurple'>
              Profile
            </Link>
          </div>
          <div className='text-xs font-semibold px-6 pb-3'>
            <Link
              to='#'
              className='hover:text-lightPurple'
              onClick={handleSignout}
            >
              Sign Out
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
