export interface IModalOptions {
  titleColor?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
}

export const defaultModalOptions: IModalOptions = {
  titleColor: '#FFF',
  width: '570',
  height: '504',
  maxWidth: '570',
};

export interface IModal {
  show: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  title: string;
  ModalOptions?: IModalOptions;
}
