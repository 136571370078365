export const TOTAL_SUPPLY = 15000;

export const LAND_TYPES = [
  {
    key: 'epic',
    title: 'Epic',
    id: 'Mega_Land',
  },
  {
    key: 'medium',
    title: 'Medium',
    id: 'Medium_Land',
  },
  {
    key: 'standard',
    title: 'Standard',
    id: 'Standard_Land',
  },
  {
    key: 'large',
    title: 'Large',
    id: 'Large_Land',
  },
  {
    key: 'giant',
    title: 'Giant',
    id: 'Giant_Land',
  },
];

export const MAP_COLORS = [
  '#FF850B',
  '#FFD348',
  '#E23EFF',
  '#0060F9',
  '#1BFF00',
];

export const MAP_STROKERS = [
  '#FFECD2',
  '#FFF4D9',
  '#F7AEFF',
  '#B6E3FF',
  '#C8FFA4',
];

export const IMAGE_SIZE = 5500;

export const BACKIMAGE_URLS = {
  doubleZoom: [
    './img/level_1/zoom_1_00.png',
    './img/level_1/zoom_1_10.png',
    './img/level_1/zoom_1_01.png',
    './img/level_1/zoom_1_11.png',
  ],
  quadrupleZoom: [
    './img/level_2/zoom_2_00.png',
    './img/level_2/zoom_2_01.png',
    './img/level_2/zoom_2_02.png',
    './img/level_2/zoom_2_03.png',
    './img/level_2/zoom_2_10.png',
    './img/level_2/zoom_2_11.png',
    './img/level_2/zoom_2_12.png',
    './img/level_2/zoom_2_13.png',
    './img/level_2/zoom_2_20.png',
    './img/level_2/zoom_2_21.png',
    './img/level_2/zoom_2_22.png',
    './img/level_2/zoom_2_23.png',
    './img/level_2/zoom_2_30.png',
    './img/level_2/zoom_2_31.png',
    './img/level_2/zoom_2_32.png',
    './img/level_2/zoom_2_33.png',
  ],
};

export const MAP_PROPERTIES_TO_DISABLE = {
  id: [],
  group2: [],
};

export const ZOOM_DURATION = 500;

export const MAP_SIEDBAR_WIDTH = 502;
export const MAP_HEADER_HEIGHT = 82;

export const MAP_TOOLBAR_WIDTH = 330;
export const MAP_TOOLBAR_HEIGHT = 50;
