import React, { useState, createContext, useContext } from 'react';

export type ILandState = {
  selectedLands?: Array<any>;
};

export type IUpdateLandState = {
  dispatch?: Function;
  selectLand?: Function;
};

export const LandInitialState: ILandState = {
  selectedLands: [],
};

export const UpdateLandInitialState: IUpdateLandState = {};

export const LandContext = createContext(LandInitialState);
export const useLands = () => useContext(LandContext);

export const UpdateLandContext = createContext(UpdateLandInitialState);
export const useUpdateLands = () => useContext(UpdateLandContext);

export default ({ children }) => {
  const [value, dispatch] = useState(LandInitialState);

  const selectLand = (land, checked) => {
    dispatch(v => {
      const { selectedLands } = v;
      const index = selectedLands.findIndex(l => l.id === land.id);
      if (index === -1 && checked) {
        selectedLands.push(land);

        return {
          ...v,
          selectedLands,
        };
      }

      if (index > -1 && !checked) {
        selectedLands.splice(index, 1);

        return {
          ...v,
          selectedLands,
        };
      }

      return { ...v };
    });
  };

  return (
    <UpdateLandContext.Provider value={{ dispatch, selectLand }}>
      <LandContext.Provider value={value}>{children}</LandContext.Provider>
    </UpdateLandContext.Provider>
  );
};
