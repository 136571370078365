import React from 'react';
import cx from 'classnames';

import { FormCheck } from 'components/Atoms';

import './index.scss';

export type IDropdownItem = {
  id: number | string;
  checked: boolean;
  label?: string;
  textColor?: string;
  disabled?: boolean;
};

export type DropdownProps = {
  id: number | string;
  label?: string;
  description?: string;
  variant?:
    | 'orange'
    | 'purple'
    | 'lightPurple'
    | 'grey'
    | 'blue'
    | 'green'
    | 'yellow';
  color?: string;
  open: boolean;
  hide?: boolean;
  list?: Array<IDropdownItem>;
  checkedList?: any;
  onHide?: (id: number | string, hide: boolean) => void | undefined;
  onClick?: (id: number | string, open: boolean) => void | undefined;
  onClickItem?: (item: IDropdownItem) => void | undefined;
  onViewItem?: (item: IDropdownItem) => void | undefined;
};

export const Dropdown: React.FC<DropdownProps> = ({
  id,
  label,
  description,
  variant,
  color,
  open,
  hide,
  list,
  checkedList,
  onHide,
  onClick,
  onClickItem,
  onViewItem,
}: DropdownProps) => {
  const style = {
    ...(color ? { color } : {}),
  };
  const svgStyle = {
    ...(color
      ? {
          fill: color,
          stroke: color,
        }
      : {}),
  };

  const handleClick = () => {
    onClick && onClick(id, !open);
  };

  const handleClickItem = (item: IDropdownItem) => {
    onClickItem && onClickItem(item);
  };

  const handleViewItem = (item: IDropdownItem) => {
    onViewItem && onViewItem(item);
  };

  const handleHide = onHide && (() => onHide(id, !hide));

  return (
    <div className='form-dropdown'>
      <div className='form-dropdown__header'>
        <div className='form-dropdown__header__action' onClick={handleClick}>
          <div
            className={cx(
              'form-dropdown__header__arrow',
              variant && {
                [`text-${variant}`]: true,
              }
            )}
          >
            {!open ? (
              <svg
                width='7'
                height='13'
                viewBox='0 0 7 13'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7 6.5L0.250001 12.1292L0.250001 0.870835L7 6.5Z'
                  {...svgStyle}
                />
              </svg>
            ) : (
              <svg
                width='13'
                height='7'
                viewBox='0 0 13 7'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.5 7L0.870835 0.25L12.1292 0.250001L6.5 7Z'
                  {...svgStyle}
                />
              </svg>
            )}
          </div>
          <p
            className={cx(
              'form-dropdown__header__label',
              variant && {
                [`text-${variant}`]: true,
              }
            )}
            style={style}
          >
            {label} ({(list && list.length) || 0})
          </p>
        </div>
        {/* {handleHide && (
          <div className='form-dropdown__header__eye' onClick={handleHide}>
            {hide ? (
              <svg
                width='22'
                height='9'
                viewBox='0 0 22 9'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 1C1 1 4.5 5 11 5C17.5 5 21 1 21 1'
                  stroke='#625B6A'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M3 2.64502L1 5.00002'
                  stroke='#625B6A'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M20.9999 4.99995L19.0039 2.64795'
                  stroke='#625B6A'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7.914 4.67993L7 7.49993'
                  stroke='#625B6A'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M14.063 4.68799L15 7.49999'
                  stroke='#625B6A'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            ) : (
              <svg
                width='22'
                height='16'
                viewBox='0 0 22 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M20.257 6.962C20.731 7.582 20.731 8.419 20.257 9.038C18.764 10.987 15.182 15 11 15C6.81801 15 3.23601 10.987 1.74301 9.038C1.51239 8.74113 1.38721 8.37592 1.38721 8C1.38721 7.62408 1.51239 7.25887 1.74301 6.962C3.23601 5.013 6.81801 1 11 1C15.182 1 18.764 5.013 20.257 6.962V6.962Z'
                  stroke='#625B6A'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11 11C12.6569 11 14 9.65685 14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11Z'
                  stroke='#625B6A'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            )}
          </div>
        )} */}
      </div>
      {description && (
        <p className='form-dropdown__header__description text-grey'>
          {description}
        </p>
      )}

      {open && (
        <ul className='form-dropdown__list'>
          {list &&
            list.map(item => (
              <li
                key={item.id}
                className='form-dropdown__list--item'
                style={style}
              >
                <FormCheck
                  variant={variant}
                  label={item.label}
                  checked={item.checked}
                  color={color}
                  textColor={item.textColor}
                  disabled={item.disabled || false}
                  onClick={() => handleClickItem(item)}
                />
                {handleHide && (
                  <div
                    className='form-dropdown__list--viewer'
                    onClick={() => handleViewItem(item)}
                  >
                    <svg
                      className='mobileUp:hidden'
                      width='30'
                      height='30'
                      viewBox='0 0 30 30'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        y='0.861084'
                        width='29.063'
                        height='29.063'
                        rx='4'
                        fill='#2C2930'
                      />
                      <path
                        d='M14.5 5.4375C12.4039 5.43997 10.3944 6.27374 8.91218 7.75592C7.43 9.23809 6.59623 11.2476 6.59376 13.3438C6.59125 15.0567 7.15078 16.7232 8.18651 18.0875C8.18651 18.0875 8.40213 18.3714 8.43735 18.4124L14.5 25.5625L20.5655 18.4088C20.5972 18.3707 20.8135 18.0875 20.8135 18.0875L20.8142 18.0853C21.8494 16.7216 22.4087 15.0559 22.4063 13.3438C22.4038 11.2476 21.57 9.23809 20.0878 7.75592C18.6057 6.27374 16.5961 5.43997 14.5 5.4375V5.4375ZM14.5 16.2188C13.9314 16.2188 13.3755 16.0501 12.9027 15.7342C12.43 15.4183 12.0615 14.9693 11.8439 14.444C11.6263 13.9186 11.5693 13.3406 11.6803 12.7829C11.7912 12.2252 12.065 11.7129 12.4671 11.3108C12.8692 10.9087 13.3814 10.6349 13.9391 10.524C14.4968 10.4131 15.0749 10.47 15.6002 10.6876C16.1256 10.9052 16.5746 11.2737 16.8905 11.7465C17.2064 12.2193 17.375 12.7751 17.375 13.3438C17.3741 14.106 17.0709 14.8367 16.5319 15.3756C15.9929 15.9146 15.2622 16.2178 14.5 16.2188V16.2188Z'
                        fill='black'
                      />
                    </svg>
                    <svg
                      className='mobile:hidden'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.7917 0.5C13.51 0.5 14.1988 0.785341 14.7067 1.29325C15.2147 1.80116 15.5 2.49004 15.5 3.20833V12.7917C15.5 13.51 15.2147 14.1988 14.7067 14.7067C14.1988 15.2147 13.51 15.5 12.7917 15.5H3.20833C2.49004 15.5 1.80116 15.2147 1.29325 14.7067C0.785341 14.1988 0.5 13.51 0.5 12.7917V3.20833C0.5 2.49004 0.785341 1.80116 1.29325 1.29325C1.80116 0.785341 2.49004 0.5 3.20833 0.5H12.7917ZM7.16667 3.83333C6.65424 3.83355 6.14876 3.9519 5.6895 4.1792C5.23024 4.40649 4.82957 4.73661 4.51861 5.14391C4.20766 5.5512 3.99479 6.0247 3.89655 6.52763C3.79831 7.03055 3.81735 7.54935 3.95218 8.04372C4.08701 8.5381 4.334 8.99473 4.67396 9.37814C5.01393 9.76155 5.43772 10.0614 5.9124 10.2545C6.38708 10.4475 6.89987 10.5285 7.41094 10.4911C7.922 10.4538 8.41758 10.2991 8.85917 10.0392L10.7442 11.9225L10.8225 11.9917C10.99 12.1212 11.2005 12.1821 11.4113 12.162C11.622 12.1419 11.8173 12.0424 11.9573 11.8836C12.0973 11.7248 12.1717 11.5186 12.1652 11.307C12.1587 11.0954 12.072 10.8941 11.9225 10.7442L10.0392 8.85917C10.3376 8.35284 10.4968 7.77661 10.5007 7.18889C10.5047 6.60118 10.3531 6.02288 10.0615 5.51261C9.76984 5.00235 9.34847 4.57826 8.84009 4.28335C8.33171 3.98844 7.75439 3.83319 7.16667 3.83333ZM7.16667 5.5C7.60869 5.5 8.03262 5.67559 8.34518 5.98816C8.65774 6.30072 8.83333 6.72464 8.83333 7.16667C8.83333 7.60869 8.65774 8.03262 8.34518 8.34518C8.03262 8.65774 7.60869 8.83333 7.16667 8.83333C6.72464 8.83333 6.30072 8.65774 5.98816 8.34518C5.67559 8.03262 5.5 7.60869 5.5 7.16667C5.5 6.72464 5.67559 6.30072 5.98816 5.98816C6.30072 5.67559 6.72464 5.5 7.16667 5.5Z'
                        fill='#2C2930'
                      />
                    </svg>
                  </div>
                )}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
