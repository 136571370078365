export enum TransportType {
  MOTORCYCLE = 'Motorcycle',
  TRUCK = 'Truck',
  SPORTSTER = 'Sportster',
  JET = 'Jet',
  YACHT = 'Yacht',
}

export enum AvatarType {
  ADAM = 'Adam',
  EVE = 'Eve',
  QUANTO = 'Quanto',
}

export enum BonusPackType {
  DEFAULT = 'Bonus Pack',
}
