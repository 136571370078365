import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import Account from 'views/account';
import AvatarsDashboard from 'views/dashboard/avatars';
import BonusPacksDashboard from 'views/dashboard/bonuspacks';
import LandDashboard from 'views/dashboard/land';
import LandsDashboard from 'views/dashboard/lands';
import TransportsDashboard from 'views/dashboard/transports';
import ForgotPassword from 'views/forgot-password';
import Login from 'views/login';
import ResetPassword from 'views/reset-password';
import SignUp from 'views/signup';
import Wallet from 'views/wallet';

import { NotFound } from 'views/error';
import Homepage from 'views/homepage';

const history = createBrowserHistory();

export default () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/'>
          <Homepage />
        </Route>
        <Route exact path='/signup'>
          <SignUp />
        </Route>
        <Route exact path='/login'>
          <Login />
        </Route>
        <Route exact path='/forgot-password'>
          <ForgotPassword />
        </Route>
        <Route exact path='/reset-password'>
          <ResetPassword />
        </Route>
        <PrivateRoute path='/account' component={Account} />
        <PrivateRoute path='/wallet' component={Wallet} />
        <PrivateRoute requireWallet path='/land' component={LandDashboard} />
        <PrivateRoute requireWallet path='/lands' component={LandsDashboard} />
        <PrivateRoute
          requireWallet
          path='/transports'
          component={TransportsDashboard}
        />
        <PrivateRoute
          requireWallet
          path='/avatars'
          component={AvatarsDashboard}
        />
        <PrivateRoute
          requireWallet
          path='/bonus-packs'
          component={BonusPacksDashboard}
        />
        {/* 404 */}
        <PrivateRoute component={NotFound} />
      </Switch>
    </Router>
  );
};
