import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { AuthLayout, FormInput, FormButton } from 'components/Atoms';
import { useAuth } from 'state/user/hooks';

import { sendResetEmail } from 'utils/firebase';

export default () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { loggedIn } = useAuth();
  if (loggedIn) {
    return <Redirect to='/land' />;
  }

  const callback = async () => {
    if (!email) return;

    setIsLoading(true);
    try {
      await sendResetEmail(email);

      setSubmitted(true);
      toast.success('Sent reset password email');
    } catch (error) {
      toast.error('Problem with sending password reset email.');
    }

    setIsLoading(false);
  };

  return (
    <AuthLayout title='Forgot Password?'>
      <Helmet>
        <title>Netvrk - Forgot Password</title>
      </Helmet>
      {submitted ? (
        <div className='text-center text-sm text-white font-play'>
          An email has been sent to {email}.<br />
          Please check your email to reset your password.
        </div>
      ) : (
        <>
          <FormInput
            type='text'
            name='email'
            value={email}
            placeholder='Email'
            onChange={e => setEmail(e.target.value)}
            onEnter={callback}
          />
          <FormButton
            label='Submit'
            loading={isLoading}
            className='forgot-email-submit'
            onClick={callback}
          />
        </>
      )}
    </AuthLayout>
  );
};
