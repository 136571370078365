import React from 'react';

import { TransportType } from 'config/nfts';
import { DropdownMenu } from 'components/Atoms/DropdownMenu';
import { TileViewIcon, RowViewIcon } from 'components/icons';

type Props = {
  viewMode: number;
  setViewMode: (index: number) => void;
  onFilter: (landType: string) => void;
};

const SubHeader: React.FC<Props> = ({ viewMode, setViewMode, onFilter }) => {
  return (
    <div className='flex justify-between items-center'>
      <div className='flex'>
        <div className='mr-2'>
          <DropdownMenu
            items={['All Transport Types', ...Object.values(TransportType)]}
            onClick={onFilter}
          />
        </div>
      </div>
      <div className='flex gap-4'>
        <TileViewIcon
          selected={viewMode === 1}
          onClick={() => setViewMode(1)}
        />
        <RowViewIcon selected={viewMode === 2} onClick={() => setViewMode(2)} />
      </div>
    </div>
  );
};

export default SubHeader;
