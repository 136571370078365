import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { Dropdown } from '../Dropdown';
import { FormButton, FormCheck, LandMintPreviewModal } from 'components/Atoms';
import { useChain, useUpdateChain } from 'context/chain/context';
import { useLands, useUpdateLands } from 'context/land/context';
import { mapHideSquare, mapSelectSquare } from 'utils/map';
import ResponsiveSidebar, { useResponsiveSidebarContext } from './responsive';

const LandSidebar = () => {
  const history = useHistory();

  const { setMintExpanded } = useResponsiveSidebarContext();
  const { landTypes, list, totalList, mintedLands } = useChain();
  const { selectedLands } = useLands();
  const { selectLand: selectLandChain } = useUpdateChain();
  const { selectLand } = useUpdateLands();

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState({});
  const [hide, setHide] = useState({});
  const [checkedAll, setCheckedAll] = React.useState(false);

  const mintedLandIds = (mintedLands || []).map(land => `${land.tokenId}`);

  const handleHidePreviewModal = () => {
    setShow(false);
  };

  const handleOpen = (id: string, val: boolean) => {
    setOpen(op => ({
      ...op,
      [id]: val,
    }));
  };

  const handleHide = (id: string, val: boolean) => {
    setHide(hd => ({
      ...hd,
      [id]: val,
    }));

    mapHideSquare('map', id, !val);
  };

  const handleClick = () => {
    const tooltipEl = document.getElementById('new-land-tooltip');
    if (tooltipEl) {
      tooltipEl.style.display = 'none';
    }

    setShow(true);
  };

  const handleCheckAll = () => {
    for (const landType in list) {
      list[landType].forEach(item => {
        const newItem = {
          ...item,
          checked: checkedAll,
        };
        handleClickItem(newItem);
      });
    }
    setCheckedAll(!checkedAll);
  };

  const handleClickItem = item => {
    const checked = !item.checked;

    selectLandChain(item, checked);
    selectLand(item, checked);

    mapSelectSquare(`${item.label}`, checked);
  };

  const handleViewItem = item => {
    if (setMintExpanded) {
      setMintExpanded('collapsed');
    }
    history.push({
      search: `?landId=${item.label}`,
    });
  };

  return (
    <>
      <div className='map-sidebar__header'>
        <div className='map-sidebar__header--lands'>
          <div className='map-sidebar__header--land'>
            <p>Allocated</p>
            <h1>{totalList || 0}</h1>
          </div>
          <div className='map-sidebar__header--land'>
            <p>Selected</p>
            <h1>{(selectedLands && selectedLands.length) || 0}</h1>
          </div>
          <div className='map-sidebar__header--land'>
            <p>Minted</p>
            <h1>{(mintedLands && mintedLands.length) || 0}</h1>
          </div>
        </div>
        <FormButton
          label='MINT PREVIEW'
          disabled={!selectedLands || !selectedLands.length}
          onClick={handleClick}
        />
      </div>
      <div className='map-sidebar__action'>
        <FormCheck
          label='Select All'
          color='#625B6A'
          textColor='#fff'
          checked={checkedAll}
          onClick={handleCheckAll}
        />
      </div>
      <div className='map-sidebar__body netvrk-scrollbar' id='map-land-sidebar'>
        {landTypes &&
          landTypes.map(landType => (
            <Dropdown
              key={landType.name}
              id={landType.name}
              label={landType.name}
              color={landType.color}
              open={!!open[landType.name]}
              hide={!!hide[landType.name]}
              list={list[landType.name]}
              onClick={handleOpen}
              onViewItem={handleViewItem}
              onClickItem={handleClickItem}
              onHide={handleHide}
            />
          ))}
      </div>

      {/* Mint Preview Modal */}
      <LandMintPreviewModal
        mintedIds={mintedLandIds}
        open={show}
        onHide={handleHidePreviewModal}
      />
    </>
  );
};

const Sidebar = () => (
  <ResponsiveSidebar>
    <LandSidebar />
  </ResponsiveSidebar>
);

export default Sidebar;
