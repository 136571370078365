import React from 'react';
import { Helmet } from 'react-helmet';

import { Layout, WalletConnect } from 'components/Atoms';

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>Netvrk - Connect Wallet</title>
      </Helmet>
      <WalletConnect />
    </Layout>
  );
};
