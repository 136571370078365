export const firebaseConfig =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? {
        apiKey: 'AIzaSyCSzvHhZGjXDVcQJ5mcGvZaqm491pic0mc',
        authDomain: 'dashboard-production-e94e0.firebaseapp.com',
        projectId: 'dashboard-production-e94e0',
        storageBucket: 'dashboard-production-e94e0.appspot.com',
        messagingSenderId: '175044708709',
        appId: '1:175044708709:web:2ade3f7de26bc695b5f98c',
      }
    : {
        apiKey: 'AIzaSyDGgkm45g0taHEH8atuVung7JwnGCkeKR4',
        authDomain: 'plouton-34bb0.firebaseapp.com',
        projectId: 'plouton-34bb0',
        storageBucket: 'plouton-34bb0.appspot.com',
        messagingSenderId: '834166666987',
        appId: '1:834166666987:web:e97167390f99d9668770ae',
      };

export default firebaseConfig;
