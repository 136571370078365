import React, { useState } from 'react';

import Sidebar from 'components/Dashboard/Sidebar';
import Header from 'components/Dashboard/Header';

export const Layout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className='h-screen bg-black'>
      <Header onShowSidebar={() => setShowSidebar(true)} />
      <div
        className='relative flex flex-1 mb-4 overflow-y-auto mobileUp:ml-52 mobile:z-40'
        style={{ height: 'calc(100% - 82px)' }}
      >
        <Sidebar
          show={showSidebar}
          onHideSidebar={() => setShowSidebar(false)}
        />
        {children}
      </div>
    </div>
  );
};
