import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

// import { useChain } from 'context/chain/context';
// import { useMe } from 'state/user/hooks';
import { CardSettingModalWrapper } from './styles';

const SettingModal = ({ isOpen, onClose, image, tokenId, transportData }) => {
  // const [imageUrl, setImageUrl] = useState(image);
  // const [file] = useState<File>(null);
  // const [isUploading, setIsUploading] = useState(false);

  // const { account } = useMe();
  // const {
  //   transports: { all: allTransports },
  // } = useChain();

  // const onSave = () => null;

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-60 overflow-y-auto bg-black bg-opacity-75'
          onClose={onClose}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <CardSettingModalWrapper className='relative inline-block w-full max-w-md p-1 my-8 overflow-hidden text-left align-middle transition-all transform bg-dark shadow-xl rounded-2xl'>
                <div
                  className='absolute right-4 top-4 cursor-pointer'
                  onClick={onClose}
                >
                  <svg
                    width='17'
                    height='17'
                    viewBox='0 0 17 17'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.375 2.21125L14.7888 0.625L8.5 6.91375L2.21125 0.625L0.625 2.21125L6.91375 8.5L0.625 14.7888L2.21125 16.375L8.5 10.0863L14.7888 16.375L16.375 14.7888L10.0863 8.5L16.375 2.21125Z'
                      fill='white'
                    />
                  </svg>
                </div>
                <div className='border border-third rounded-xl px-12 pt-10 pb-3'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium font-teko leading-6 text-white text-center mb-6'
                  >
                    NFT SETTINGS
                  </Dialog.Title>
                  <div className='thumbnail'>
                    {image && (
                      <div
                        className='image'
                        style={{ backgroundImage: `url(${image})` }}
                      >
                        {/* <div
                          className='add-image rounded-full bg-black bg-opacity-60 py-2 px-8 hover:bg-opacity-50 cursor-pointer'
                          onClick={() => setImageUrl('')}
                        >
                          REMOVE IMAGE
                        </div> */}
                      </div>
                    )}
                  </div>
                  <p className='mt-6 mb-1 text-center text-dim font-play text-xxs'>
                    NFT TITLE
                  </p>
                  <div className='mx-12 rounded-full border font-play border-third bg-black flex items-center justify-center text-white p-2'>
                    <p>
                      {transportData
                        ? `${transportData.type}_${transportData.tokenId}`
                        : tokenId}
                    </p>
                  </div>
                  <div className='mt-1 text-10px text-center underline text-white font-play'>
                    Minted by the user
                  </div>
                  {/* <div className='hidden mt-4'>
                    <FormButton
                      label='SAVE CHANGES'
                      onClick={onSave}
                      loading={isUploading}
                    />
                  </div> */}
                </div>
              </CardSettingModalWrapper>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SettingModal;
