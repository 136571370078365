import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { AuthLayout, FormInput, FormButton, FormLink } from 'components/Atoms';
import { useAuth } from 'state/user/hooks';
import { register } from 'utils/firebase';

export default () => {
  const history = useHistory();
  const [form, setForm] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [validation, setValidation] = useState({
    username: false,
    email: false,
    password: false,
    confirmPassword: false,
  });
  const [registering, setRegistering] = useState(false);

  const { loggedIn } = useAuth();

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleBlur = event => {
    setValidation({
      ...validation,
      [event.target.name]: !form[event.target.name],
    });
  };

  const handleSignup = async () => {
    if (!form.username) {
      toast.error('Username is required');
      return;
    }
    if (!form.password) {
      toast.error('Password is required');
      return;
    }
    if (form.password !== form.confirmPassword) {
      toast.error('Password does not match');
      return;
    }

    setRegistering(true);

    try {
      await register(form.username, form.email, form.password, null);
      toast.success('Your signup was successful');
      history.push('/login');
    } catch (error) {
      toast.error('Problem with registering your account');
    }

    setRegistering(false);
  };

  if (loggedIn) {
    return <Redirect to='/land' />;
  }

  return (
    <AuthLayout title='Signup'>
      <Helmet>
        <title>Netvrk - Signup</title>
      </Helmet>
      <>
        <FormInput
          type='text'
          name='username'
          value={form.username}
          placeholder='Username'
          validation={validation.username}
          onChange={handleChange}
          onBlur={handleBlur}
          onEnter={handleSignup}
        />
        <FormInput
          type='email'
          name='email'
          value={form.email}
          placeholder='Email'
          validation={validation.email}
          onChange={handleChange}
          onBlur={handleBlur}
          onEnter={handleSignup}
        />
        <FormInput
          type='password'
          name='password'
          value={form.password}
          placeholder='Password'
          validation={validation.password}
          onChange={handleChange}
          onBlur={handleBlur}
          onEnter={handleSignup}
        />
        <FormInput
          type='password'
          name='confirmPassword'
          value={form.confirmPassword}
          placeholder='Confirm Password'
          validation={validation.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          onEnter={handleSignup}
        />
        <FormButton
          label='CREATE ACCOUNT'
          loading={registering}
          onClick={handleSignup}
        />
        <FormLink link='/login' label='Back to Login' />
      </>
    </AuthLayout>
  );
};
