import React, { useMemo } from 'react';

import { Dropdown } from '../Dropdown';
import { AvatarType } from 'config/nfts';
import {
  FormButton,
  FormCheck,
  AvatarMintPreviewModal,
} from 'components/Atoms';
import { useChain } from 'context/chain/context';
import ResponsiveSidebar from './responsive';

export const AvatarSidebar = () => {
  const {
    avatars: { all: allAvatars, grouped: items, owned: myAvatars },
  } = useChain();

  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState({});
  const [selectedIds, setSelectedIds] = React.useState({});
  const [checkedAll, setCheckedAll] = React.useState(false);

  const tokenIdsToMint = useMemo(
    () =>
      Object.keys(selectedIds).filter(
        tokenId => selectedIds[tokenId] && !myAvatars.includes(tokenId)
      ),
    [myAvatars, selectedIds]
  );

  const handleOpen = (id: string, val: boolean) => {
    setOpen(op => ({
      ...op,
      [id]: val,
    }));
  };

  const handleClickItem = item => {
    const checked = !item.checked;

    const updatedIds = {
      ...selectedIds,
      [item.tokenId]: checked,
    };
    setSelectedIds(updatedIds);
  };

  const handlePreview = () => {
    setShow(true);
  };

  const handleCheckAll = () => {
    const updatedIds = allAvatars.reduce(
      (a, v) => ({
        ...a,
        [v.tokenId]: !checkedAll,
      }),
      {}
    );
    setSelectedIds(updatedIds);
    setCheckedAll(!checkedAll);
  };

  return (
    <ResponsiveSidebar>
      <div className='map-sidebar__header'>
        <div className='map-sidebar__header--lands'>
          <div className='map-sidebar__header--land'>
            <p>Allocated</p>
            <h1>{allAvatars.length}</h1>
          </div>
          <div className='map-sidebar__header--land'>
            <p>Selected</p>
            <h1>{tokenIdsToMint.length}</h1>
          </div>
          <div className='map-sidebar__header--land'>
            <p>Minted</p>
            <h1>{myAvatars.length}</h1>
          </div>
        </div>
        <FormButton
          label='MINT PREVIEW'
          disabled={
            Object.keys(selectedIds).filter(tokenId => selectedIds[tokenId])
              .length === 0
          }
          onClick={handlePreview}
        />
      </div>
      <div className='map-sidebar__action'>
        <FormCheck
          label='Select All'
          color='#625B6A'
          textColor='#fff'
          checked={checkedAll}
          onClick={handleCheckAll}
        />
      </div>
      <div className='map-sidebar__body netvrk-scrollbar' id='map-land-sidebar'>
        {Object.values(AvatarType).map(
          type =>
            items[type] && (
              <Dropdown
                key={type}
                id={type}
                label={type}
                color='#C397F7'
                open={!!open[type]}
                list={items[type].map(d => ({
                  ...d,
                  id: d.tokenId,
                  label: `${type}_${d.tokenId}`,
                  textColor: '#fff',
                  checked:
                    myAvatars.includes(d.tokenId) || selectedIds[d.tokenId],
                  disabled: myAvatars.includes(d.tokenId),
                }))}
                onClick={handleOpen}
                onClickItem={handleClickItem}
              />
            )
        )}
      </div>
      {/* Mint Preview Modal */}
      <AvatarMintPreviewModal
        tokenIds={tokenIdsToMint}
        open={show}
        onHide={() => setShow(false)}
      />
    </ResponsiveSidebar>
  );
};
