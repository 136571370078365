import { useMemo } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import './styles.scss';

// const Hamberber = ({ open }: { open: boolean }) => (
//   <div className='desktop:hidden flex items-center '>
//     <button className='outline-none mobile-menu-button'>
//       <svg
//         className=' w-6 h-6 text-gray-500 hover:text-green-500 '
//         fill='#fff'
//         strokeLinecap='round'
//         strokeLinejoin='round'
//         strokeWidth='3'
//         viewBox='0 0 30 30'
//         stroke='#fff'
//       >
//         {open && <path d='M4 6l22 18M4 24l22 -18'></path>}
//         {!open && <path d='M4 6h22M4 15h22M4 24h22'></path>}
//       </svg>
//     </button>
//   </div>
// );
const Header = () => {
  const { pathname } = useLocation();
  const isAuthPage = useMemo(
    () =>
      pathname.includes('login') ||
      pathname.includes('signup') ||
      pathname.includes('forgot') ||
      pathname.includes('reset') ||
      pathname.includes('verify') ||
      pathname.includes('') ||
      pathname.includes('account'),
    [pathname]
  );

  return (
    <header className='topnav sticky top-0 bg-dark z-30 py-1 font-teko'>
      <div
        className='px-4 sm:px-6 lg:px-8 border-t border-b'
        style={{ borderColor: '#38333E' }}
      >
        <div className='flex h-16 -mb-px'>
          <div
            className='logo pr-4 flex justify-center items-center bg-no-repeat bg-right'
            style={{ backgroundImage: 'url(/images/menu-left.png)' }}
          >
            <Link to='/'>
              <img src='/images/logo.svg' alt='logo' className='max-h-10' />
            </Link>
          </div>
          {!isAuthPage && (
            <>
              <div className='flex-1 flex justify-between'>
                <div className='flex h-full items-center'>
                  <NavLink
                    className='menu-item text-lightPurple hover:text-white uppercase pl-6'
                    activeClassName='menu-item-active'
                    to='/marketplace'
                  >
                    <span>Marketplace</span>
                  </NavLink>
                  <NavLink
                    className='menu-item text-lightPurple hover:text-white uppercase pl-6'
                    activeClassName='menu-item-active'
                    to='/map'
                  >
                    <span>Map</span>
                  </NavLink>
                  <NavLink
                    className='menu-item text-lightPurple hover:text-white uppercase pl-6'
                    activeClassName='menu-item-active'
                    to='/mining-game'
                  >
                    <span>Mining Game</span>
                  </NavLink>
                </div>
                <div className='flex items-center justify-end pr-6'>
                  <Link
                    className='btn btn-purple bg-gradient-purple px-4 py-2 rounded-full text-white uppercase mr-4'
                    to='/signup'
                  >
                    Sign Up
                  </Link>
                  <Link
                    className='btn btn-purple bg-dark px-4 py-2 rounded-full text-white uppercase'
                    to='/login'
                  >
                    Log In
                  </Link>
                </div>
              </div>
              <div
                className='pl-4 flex items-center bg-no-repeat bg-left'
                style={{ backgroundImage: 'url(/images/menu-right.png)' }}
              >
                <button className='btn btn-blue bg-gradient-blue px-4 py-2 rounded-full text-white uppercase'>
                  Buy NETVRK
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
