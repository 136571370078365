import axios from 'axios';
import { API_URL } from 'config/constants';
import { TransportType, AvatarType, BonusPackType } from 'config/nfts';
//import decrypt from 'services/encryption';

export const fetchNFTData = async (account: string) => {
  const { data } = await axios.get(`${API_URL}/api/allocation/${account}`);

  //temporarily disabled the encryption because 4mb next js limitation in api body.
  //const decryptedData = decrypt(data);
  //return JSON.parse(decryptedData);
  return data;
};

export const groupNFTData = (
  NFTType: 'transport' | 'avatar' | 'bonuspack',
  items: any[]
) => {
  const typeEnum =
    NFTType === 'transport'
      ? TransportType
      : NFTType === 'avatar'
      ? AvatarType
      : BonusPackType;
  const nfts = {};

  Object.values(typeEnum).forEach(
    type => (nfts[type] = items.filter(d => d.type === type))
  );

  return nfts;
};
