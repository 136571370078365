import { useCallback } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import toast from 'react-hot-toast';

import { ConnectorNames, CONNECTOR_LOCAL_KEY } from '../config/constants';
import { connectorsByName } from '../utils/web3React';

const useWeb3Auth = () => {
  const { activate, deactivate } = useWeb3React();

  const login = useCallback(
    (connectorID: ConnectorNames) => {
      const connector = connectorsByName[connectorID];
      if (connector) {
        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError) {
            activate(connector);
          } else {
            window.localStorage.removeItem(CONNECTOR_LOCAL_KEY);
            if (error instanceof NoEthereumProviderError) {
              toast.error('Provider Error');
            } else if (
              error instanceof UserRejectedRequestErrorInjected ||
              error instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector;
                walletConnector.walletConnectProvider = null;
              }
              toast.error('Please authorize to access your account');
            } else {
              toast.error(
                'Unknown error occurred while connecting your wallet.'
              );
            }
          }
        });
      } else {
        toast.error('Unable to find connector');
      }
    },
    [activate]
  );

  const logout = useCallback(() => {
    deactivate();
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName.walletconnect.close();
      connectorsByName.walletconnect.walletConnectProvider = null;
    }
    window.localStorage.removeItem(CONNECTOR_LOCAL_KEY);
  }, [deactivate]);

  return { login, logout };
};

export default useWeb3Auth;
