import { BigNumber } from 'bignumber.js';
import { Contract } from '@ethersproject/contracts';

import {
  landContract,
  parcelMinterContract,
  rootTransportContract,
  transportMinterContract,
  rootAvatarContract,
  avatarMinterContract,
  rootBonusPackContract,
  bonusPackMinterContract,
} from 'contracts/address';
import RootLandABI from 'contracts/abis/RootLand.json';
import ParcelMinterABI from 'contracts/abis/ParcelMinter.json';
import RootTransportABI from 'contracts/abis/RootTransport.json';
import TransportMinterABI from 'contracts/abis/TransportMinter.json';
import RootAvatarABI from 'contracts/abis/RootAvatar.json';
import AvatarMinterABI from 'contracts/abis/AvatarMinter.json';
import RootBonusPackABI from 'contracts/abis/RootBonusPack.json';
import BonusPackMinterABI from 'contracts/abis/BonusPackMinter.json';

export type IParcels = {
  Mega_Land?: BigNumber;
  Giant_Land?: BigNumber;
  Large_Land?: BigNumber;
  Medium_Land?: BigNumber;
  Standard_Land?: BigNumber;
};

export type ILand = {
  id: String;
  tokenId: String;
  owner: String;
};

export type IChainInitialState = {
  RootLandContract?: Contract;
  ParcelMinterContract?: Contract;
  RootTransportContract?: Contract;
  TransportMinterContract?: Contract;
  RootAvatarContract?: Contract;
  AvatarMinterContract?: Contract;
  RootBonusPackContract?: Contract;
  BonusPackMinterContract?: Contract;

  // Available lands to claim/mint
  // parcels?: IParcels;
  parcels?: any;

  // All lands
  lands?: Array<any>;

  // User claimed lands
  myLands?: Array<ILand>;

  // All claimed lands
  claimedLands?: Array<ILand>;

  // Already minted lands
  mintedLands?: Array<ILand>;

  // All land types
  landTypes?: Array<any>;

  // All lands by landtypes
  groups?: any;

  // Available land list
  list?: Array<any>;

  // Available lands count
  totalList?: number;

  // Transports data
  transports: {
    all: any[];
    grouped: any;
    owned: any[];
  };

  // Avatars data
  avatars: {
    all: any[];
    grouped: any;
    owned: any[];
  };

  // BonusPacks data
  bonuspacks: {
    all: any[];
    grouped: any;
    owned: any[];
  };

  // land data is loaded?
  isLoaded: Boolean;
  firebaseAuthState: Boolean;
};

export type IUpdateChainInitialState = {
  dispatch?: Function;
  selectLand?: Function;
  setMintedLands?: Function;
};

export const RootLand = (library, chainId): Contract => {
  return new Contract(landContract[chainId], RootLandABI, library.getSigner());
};

export const ParcelMinter = (library, chainId): Contract => {
  return new Contract(
    parcelMinterContract[chainId],
    ParcelMinterABI,
    library.getSigner()
  );
};

export const RootTransport = (library, chainId): Contract => {
  return new Contract(
    rootTransportContract[chainId],
    RootTransportABI,
    library.getSigner()
  );
};

export const TransportMinter = (library, chainId): Contract => {
  return new Contract(
    transportMinterContract[chainId],
    TransportMinterABI,
    library.getSigner()
  );
};

export const RootAvatar = (library, chainId): Contract => {
  return new Contract(
    rootAvatarContract[chainId],
    RootAvatarABI,
    library.getSigner()
  );
};

export const AvatarMinter = (library, chainId): Contract => {
  return new Contract(
    avatarMinterContract[chainId],
    AvatarMinterABI,
    library.getSigner()
  );
};

export const RootBonusPack = (library, chainId): Contract => {
  return new Contract(
    rootBonusPackContract[chainId],
    RootBonusPackABI,
    library.getSigner()
  );
};

export const BonusPackMinter = (library, chainId): Contract => {
  return new Contract(
    bonusPackMinterContract[chainId],
    BonusPackMinterABI,
    library.getSigner()
  );
};

export const fetchLanesFromContract = async (
  contract: Contract,
  address: String
) => {
  const megaLand = await contract.epicMinters(address);
  const giantLand = await contract.giantMinters(address);
  const largeLand = await contract.largeMinters(address);
  const mediumLand = await contract.mediumMinters(address);
  const standardLand = await contract.standardMinters(address);

  const parcels: IParcels = {
    Mega_Land: new BigNumber(megaLand.toString()),
    Giant_Land: new BigNumber(giantLand.toString()),
    Large_Land: new BigNumber(largeLand.toString()),
    Medium_Land: new BigNumber(mediumLand.toString()),
    Standard_Land: new BigNumber(standardLand.toString()),
  };

  return parcels;
};

export const fetchLandsFromMock = async (
  availableLandIds: any,
  groups: any
) => {
  return Object.keys(groups).reduce((value, key) => {
    const landIdsByGroup =
      groups[key].filter(g => availableLandIds.includes(g.tokenId)) || [];

    return {
      ...(value || {}),
      [key]: new BigNumber(landIdsByGroup.length),
    };
  }, {});
};

export const mintMultiLands = async (contract, tokens = []) => {
  if (!contract) {
    throw new Error('Wallet is not connected');
  }

  if (!tokens || !tokens.length) {
    throw new Error('No selection');
  }

  const tx = await contract.batchRedeemParcels(
    tokens.map(item => item.id),
    tokens.map(item => item.proof)
  );
  await tx.wait();
};

export const mintMultiTransports = async (contract, tokens = []) => {
  if (!contract) {
    throw new Error('Wallet is not connected');
  }

  if (!tokens || !tokens.length) {
    throw new Error('No selection');
  }

  const tx = await contract.batchRedeemTransports(
    tokens.map(item => item.id),
    tokens.map(item => item.proof)
  );
  await tx.wait();
};

export const mintMultiAvatars = async (contract, tokens = []) => {
  if (!contract) {
    throw new Error('Wallet is not connected');
  }

  if (!tokens || !tokens.length) {
    throw new Error('No selection');
  }

  const tx = await contract.batchRedeemAvatars(
    tokens.map(item => item.id),
    tokens.map(item => item.proof)
  );
  await tx.wait();
};

export const mintMultiBonuspacks = async (contract, tokens = []) => {
  if (!contract) {
    throw new Error('Wallet is not connected');
  }

  if (!tokens || !tokens.length) {
    throw new Error('No selection');
  }

  const tx = await contract.batchRedeemBonusPacks(
    tokens.map(item => item.id),
    tokens.map(item => item.proof)
  );
  await tx.wait();
};
