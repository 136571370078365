import React from 'react';

// import { FormButton } from '../Form';
import Modal from 'react-modal';

import './index.scss';

Modal.setAppElement('#root');

export type IModalWrapperProps = {
  open?: boolean;
  children?: React.ReactNode;
};

export type IModalHeaderProps = {
  onHide: () => void | undefined;
  children?: React.ReactNode;
};

export type IModalBodyProps = {
  children?: React.ReactNode;
};

export type IModalFooterProps = {
  children?: React.ReactNode;
};

export const ModalWrapper: React.FC<IModalWrapperProps> = ({
  open,
  children,
}: IModalWrapperProps) => {
  return (
    <Modal
      isOpen={open}
      style={{
        overlay: {
          zIndex: 70,
          background: 'rgba(0, 0, 0, 0.8)',
        },
        content: {
          background: 'transparent',
          border: 'none',
        },
      }}
    >
      <div className='modal'>
        <div className='modal-content'>
          <div className='modal-content__container'>{children}</div>
        </div>
      </div>
    </Modal>
  );
};

export const ModalHeader: React.FC<IModalHeaderProps> = ({
  onHide,
  children,
}: IModalHeaderProps) => (
  <div className='modal-header'>
    <div className='modal-header__description'>{children}</div>
    <div className='modal-header__action' onClick={onHide}>
      <svg
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.375 2.21125L14.7888 0.625L8.5 6.91375L2.21125 0.625L0.625 2.21125L6.91375 8.5L0.625 14.7888L2.21125 16.375L8.5 10.0863L14.7888 16.375L16.375 14.7888L10.0863 8.5L16.375 2.21125Z'
          fill='white'
        />
      </svg>
    </div>
  </div>
);

export const ModalBody: React.FC<IModalBodyProps> = ({
  children,
}: IModalBodyProps) => (
  <div className='modal-body netvrk-scrollbar'>{children}</div>
);

export const ModalFooter: React.FC<IModalFooterProps> = ({
  children,
}: IModalFooterProps) => <div className='modal-footer'>{children}</div>;

// export const ModalWrapper: React.FC<IModalWrapperProps> = ({
//   open,
// }: IModalWrapperProps) => {
//   if (!open) return <></>;

//   return (
//     <div className='modal'>
//       <div className='modal-content'>
//         <div className='modal-content__container'>
//           <div className='modal-header'>
//             <div className='modal-header__description'>
//               <h5>MINT PREVIEW</h5>
//               <p>You are about to mint 35 / 48 times.</p>
//             </div>
//             <div className='modal-header__action'>
//               <svg
//                 width='17'
//                 height='17'
//                 viewBox='0 0 17 17'
//                 fill='none'
//                 xmlns='http://www.w3.org/2000/svg'
//               >
//                 <path
//                   d='M16.375 2.21125L14.7888 0.625L8.5 6.91375L2.21125 0.625L0.625 2.21125L6.91375 8.5L0.625 14.7888L2.21125 16.375L8.5 10.0863L14.7888 16.375L16.375 14.7888L10.0863 8.5L16.375 2.21125Z'
//                   fill='white'
//                 />
//               </svg>
//             </div>
//           </div>
//           <div className='modal-body'>
//             <ul>
//               <li className='modal-row'>
//                 <div className='modal-row__icon'>
//                   <svg
//                     width='11'
//                     height='9'
//                     viewBox='0 0 11 9'
//                     fill='none'
//                     xmlns='http://www.w3.org/2000/svg'
//                   >
//                     <path
//                       d='M3.93902 6.21994L1.88289 4.1472L1 5.03723L3.93902 8L10 1.89003L9.11711 1L3.93902 6.21994Z'
//                       fill='#C397F7'
//                       stroke='#C397F7'
//                     />
//                   </svg>
//                 </div>
//                 <div className='modal-row__text'>Mega Land (2 / 3)</div>
//               </li>
//               <li className='modal-row'>
//                 <div className='modal-row__icon'>
//                   <svg
//                     width='11'
//                     height='9'
//                     viewBox='0 0 11 9'
//                     fill='none'
//                     xmlns='http://www.w3.org/2000/svg'
//                   >
//                     <path
//                       d='M3.93902 6.21994L1.88289 4.1472L1 5.03723L3.93902 8L10 1.89003L9.11711 1L3.93902 6.21994Z'
//                       fill='#C397F7'
//                       stroke='#C397F7'
//                     />
//                   </svg>
//                 </div>
//                 <div className='modal-row__text'>Mega Land (2 / 3)</div>
//               </li>
//               <li className='modal-row'>
//                 <div className='modal-row__icon'>
//                   <svg
//                     width='11'
//                     height='9'
//                     viewBox='0 0 11 9'
//                     fill='none'
//                     xmlns='http://www.w3.org/2000/svg'
//                   >
//                     <path
//                       d='M3.93902 6.21994L1.88289 4.1472L1 5.03723L3.93902 8L10 1.89003L9.11711 1L3.93902 6.21994Z'
//                       fill='#C397F7'
//                       stroke='#C397F7'
//                     />
//                   </svg>
//                 </div>
//                 <div className='modal-row__text'>Mega Land (2 / 3)</div>
//               </li>
//               <li className='modal-row'>
//                 <div className='modal-row__icon'>
//                   <svg
//                     width='11'
//                     height='9'
//                     viewBox='0 0 11 9'
//                     fill='none'
//                     xmlns='http://www.w3.org/2000/svg'
//                   >
//                     <path
//                       d='M3.93902 6.21994L1.88289 4.1472L1 5.03723L3.93902 8L10 1.89003L9.11711 1L3.93902 6.21994Z'
//                       fill='#C397F7'
//                       stroke='#C397F7'
//                     />
//                   </svg>
//                 </div>
//                 <div className='modal-row__text'>Mega Land (2 / 3)</div>
//               </li>
//             </ul>
//           </div>
//           <div className='modal-footer'>
//             <div className='modal-footer__description'>
//               <p>You have 13 un-minted items.</p>
//               <p>Would you like to proceed anyway?</p>
//             </div>
//             <div className='modal-footer__actions'>
//               <FormButton label='CANCEL' />
//               <FormButton label='MINT NOW' />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
