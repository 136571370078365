import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import { useDispatch } from 'react-redux';

import reducer from './reducer';

const PERSISTED_KEYS: string[] = ['user', 'lands'];

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({ thunk: true }),
    save({ states: PERSISTED_KEYS }),
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
