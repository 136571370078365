import { combineReducers } from '@reduxjs/toolkit';

import user from './user/reducer';
import lands from './lands/reducer';

const reducer = combineReducers({
  user,
  lands,
});

export default reducer;
