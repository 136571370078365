import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import { Map, WalletConnect, LandTooltip } from 'components/Atoms';

import { useChain } from 'context/chain/context';

import { mapInitializer } from 'utils/map';

import './style.scss';

const Land = () => {
  const [isMapInitialized, setMapInitialized] = useState(false);
  const { active } = useWeb3React();
  const { lands, claimedLands, landTypes, isLoaded } = useChain();

  // Initialize map
  useEffect(() => {
    const initializeMap = () => {
      mapInitializer(lands, landTypes, claimedLands);
      setMapInitialized(true);
    };

    isLoaded && !isMapInitialized && initializeMap();
  }, [
    lands,
    claimedLands,
    landTypes,
    isLoaded,
    isMapInitialized,
    setMapInitialized,
  ]);

  // Update map
  useEffect(() => {
    if (
      !isMapInitialized ||
      !claimedLands ||
      !claimedLands.length ||
      !lands ||
      !lands.length
    )
      return;

    claimedLands.forEach(claimedLand => {
      const land = lands.find(land => land.tokenId === claimedLand.tokenId);
      if (land) {
        const classname = document
          .querySelector(`#${land.id}`)
          .getAttribute('class');

        if (!classname.includes('disabled')) {
          document
            .querySelector(`#${land.id}`)
            .setAttribute('class', `${classname} disabled`);

          document
            .querySelector(`#${land.id}`)
            .setAttribute('owner', `${claimedLand.owner}`);
        }
      }
    });
  }, [isMapInitialized, lands, claimedLands]);

  // useEffect(() => {
  //   if (!isMapInitialized || !parcels) return;

  //   // Object.keys(parcels).forEach(group => {
  //   //   document.querySelector(
  //   //     `#map-group-${group}`
  //   //   ).innerHTML = `${group} (${parcels[group].toString()})`;
  //   // });
  // }, [isMapInitialized, lands, parcels]);

  useEffect(() => {
    if (!active) {
      setMapInitialized(false);
    }
  }, [active, setMapInitialized]);

  return (
    <div className='map_container'>
      {active ? (
        <>
          <Map />
          <LandTooltip />
          {/* <LandSelection loading={loading} onMint={mint} /> */}
        </>
      ) : (
        <WalletConnect />
      )}
    </div>
  );
};

export default Land;
